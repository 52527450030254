import React from 'react';

// Material UI
import { makeStyles, styled } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import StoreIcon from '@material-ui/icons/Store';

// Utility
import moment from 'moment-timezone';
import Tooltip from 'components/popups/Tooltip';


// Green for live and upcoming events | Grey for private and concluded events
export default function ProductTitle(props) {

    const { name, visible, accessCode, timezone, startDate, endDate, qtyLeft, onEdit } = props;

    const classes = useStyles();


    // ==== Sale Duration Status ==== //

    let onSale;
    let onSaleMessage;

    if (moment(startDate).tz(timezone, true) > moment()) {
        onSale = false;
        onSaleMessage = 'On sale starting ' + formatDate(startDate);
    }
    else if (moment(endDate).tz(timezone, true) < moment()) {
        onSale = false;
        onSaleMessage = 'Sale ended ' + formatDate(endDate);
    } else {
        onSale = true;
        onSaleMessage = endDate ? 'On sale until ' + formatDate(endDate) : 'On sale';
    }

    if (qtyLeft <= 0) {
        onSale = false;
        onSaleMessage = 'Sold Out';
    }

    return (
        <Box>
            <Typography className={classes.title} onClick={onEdit ?? undefined}>{name}</Typography>

            <Box display='flex' alignItems='center'>
                {onSale
                    ? <StoreActiveIcon fontSize='small' />
                    : <StoreInactiveIcon fontSize='small' />
                }
                <IconText variant='caption'>{onSaleMessage}</IconText>

                {accessCode ? (
                    <Box display='flex' alignItems='center' ml={0.5}>
                        <Tooltip message={'Access Code: ' + accessCode}>
                            <Lock />
                        </Tooltip>
                        <IconText variant='caption'>Access code required</IconText>
                    </Box>
                ) : (
                    <Box display='flex' alignItems='center' ml={0.5}>
                        {visible === 1 ? <Visible /> : <Hidden />}
                        <IconText variant='caption'>{visible === 1 ? 'Everyone' : 'No one'}</IconText>
                    </Box>
                )}
            </Box>
        </Box>
    )
};


function formatDate(date) {
    return moment(date).get('year') === moment().get('year')
        ? moment(date).format('ddd MMM D, h:mma')
        : moment(date).format('ddd MMM D, YYYY h:mma')
}


const useStyles = makeStyles(theme => ({
    title: {
        paddingRight: theme.spacing(1),
        display: 'inline',
        fontWeight: 'bold',
        '&:hover': {
            cursor: 'pointer'
        },
    },
    statusPublished: {
        height: '.6rem',
        width: '.6rem',
        backgroundColor: theme.palette.success.main,
        borderRadius: '.6rem',
        marginRight: 4
    },
    statusHidden: {
        height: '.6rem',
        width: '.6rem',
        backgroundColor: theme.palette.grey.A200,
        borderRadius: '.6rem',
        marginRight: 4
    },
}))


const StoreActiveIcon = styled(StoreIcon)(({ theme }) => ({
    height: '16px',
    color: theme.palette.success.main,
    marginLeft: -3
}));
StoreActiveIcon.defaultProps = { fontSize: 'small' }

const StoreInactiveIcon = styled(StoreIcon)(({ theme }) => ({
    height: '16px',
    color: theme.palette.grey.A200,
    marginLeft: -3
}));
StoreInactiveIcon.defaultProps = { fontSize: 'small' }

const Visible = styled(PersonIcon)(({ theme }) => ({
    height: '16px',
    color: theme.palette.success.main,
    marginLeft: 8
}));

const Hidden = styled(PersonIcon)(({ theme }) => ({
    height: '16px',
    color: theme.palette.grey.A200,
    marginLeft: 8
}));

const Lock = styled(LockIcon)(({ theme }) => ({
    height: '16px',
    color: theme.palette.warning.main,
    marginLeft: 8
}));
Lock.defaultProps = { color: 'secondary', fontSize: 'small' }

const IconText = styled(Typography)(({ theme }) => ({
    marginBottom: -2
}));
IconText.defaultProps = { variant: 'caption' }