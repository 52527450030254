import React from 'react';

// Material-UI
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell as _TableCell } from '@material-ui/core';
import { styled } from '@material-ui/core';

// Components
import PayoutChip from 'components/payouts/PayoutChip';

// Utility
import moment from 'moment-timezone';


export default function PayoutHistory(props) {
    const { payouts, totalPayouts } = props;

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCellHeader>WHEN</TableCellHeader>
                        <TableCellHeader align='right'>STATUS</TableCellHeader>
                        <TableCellHeader align='right'>AMOUNT</TableCellHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {payouts.length > 0 && (
                        <TableRow>
                            <TableCellEmpty></TableCellEmpty>
                        </TableRow>
                    )}

                    {payouts.length > 0 && payouts.map(p => (
                        <Payout key={p.payout_id} {...p} />
                    ))}

                    {payouts.length === 0 ? (
                        <TableRow>
                            <TableCellEmpty align='center' colSpan={3}>No payouts found</TableCellEmpty>
                        </TableRow>
                    ) : (
                        <TableRow>
                            <TotalBlack colSpan={3} align='right'>{formatPrice(totalPayouts)}</TotalBlack>
                        </TableRow>
                    )}

                </TableBody>
            </Table>
        </TableContainer>
    )
}


function Payout(props) {

    const date = moment(props.payout_date).get('year') === moment().get('year')
        ? moment(props.payout_date).format('MMM Do')
        : moment(props.payout_date).format('MMM Do YYYY')
    
    return (
        <TableRow>
            <TableCell>{date}</TableCell>
            <TableCell align='right'>
                <PayoutChip
                    payoutDate={props.payout_date}
                    finalPayout={Boolean(props.final_payout_flag)}
                />
            </TableCell>
            <TableCellBold align='right'>{formatPrice(props.payout_total_amt)}</TableCellBold>
        </TableRow>
    )
}

// Format for price display
function formatPrice(num) {
    return '$' + num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

const TableCell = styled(_TableCell)(({ theme }) => ({
    border: 'none',
    paddingTop: 0
}));

const TableCellEmpty = styled(_TableCell)(({ theme }) => ({
    border: 'none',
    paddingBottom: 0
}));

const TableCellHeader = styled(TableCell)(({ theme }) => ({
    ...theme.typography.subHeader,
    fontWeight: 'bold',
    borderBottom: '0.5px solid',
    paddingTop: 0
}));

const TableCellBold = styled(TableCell)(({ theme }) => ({
    width: 80,
    fontWeight: 'bold',
    border: 'none',
    paddingTop: 0
}));

const TotalBlack = styled(_TableCell)(({ theme }) => ({
    border: 'none',
    borderTop: '0.5px solid',
    paddingBottom: 0,
    fontWeight: 'bold'
}));
