import React, { useState, useEffect } from 'react';

// Material UI
import { styled, Box, TextField, Grid, Card, CardHeader, CardContent, Typography, Button, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress } from '@material-ui/core';
import { Alert as _Alert } from '@material-ui/lab';

// Components
import { Page } from 'components/ui/layout';
import { AlertButton } from 'components/ui/alerts';
import HostPricingDetails from 'components/HostPricingDetails';
import EventPayouts from 'components/payouts/EventPayouts';
import ProductSales from 'components/widgets/ProductSales';
import RecentOrders from 'components/widgets/RecentOrders';
import ProductSalesReport from 'components/widgets/ProductSalesReport';

// Utility
import { useAuth } from 'context/auth';
import fetchExpresso from 'utility/fetchExpresso';
import { formatPrice } from 'utility/numbers';
import { useEvent } from 'context/event';
import moment from 'moment-timezone';
import { ConnectedAccount } from 'stripe';



export default function EventDashboard(props) {
    const eventId = props.match.params.id;
    const { auth } = useAuth();
    const event = useEvent();

    const [stats, setStats] = useState({
        values: null,
        loading: true,
        error: false
    });


    useEffect(() => {
        fetchExpresso(`/apiv2/events/${eventId}/dashboard?tz=${moment.tz.guess(true)}`)
            .then(async res => {
                if (res.status === 200) {
                    const values = await res.json();
                    setStats(stats => ({ ...stats, loading: false, error: false, values: values }));
                } else {
                    throw new Error();
                }
            })
            .catch(e => {
                setStats(stats => ({ ...stats, loading: false, error: true }));
            })
    }, [eventId]);

    if (stats.loading) {
        return (
            <Page>
                <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' mt={12}>
                    <CircularProgress />
                </Box>
            </Page>
        )
    };
    
    if (stats.error) {
        return (
            <Page>
                <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' mt={12}>
                    <Typography variant='h5'>There was a problem finding your event</Typography>
                    <Box height={32} />
                    <Button variant='outlined' onClick={() => props.history.push('/events')}>Back to Events</Button>
                </Box>
            </Page>
        )
    };


    let publishStatus = 'draft';
    let alertType;
    let alertText;

    if (event.live_flag) {
        event.unlisted_flag
            ? publishStatus = 'unlisted'
            : publishStatus = 'published'
    }


    const endDate = moment.tz(event.end_date, event.time_zone);
    const currentDate = moment().tz(event.time_zone);

    if (endDate < currentDate) {
        publishStatus = 'concluded';
    }


    switch (publishStatus) {
        case 'draft':
            alertType = 'warning';
            alertText = 'Your event is in draft mode'
            break;
        case 'unlisted':
            alertType = 'info';
            alertText = 'Your event is unlisted'
            break;
        case 'published':
            alertType = 'success';
            alertText = 'Your event is published'
            break;
        case 'concluded':
            alertType = 'info';
            alertText = 'Your event has concluded'
            break;
    
        default:
            break;
    }


    const connect = new ConnectedAccount(null, stats.values.connect_status)


    return (
        <Page>

            <Box mb={4}>
                {connect.chargesEnabled === false && (
                    <Alert
                        severity='warning'
                        variant='filled'
                        action={<AlertButton severity='warning' onClick={() => props.history.push(`/settings`)}>Connect</AlertButton>}
                    >
                        Please complete your FrontDoor+ Payments onboarding before publishing an event
                    </Alert>
                )}

                {stats.values.product_count === 0 && (
                    <Alert
                        severity='warning'
                        variant='filled'
                        action={<AlertButton severity='warning' onClick={() => props.history.push(`/events/${eventId}/tickets`)}>Create</AlertButton>}
                    >
                        Your event has 0 tickets
                    </Alert>
                )}
                <Alert
                    severity={alertType}
                    variant='filled'
                    style={publishStatus === 'concluded' ? { backgroundColor: '#777' } : undefined}
                    action={publishStatus !== 'concluded' ? <AlertButton severity={alertType} onClick={() => props.history.push(`/events/${eventId}/publish`)}>Update</AlertButton> : undefined}
                >
                    {alertText}
                </Alert>

                <PayoutAlert
                    stats={stats}
                    event={event}
                    onClick={() => props.history.push(`payouts`)}
                />
            </Box>

            <GridContainer container spacing={4}>

                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardHeader title='Revenue' />
                        <CardContent>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography>Total</Typography>
                                    <PrimaryStat>{formatPrice(stats.values.total_revenue + stats.values.pos_total_revenue)}</PrimaryStat>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>Online</Typography>
                                    <PrimaryStat>{formatPrice(stats.values.total_revenue)}</PrimaryStat>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>In-Person</Typography>
                                    <PrimaryStat>{formatPrice(stats.values.pos_total_revenue)}</PrimaryStat>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardHeader title='Orders' />
                        <CardContent>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography>Total</Typography>
                                    <PrimaryStat>{stats.values.total_orders + stats.values.pos_total_orders}</PrimaryStat>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>Online</Typography>
                                    <PrimaryStat>{stats.values.total_orders}</PrimaryStat>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>In-Person</Typography>
                                    <PrimaryStat>{stats.values.pos_total_orders}</PrimaryStat>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardHeader title='Tickets Sold' />
                        <CardContent>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography>Total</Typography>
                                    <PrimaryStat>{stats.values.total_tickets + stats.values.pos_total_tickets}</PrimaryStat>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>Online</Typography>
                                    <PrimaryStat>{stats.values.total_tickets}</PrimaryStat>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>In-Person</Typography>
                                    <PrimaryStat>{stats.values.pos_total_tickets}</PrimaryStat>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardHeader title='Guest Check-ins' />
                        <CardContent>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography>Total</Typography>
                                    <PrimaryStat>{stats.values.scan_count + stats.values.pos_scan_count}</PrimaryStat>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>Online</Typography>
                                    <PrimaryStat>{stats.values.scan_count}</PrimaryStat>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>In-Person</Typography>
                                    <PrimaryStat>{stats.values.pos_scan_count}</PrimaryStat>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <ProductSalesReport eventId={eventId} />
                </Grid>

            </GridContainer>
            
            <RecentOrders eventId={eventId} />

            <ProductSales eventId={eventId} hostId={auth.hostId} />


            <GridContainer container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardHeader title='Top Cities' />
                        <CardContent>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableHeadCell>CITY</TableHeadCell>
                                        <TableHeadCell align='right'>ORDERS</TableHeadCell>
                                        <TableHeadCell align='right'>TOTAL</TableHeadCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stats.values.topCities.length === 0 && (
                                        <TableRow>
                                            <TableCell align='center' colSpan={3}>
                                                <Typography variant='subtitle2'>No orders found</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {stats.values.topCities.map(c => (
                                        <TableRow key={`${c.city}-${c.province}`}>
                                            <TableCell>{c.city}, {c.province}</TableCell>
                                            <TableCell align='right'>{c.order_count}</TableCell>
                                            <TableCell align='right'>{formatPrice(c.revenue)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Grid>

                {event.scheduled_payout_flag === 1 && (
                    <Grid item xs={12} md={6}>
                        <EventPayouts eventId={eventId} />
                    </Grid>
                )}

                <Grid item xs={12} md={6}>
                    <Card>
                        <CardHeader title='Pricing Details' />
                        <CardContent>
                            <HostPricingDetails eventId={eventId} />
                        </CardContent>
                    </Card>
                </Grid>
            </GridContainer>
        </Page>
    )
}


function PayoutAlert(props) {
    const { stats, event, onClick } = props;

    if (stats.values.payout_amt && stats.values.payout_date) {
        // Event paid out (multi-payout event)
        if (event.scheduled_payout_flag) {
            return (
                <Alert severity='success' variant='filled' action={(
                    <Button color="inherit" size="small" onClick={onClick}>View</Button>
                )}>
                    {`Final payout issued on ${moment(stats.values.payout_date).format('MMM Do YYYY')}`}
                </Alert>
            )
        // Event paid out (single-payout event)
        } else {
            return (
                <Alert severity='success' variant='filled'>
                    {`${formatPrice(stats.values.payout_amt)} paid out on ${moment(stats.values.payout_date).format('MMM Do YYYY')}`}
                </Alert>
            )
        }
    // Event not paid out
    } else {
        return null;
    }
}


const Alert = styled(_Alert)(({ theme }) => ({
    marginBottom: theme.spacing(2)
}));

const GridContainer = styled(Grid)({
    marginBottom: '64px'
});

const PrimaryStat = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold'
}));
PrimaryStat.defaultProps = { variant: 'h6' }

const TableHeadCell = styled(TableCell)(({ theme }) => ({
    ...theme.typography.subHeader
}));

const EventPageLink = styled(TextField)(({ theme }) => ({
    borderColor: theme.palette.secondary.main,
    marginHorizontal: theme.spacing(2),
}));
EventPageLink.defaultProps = { variant: 'outlined', fullWidth: true, }

const WarningButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'white',
    color: theme.palette.warning.dark,
    '&:hover': {
        backgroundColor: theme.palette.grey[300],
        cursor: 'pointer'
    }
}));
WarningButton.defaultProps = {
    color: 'inherit',
    size: 'small',
}


const TestButtonWithProps = styled(({ theme, ...other }) => <Button theme={theme} {...other} />)({
    color: (props) => props.severity === 'warning' ? props.theme.palette.warning.dark : props.theme.palette.success.dark
});
TestButtonWithProps.defaultProps = {
    color: 'inherit',
    size: 'small',
}