import React, { useState, useEffect } from 'react';
import { styled, Paper, Typography, Button, Grid, Box } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { AdminPage, ControlBar, ControlBarButton } from 'components/ui/layout';
import { LoadingState, ErrorState } from 'components/ui/states';
import FullScreenModal from 'components/popups/FullScreenModal';
import HostPricingCreator from 'components/forms/HostPricingCreator';
import HostPricingEditor from 'components/forms/HostPricingEditor';
import fetchExpresso from 'utility/fetchExpresso';
import { formatPrice } from 'utility/numbers';
import { useNotification } from 'context/notification';


export default function HostPricing(props) {

    const [state, setState] = useState({
        data: null,
        status: 'loading', // loading, error, success, settingDefault
        modalOpen: false,
        selectedPricing: null,
        dependency: 0, // incrementing this value will refresh the list of host_pricing records
        defaultPricing: {
            loading: false,
            pricingId: null
        }
    });

    const hostId = props.match.params.hostId;

    let hostName = '';

    if (props.location.search.startsWith('?host=')) {
        hostName = decodeURIComponent(props.location.search.split('&')[0].slice(6));
    }

    const { createNotification } = useNotification();

    useEffect(() => {
        fetchExpresso(`/apiv2/hosts/${hostId}/host_pricing?all=true`)
            .then(async res => {
                if (res.status !== 200) throw new Error();
                const data = await res.json();
                setState((state) => ({ ...state, status: 'success', data: data }))
            })
            .catch(() => setState((state) => ({ ...state, data: null, status: 'error' })))
    }, [state.dependency, hostId]);



    function handleModalClose() {
        setState({...state, modalOpen: false, selectedPricing: null});
    }

    function handleModalOpen() {
        setState({...state, modalOpen: true});
    }

    const handleUpdate = () => {
        const newDependency = state.dependency + 1;
        setState({...state, modalOpen: false, selectedPricing: null, dependency: newDependency });
    }

    const handleEdit = (id) => {
        setState({...state, modalOpen: true, selectedPricing: id });
    }

    const handleMakeDefault = (id) => {
        setState({ ...state, defaultPricing: { loading: true, pricingId: id } })

        fetchExpresso(`/apiv2/hosts/${hostId}/host_pricing/${id}/status`, { method: 'PATCH' })
            .then(res => {
                if (res.status === 200) {
                    const newDependency = state.dependency + 1;
                    setState({ ...state, dependency: newDependency, defaultPricing: { loading: false, pricingId: null } });
                    createNotification('Updated default pricing')
                } else {
                    throw new Error()
                }
            })
            .catch(e => {
                createNotification('Unable to change default pricing agreement');
                setState({ ...state, defaultPricing: { loading: false, pricingId: null } })
            })
    }


    return (
        <AdminPage>
                
            <ControlBar>
                <Typography variant='h4'>{hostName}</Typography>
                <ControlBarButton onClick={handleModalOpen}>New Host Pricing</ControlBarButton>
            </ControlBar>

            {state.status === 'loading' && (
                <LoadingState />
            )}
            
            {state.status === 'error' && (
                <ErrorState message='Unable to load host pricing data' />
            )}
            
            {['success', 'settingDefault'].includes(state.status) && (
                <Grid container spacing={4}>
                    {state.data.map(hostPricingRecord => (
                        <Grid key={hostPricingRecord.host_pricing_id} item xs={12} md={6} lg={4}>
                            <HostPricingItem
                                onEdit={handleEdit}
                                onMakeDefault={handleMakeDefault}
                                updatingDefault={state.status === 'settingDefault'}
                                loading={hostPricingRecord.host_pricing_id === state.defaultPricing.pricingId}
                                {...hostPricingRecord}
                            />
                        </Grid>
                    ))}
                </Grid>
            )}

            <FullScreenModal open={state.modalOpen} onClose={handleModalClose} title={state.selectedPricing ? 'Edit Host Pricing' : 'Create Host Pricing'}>
                {state.selectedPricing
                    ? <HostPricingEditor hostId={hostId} hostName={hostName} pricingId={state.selectedPricing} onUpdate={handleUpdate} onCancel={handleModalClose} />
                    : <HostPricingCreator hostId={hostId} hostName={hostName} onUpdate={handleUpdate} onCancel={handleModalClose} />
                }
            </FullScreenModal>

        </AdminPage>
    )
}

function HostPricingItem(props) {
    const isDefault = props.status === 1;
    const eventCount = props.event_count;

    const name = props.name ?? 'Untitled';

    const paysB4tFees = props.host_absorbs_b4t_fees ? 'Host pays:' : 'Customer pays:';
    const paysCcFees = props.host_absorbs_cc_fees ? 'Host pays:' : 'Customer pays:';

    const b4tFeeText = `${parseFloat((props.b4t_fee_order_pct * 100).toFixed(2))}% + ${formatPrice(props.b4t_fee_line_item_amt)}`;

    const feeMinText = props.b4t_fee_min_amt ? formatPrice(props.b4t_fee_min_amt) : 'n/a';
    const feeMaxText = props.b4t_fee_max_amt ? formatPrice(props.b4t_fee_max_amt) : 'n/a';

    const ccFeeText = `${parseFloat((props.cc_fee_order_pct * 100).toFixed(4))}% + ${formatPrice(props.cc_fee_order_amt)}`;

    const payoutSchedule = props.scheduled_payout_flag ? 'Scheduled' : 'Single'

    const reservePercent = props.payout_reserve_percent ? Number((props.payout_reserve_percent * 100).toFixed(4)) + '%' : 'n/a'

    const eventCountText = (eventCount > 0)
        ? `Used in ${eventCount} event${eventCount === 1 ? '' : 's'}`
        : 'Not used';


    // ***** POS Values ***** //

    const paysPosB4tFees = props.pos_host_absorbs_b4t_fees ? 'Host pays:' : 'Customer pays:';
    const posB4tFeeText = `${parseFloat((props.pos_b4t_fee_order_pct * 100).toFixed(2))}% + ${formatPrice(props.pos_b4t_fee_line_item_amt)}`;

    
    return (
        <ListItem>

            <ListHeader>
                <Typography variant='h6'>{name}</Typography>
                {isDefault && (
                    <DefaultIcon />
                )}
            </ListHeader>

            <ListContent>

                <Box borderBottom={0.5} borderColor='#777'>
                    <Typography variant='subtitle2'>B4T Fees</Typography>
                </Box>

                <Box display='flex' justifyContent='space-between' mt={1}>
                    <Bold>{paysB4tFees}</Bold>
                    <Bold>{b4tFeeText}</Bold>
                </Box>
                <Box display='flex' justifyContent='space-between'>
                    <Typography>Minimum:</Typography>
                    <Typography>{feeMinText}</Typography>
                </Box>
                <Box display='flex' justifyContent='space-between' mb={4}>
                    <Typography>Maximum:</Typography>
                    <Typography>{feeMaxText}</Typography>
                </Box>

                <Box borderBottom={0.5} borderColor='#777'>
                    <Typography variant='subtitle2'>Credit Card Fees</Typography>
                </Box>
                
                <Box display='flex' justifyContent='space-between' mt={1} mb={4}>
                    <Bold>{paysCcFees}</Bold>
                    <Bold>{ccFeeText}</Bold>
                </Box>

                <Box borderBottom={0.5} borderColor='#777'>
                    <Typography variant='subtitle2'>Processing Fees (POS)</Typography>
                </Box>
                
                <Box display='flex' justifyContent='space-between' mt={1} mb={4}>
                    <Bold>{paysPosB4tFees}</Bold>
                    <Bold>{posB4tFeeText}</Bold>
                </Box>


                <Box borderBottom={0.5} borderColor='#777'>
                    <Typography variant='subtitle2'>Payout Schedule</Typography>
                </Box>

                <Box display='flex' justifyContent='space-between' mt={1}>
                    <Bold>Payout</Bold>
                    <Bold>{payoutSchedule}</Bold>
                </Box>
                <Box display='flex' justifyContent='space-between' mb={4}>
                    <Typography>Reserve</Typography>
                    <Typography>{reservePercent}</Typography>
                </Box>

                <Box borderBottom={0.5} borderColor='#777'>
                    <Typography variant='subtitle2'>Events</Typography>
                </Box>

                <Box mt={1}>
                    <EventCountText>{eventCountText}</EventCountText>
                </Box>

            </ListContent>

            <ListFooter>
                <Box mr='auto'>
                    <Typography variant='caption'>{props.host_pricing_id}</Typography>
                </Box>
                <Action color='secondary' variant='outlined' disabled={isDefault || props.loading} onClick={() => props.onMakeDefault(props.host_pricing_id)}>Make Default</Action>
                <Action color='secondary' variant='outlined' disabled={props.loading} onClick={() => props.onEdit(props.host_pricing_id)}>Edit</Action>
            </ListFooter>
            
        </ListItem>
    )
}


const ListItem = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: '12px'
}));

const ListHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2)
}));

const DefaultIcon = styled(CheckCircleOutlineIcon)(({ theme }) => ({
    color: theme.palette.success.main
}));

const ListContent = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    maxWidth: theme.breakpoints.values.sm
}));

const ListFooter = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3)
}));

const EventCountText = styled(Typography)(({ theme }) => ({
    marginRight: 'auto',
    fontWeight: 'bold'
}));

const Action = styled(Button)(({ theme }) => ({
    marginLeft: '12px',
}));

const Bold = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold'
}));