import React from 'react';
import { styled, Step, StepContent, StepLabel as _StepLabel, Stepper, Box } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import moment from 'moment-timezone';


export default function ActivityLog(props) {
    const { log } = props;

    if (!log)
        return null;

    return (
        <ActivityLogCard>
            <CardHeader title='Timeline' />
            <CardContent>
                {log && (
                    <Stepper style={{ padding: 0 }} orientation='vertical'>
                        {log.map((a, index) => (
                            <Step key={index} active={true} color='secondary'>
                                <StepLabel StepIconComponent={StepIcon} StepIconProps={{ label: index + 1 }}>{moment.utc(a.action_time_stamp).local().format('MMM D, YYYY h:mm a')}</StepLabel>
                                <StepContent>
                                    <StepperText>{a.action}</StepperText>
                                    {a.action_by !== null && (
                                        <StepperText>{'by: ' + a.action_by}</StepperText>
                                    )}
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                )}
            </CardContent>
        </ActivityLogCard>
    );
}

function StepIcon({ label }) {
    return (
        <CircleLabel>
            {label}
        </CircleLabel>
    )
}

const CircleLabel = styled(Box)(({ theme }) => ({
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    color: 'white',
}));

const StepLabel = styled(_StepLabel)(({ theme }) => ({
    "& .MuiStepLabel-label" : {
        color: theme.palette.grey[700],
    }
}));

const StepperText = styled('p')(({ theme }) => ({
    margin: 0
}));

const ActivityLogCard = styled(Card)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        width: 420
    }
}));