import React, { useState } from 'react';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Link } from '@material-ui/core';

// Images
import logo from '../images/logo.png';

// Utility
import ReactGA from 'react-ga';
import fetchExpresso from 'utility/fetchExpresso';


export default function ForgotPassword() {
    const classes = useStyles();

    const [errorMessage, setErrorMessage] = useState(null)
    const [email, setEmail] = useState('')
    const [emailSentStatus, setStatus] = useState(false)

    function onEmailChange(e) {
        const value = e.target.value;
        setEmail(value)
        setErrorMessage(null);
    }

    function forgotPassword() {
        fetchExpresso(`/api/auths/forget_password`, {
            method: 'POST',
            body: { email }
        })
            .then(res => res.json())
            .then(data => {
                if (data.err === false) {
                    setStatus(true)
                    ReactGA.event({ category: "Authentication", action: 'Forgot Password', label: 'Unauthenticated' });
                } else {
                    setErrorMessage(data.message)
                }
            })
            .catch(() => {
                setErrorMessage('Please check your connection and try again later')
            });
    }

    function resendLink() {
        setStatus(false)
    }

    return (
        <div className={classes.root}>
            <div className={classes.imgContainer}>
                <Link href="/login">
                    <img alt='FrontDoor+ Icon' src={logo} className={classes.logo}></img>
                </Link>
            </div>

            <div className={classes.container}>
                <h2 style={{ textAlign: 'center', margin: 0 }}>Can't login? Don't worry!</h2>
                {
                    emailSentStatus ?
                        <div>
                            <p style={{ textAlign: 'center' }}>We have sent a recovery link to</p>
                            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>{email}</p>
                            <Button variant="outlined" color='secondary' style={{ width: '100%' }} onClick={resendLink}>Resend recovery link</Button>
                        </div>
                        :
                        <div>
                            <p>We'll send a recovery link to</p>
                            <TextField className={classes.emailInput} required type="email" variant="outlined" id="oldPassword" value={email} onChange={onEmailChange} label="Your Email" />
                        </div>
                }

                {!emailSentStatus &&
                    <div className={classes.buttonContainer}>
                        {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}
                        <Button variant="outlined" color='secondary' style={{ width: '100%' }} onClick={forgotPassword}>Send recovery link</Button>
                    </div>
                }
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            maxWidth: theme.breakpoints.values.sm,
            marginRight: 'auto',
            marginLeft: 'auto'
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2)
        }
    },
    container: {
        backgroundColor: '#fff',
        padding: theme.spacing(4),
    },
    emailInput: {
        width: '100%'
    },
    buttonContainer: {
        paddingTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    errorMessage: {
        textAlign: 'center',
        color: theme.palette.error.main,
        marginBottom: theme.spacing(3)
    },
    logo: {
        flexShrink: 0,
        padding: '1rem',
        width: '250px',
        height: '150px',
        objectFit: 'contain',
        [theme.breakpoints.down('xs')]: {
            width: '75px',
            height: '75px',
            padding: '0.5rem'
        }

    },
    imgContainer: {
        display: 'flex',
        justifyContent: 'center'
    }
}));
