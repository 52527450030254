import React, { useState, useEffect } from 'react';
import { styled, Box, Paper, Button, CircularProgress, Typography } from '@material-ui/core';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';

// Components
import OrderDetailModal from 'components/orders/OrderDetailModal';
import FullScreenModal from 'components/popups/FullScreenModal';
import { PaymentIcon, DiscountIcon, CommentIcon } from 'components/ui/icons';
import { SearchBar } from 'components/ui/inputs';

// Utility
import fetchExpresso from 'utility/fetchExpresso';
import moment from 'moment-timezone';
import { formatPrice } from 'utility/numbers';
import { AdminPage } from 'components/ui/layout';


export default function OrderLookup() {
    const [query, setQuery] = useState(null);
    const [refresh, setRefresh] = useState(0);

    const [order, setOrder] = useState({
        tranId: null,
        eventId: null
    });

    // If url includes the search param: o and e, automatically open the order with the provided id
    useEffect(() => {
        const search = new URL(document.location).searchParams;
        const order = search.get('o');
        const event = search.get('e');

        if (order && event) {
            setOrder(s => ({ ...s, tranId: order, eventId: event }))
        }
    }, []);


    const onOrderUpdate = () => {
        setOrder(s => ({ ...s, tranId: null, eventId: null }));
        setRefresh(r => r + 1);
    };


    return (
        <AdminPage>

            <SearchHeader onSearch={setQuery} />

            <SearchResults
                query={query}
                refresh={refresh}
                onSelect={(tranId, eventId) => setOrder(s => ({ ...s, tranId, eventId }))}
            />

            <FullScreenModal open={order.tranId ? true : false} onClose={() => setOrder(s => ({ ...s, tranId: null, eventId: null }))} title='Order Details'>
                <OrderDetailModal tranId={order.tranId} eventId={order.eventId} onUpdate={onOrderUpdate} />
            </FullScreenModal>

        </AdminPage>
    )
}

function SearchHeader(props) {
    const [value, setValue] = useState('');

    const disableSearch = value.length < 3;

    // Submit if enter key is hit
    function onKeyPress(e) {
        if (e.key === 'Enter' && disableSearch === false) {
            props.onSearch(value);
        }
    }

    return (
        <Box mb={4} display='flex' maxWidth={500}>
            <SearchBar
                autoFocus
                placeholder='Search by name, email, order number'
                onKeyPress={onKeyPress}
                value={value}
                onChange={setValue}
            />
            <Box ml={2}>
                <Button variant='contained' disabled={disableSearch} onClick={() => props.onSearch(value)}>Search</Button>
            </Box>
        </Box>
    )
}

function SearchResults(props) {
    const { query, onSelect, refresh } = props;

    const [state, setState] = useState({
        status: 'idle', // idle, loading, success, error
        orders: null
    });

    
    useEffect(() => {
        if (query) {
            setState(s => ({ ...s, status: 'loading' }));

            fetchExpresso(`/admin/orders?q=${query}`)
                .then(res => res.json())
                .then(data => setState(s => ({ ...s, status: 'success', orders: data })))
                .catch(e => setState(s => ({ ...s, status: 'error', orders: null })))
        } else {
            setState(s => ({ ...s, status: 'idle', orders: null }))
        }
    }, [query, refresh]);


    // No results to show yet
    if (state.status !== 'success' || state.orders.length === 0) {
        // 'idle' status shows nothing below the search bar
        return (
            <Box display='flex' justifyContent='center' alignItems='center' height={256}>
                {state.status === 'loading' && (
                    <CircularProgress />
                )}
                {state.status === 'error' && (
                    <Typography>There was a problem finding your orders</Typography>
                )}
                {state.status === 'success' && (
                    <Typography>No orders found</Typography>
                )}
            </Box>
        )
    }


    return (
        <TableContainer component={Paper}>

            <Table size='small'>

                <TableHead>

                    <TableRow>

                        <TableCellHeader width={10} variant='head'></TableCellHeader>
                        <TableCellHeader width={40} variant='head'>ORDER</TableCellHeader>
                        <TableCellHeader width={80} variant='head'>DETAIL</TableCellHeader>
                        <TableCellHeader            variant='head'>EVENT/CUSTOMER</TableCellHeader>
                        <TableCellHeader width={80} variant='head' align='right'>TIX</TableCellHeader>
                        <TableCellHeader width={80} variant='head' align='right'>TOTAL</TableCellHeader>
                        <TableCellHeader width={80} variant='head' align='right'>FD</TableCellHeader>
                        <TableCellHeader            variant='head' align='right'>WHEN</TableCellHeader>

                    </TableRow>

                </TableHead>

                <TableBody>

                    {state.orders.map(r => (
                        <BodyRow key={r.tran_id} onClick={() => onSelect(r.tran_id, r.event_id)}>
                            <TableCell>
                                <PaymentIcon status={r.status_financial} message={r.decline_message} />
                            </TableCell>
                            <TableCell>F{r.tran_id}</TableCell>
                            <TableCell>
                                <Box display='flex'>
                                    <DiscountIcon code={r.discount_code} compedBy={r.comped_by} />
                                    <CommentIcon count={r.comment_count} />
                                </Box>
                            </TableCell>
                            <EventTitleCell>
                                <EventTitleText noWrap variant='body2'>{r.event_title}</EventTitleText>
                                <Typography variant='caption'>{r.first_name + ' ' + r.last_name}</Typography>
                            </EventTitleCell>
                            <TableCell align='right'>{r.total_tickets}</TableCell>
                            <TableCell align='right'>{formatPrice(r.total)}</TableCell>
                            <TableCell align='right'>{formatPrice(r.our_fee_total)}</TableCell>
                            <TableCell align='right'><Typography noWrap variant='body2'>{moment(r.created_at).fromNow()}</Typography></TableCell>
                        </BodyRow>
                    ))}

                </TableBody>

            </Table>

        </TableContainer>
    )
}


const TableCellHeader = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold'
}));

const EventTitleCell = styled(TableCell)({
    maxWidth: 200
});

const EventTitleText = styled(Typography)({
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
});

const BodyRow = styled(TableRow)(({ theme }) => ({
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
        cursor: 'pointer'
    }
}));