import React, { useState } from 'react';

// Material UI
import { styled, useTheme } from '@material-ui/core/styles';
import { Box, Typography, TextField, Button, Radio, RadioGroup, FormControlLabel, InputAdornment, Switch, Paper, Grid } from '@material-ui/core';

import { Section, Header, Content } from 'components/ui/forms';
import { useTextInput, useNumberInput } from 'hooks/forms';


/**
 * If allowEdit = false, only the name of the host_pricing record can be changed
 * If allowDelete = false, the host_pricing record cannot be deleted
 */

export default function HostPricingForm(props) {
    const { onSubmit, onCancel, onDelete, allowDelete, allowEdit, defaultValues, loading, hostName } = props;

    const theme = useTheme();


    // Input Values
    const name           = useTextInput  ({ defaultValue: defaultValues?.name ?? undefined });
    const b4tFlat        = useNumberInput({ defaultValue: defaultValues?.b4tFlat ?? undefined,        disabled: !allowEdit });
    const b4tPercent     = useNumberInput({ defaultValue: defaultValues?.b4tPercent ?? undefined,     disabled: !allowEdit });
    const feeMin         = useNumberInput({ defaultValue: defaultValues?.feeMin ?? undefined,         disabled: (defaultValues?.enableFeeCaps === true && allowEdit) ? false : true });
    const feeMax         = useNumberInput({ defaultValue: defaultValues?.feeMax ?? undefined,         disabled: (defaultValues?.enableFeeCaps === true && allowEdit) ? false : true });
    const ccPercent      = useNumberInput({ defaultValue: defaultValues?.ccPercent ?? undefined,      disabled: !allowEdit });
    const ccFlatFee      = useNumberInput({ defaultValue: defaultValues?.ccFlatFee ?? undefined,      disabled: !allowEdit });
    const reservePercent = useNumberInput({ defaultValue: defaultValues?.reservePercent ?? undefined, disabled: (defaultValues?.enableScheduledPayouts === true && allowEdit) ? false : true });

    // Absorbing Fees
    const [absorbsB4tFees, setAbsorbsB4tFees] = useState(defaultValues?.absorbsB4tFees ?? 'customer'); // customer || host
    const [absorbsCcFees, setAbsorbsCcFees]   = useState(defaultValues?.absorbsCcFees ?? 'customer'); // customer || host

    // Enable features
    const [enableFeeCaps, setEnableFeeCaps]                   = useState(defaultValues?.enableFeeCaps ?? false); // Enables feeMin, feeMax fields
    const [enableScheduledPayouts, setEnableScheduledPayouts] = useState(defaultValues?.enableScheduledPayouts ?? false);


    function handleFeeCapToggle(e) {
        setEnableFeeCaps(e.target.checked);
        feeMin.setDisabled(!e.target.checked);
        feeMax.setDisabled(!e.target.checked);
    }

    function handleScheduledPayoutToggle(e) {
        setEnableScheduledPayouts(e.target.checked);
        reservePercent.setDisabled(!e.target.checked);
    }


    function handleSubmit() {
        if (loading) return;

        // Form Validation
        let formError = false;

        if (name.value.trim().length === 0) {
            name.setError('This field cannot be empty');
            formError = true;
        }

        if (b4tFlat.value.length === 0) {
            b4tFlat.setError('This field cannot be empty');
            formError = true;
        }

        if (b4tPercent.value.length === 0) {
            b4tPercent.setError('This field cannot be empty');
            formError = true;
        }

        if (enableFeeCaps) {
            if (feeMin.value.length === 0) {
                feeMin.setError('This field cannot be empty');
                formError = true;
            }
            if (feeMax.value.length === 0) {
                feeMax.setError('This field cannot be empty');
                formError = true;
            }
            if (feeMin.value.length > 0 && feeMax.value.length > 0) {
                if (isNaN(feeMin.value) === false && isNaN(feeMax.value) === false) {
                    if (Number(feeMin.value) > Number(feeMax.value)) {
                        feeMax.setError('Maximum must be higher than minimum');
                        formError = true;
                    }
                }
            }
        }

        if (ccPercent.value.length === 0) {
            ccPercent.setError('This field cannot be empty');
            formError = true;
        }

        if (ccFlatFee.value.length === 0) {
            ccFlatFee.setError('This field cannot be empty');
            formError = true;
        }

        if (enableScheduledPayouts) {
            if (reservePercent.value.length === 0) {
                reservePercent.setError('This field cannot be empty');
                formError = true;
            }
        }

        if (formError) {
            return;
        }

        const formData = {
            name:               name.value.trim(),
            feePerItem:         Number(b4tFlat.value || 0),
            percentFeePerOrder: Number(b4tPercent.value ? (Number(b4tPercent.value) / 100).toPrecision(6) : 0),
            feeMinAmount:       Number(feeMin.value || 0),
            feeMaxAmount:       Number(feeMax.value || 0),
            ccPercent:          Number((Number(ccPercent.value) / 100).toPrecision(6)),
            ccFlatFee:          Number(ccFlatFee.value),
            hostAbsorbsB4tFees: absorbsB4tFees === 'host' ? 1 : 0,
            hostAbsorbsCcFees:  absorbsCcFees === 'host' ? 1 : 0,
            reservePercent:     Number((Number(reservePercent.value) / 100).toFixed(4)),
            scheduledPayouts:   Number(enableScheduledPayouts) // 1 or 0
        }

        onSubmit(formData);
    }
    

    return (
        <Box width={1} display='flex' flexDirection='column' alignItems='center'>

            <Paper style={{width: '100%'}}>
                <Box pb={1} pt={1}>
                    <Typography variant='h6' style={{textAlign: 'center'}}>{hostName}</Typography>
                </Box>
            </Paper>

            <Box width={[0.9, 0.9, theme.breakpoints.values.md - 60]} my={6} display='flex' justifyContent='center' flexWrap='wrap'>
                <Box width={[1, 1, 0.6]} display='flex' flexDirection='column'>

                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Section>
                                <Header>Name</Header>
                                <Content>
                                    <TextField label='Name' variant='outlined' fullWidth {...name.formProps} />
                                </Content>
                            </Section>
                        </Grid>

                        <Grid item xs={12}>
                            <Section>

                                <Header>B4T Fee Per Ticket</Header>

                                <Content>
                                    <Typography>Who will pay this fee?</Typography>

                                    <RadioGroup row value={absorbsB4tFees} onChange={(e) => setAbsorbsB4tFees(e.target.value)}>
                                        <FormControlLabel value="customer" disabled={!allowEdit} control={<Radio />} label="Customer" />
                                        <FormControlLabel value="host" disabled={!allowEdit} control={<Radio />} label="Host" />
                                    </RadioGroup>

                                    <Box mt={4} />

                                    <Typography>Fee percentage (%) + Fee amount ($)</Typography>
                                    <Box display='flex' alignItems='center' mt={1}>
                                        <TextField placeholder='Percent' variant='outlined' {...b4tPercent.formProps} InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment>}} />
                                        <PlusIcon variant='h4'>+</PlusIcon>
                                        <TextField placeholder='Amount' variant='outlined' {...b4tFlat.formProps} InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>}} />
                                    </Box>

                                    <Box mt={4}>
                                        <FormControlLabel
                                            control={<Switch checked={enableFeeCaps} disabled={!allowEdit} onChange={handleFeeCapToggle} />}
                                            label="Set Fee Limits"
                                        />
                                        <Box display='flex' mt={1}>
                                            <TextField label='Minimum' variant='outlined' {...feeMin.formProps} />
                                            <Box width={28} />
                                            <TextField label='Maximum' variant='outlined' {...feeMax.formProps} />
                                        </Box>
                                    </Box>
                                </Content>


                            </Section>
                        </Grid>

                        <Grid item xs={12}>
                            <Section>

                                <Header>CC Fee Per Order</Header>

                                <Content>
                                    <Typography>Who will pay this fee?</Typography>
                                    <RadioGroup row={true} value={absorbsCcFees} onChange={(e) => setAbsorbsCcFees(e.target.value)}>
                                        <FormControlLabel value="customer" disabled={!allowEdit} control={<Radio />} label="Customer" />
                                        <FormControlLabel value="host" disabled={!allowEdit} control={<Radio />} label="Host" />
                                    </RadioGroup>

                                    <Box mt={4} />

                                    <Typography>Fee percentage (%) + Fee amount ($)</Typography>
                                    <Box display='flex' alignItems='center' mt={1}>
                                        <TextField placeholder='Percent' variant='outlined' {...ccPercent.formProps} InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment>}} />
                                        <PlusIcon variant='h4'>+</PlusIcon>
                                        <TextField placeholder='Amount' variant='outlined' {...ccFlatFee.formProps} InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>}} />
                                    </Box>
                                </Content>

                            </Section>
                        </Grid>

                        <Grid item xs={12}>
                            <Section>

                                <Header>Scheduled Payouts</Header>

                                <Content>
                                    <FormControlLabel
                                        control={<Switch checked={enableScheduledPayouts} disabled={!allowEdit} onChange={handleScheduledPayoutToggle} />}
                                        label="Enable Scheduled Payouts"
                                    />

                                    <Box maxWidth='50%' display='flex' flexDirection='column' mt={4}>
                                        <TextField
                                            {...reservePercent.formProps}
                                            label='Reserve'
                                            variant='outlined'
                                            InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                                        />
                                    </Box>
                                </Content>

                            </Section>
                        </Grid>
                    </Grid>


                    <Footer>
                        {allowDelete && (
                            <DeleteButton variant='outlined' onClick={onDelete} disabled={loading}>Delete</DeleteButton>
                        )}
                        <CancelButton variant='outlined' onClick={onCancel} disabled={loading}>Cancel</CancelButton>
                        <SaveButton variant='outlined' onClick={handleSubmit} disabled={loading}>Save</SaveButton>
                    </Footer>

                </Box>
            </Box>
        </Box>
    )
}

const PlusIcon = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
}));

const Footer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4)
}));

const SaveButton = styled(Button)(({ theme }) => ({
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main
}));

const CancelButton = styled(Button)({
    marginRight: '1rem'
});

const DeleteButton = styled(Button)(({ theme }) => ({
    marginRight: 'auto',
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
}));