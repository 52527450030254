export default function fetchExpresso(endpoint, options) {

    // Setup custom options object for fetch call
    let customOptions = {};

    if (options) {
        const headers = options.headers || null;
        const body = options.body || undefined;

        customOptions = {
            method: options.method,
            headers: {
                'Content-Type': 'application/json',
                ...headers
            },
            body: JSON.stringify(body)
        }
    } else {
        customOptions = {
            method: 'GET',
            headers: {}
        }
    }


    // Authorization
    const token = JSON.parse(localStorage.getItem(process.env.REACT_APP_JWT_KEY));

    if (token) {
        customOptions.headers['x-access-token'] = token.token;
    }



    return fetch(process.env.REACT_APP_API_URL + endpoint, customOptions);
}