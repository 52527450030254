import React, { useState, useEffect } from 'react';

// Material-UI
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { styled, CircularProgress, Typography, Box } from '@material-ui/core';

// Utility
import { abbreviateNumber } from 'utility/numbers';
import fetchExpresso from 'utility/fetchExpresso';
import moment from 'moment-timezone';


export default function MonthlyPosReport() {

    const [state, setState] = useState({
        data: null,
        loading: true,
        error: false
    });

    const { data, loading, error } = state;


    useEffect(() => {
        const tz = moment.tz.guess();
        let start = moment().subtract(12, 'months').startOf('month').format('YYYY-MM-DDTHH:mm:ss');
        let end = moment().format('YYYY-MM-DDTHH:mm:ss');

        fetchExpresso(`/admin/reports/monthly_pos_report?tz=${tz}&start=${start}&end=${end}`)
            .then(async res => {
                if (res.status !== 200) {
                    setState(s => ({ ...s, loading: false, error: true }));
                    return;
                }
                const data = await res.json();
                setState(s => ({ ...s, data: data, loading: false, error: false }));
            })
            .catch(e => {
                setState(s => ({ ...s, loading: false, error: true }));
            })
    }, []);

    if (loading) {
        return (
            <Root>
                <Status>
                    <CircularProgress disableShrink />
                </Status>
            </Root>
        );
    }

    if (error) {
        return (
            <Root>
                <Status>
                    <Typography>Unable to load stats</Typography>
                </Status>
            </Root>
        );
    }


    return (
        <Root>

            <CardHeader title='Monthly Report (POS)' />

            <CardContent>

                <TableContainer component={Box}>

                    <Table size='small'>

                        <TableHead>

                            <TableRow>

                                <TableCellHeader>M</TableCellHeader>
                                <TableCellHeader align='right'>EV</TableCellHeader>
                                <TableCellHeader align='right'>PRC</TableCellHeader>
                                <TableCellHeader align='right'>FD</TableCellHeader>
                                <TableCellHeader align='right'>ORD</TableCellHeader>
                                <TableCellHeader align='right'>TIX</TableCellHeader>
                                <TableCellHeader align='right'>TKT</TableCellHeader>
                                <TableCellHeader align='right'>FD%</TableCellHeader>
                                <TableCellHeader align='right'>FD/TKT</TableCellHeader>
                                <TableCellHeader align='right'>TKT/ORD</TableCellHeader>

                            </TableRow>

                        </TableHead>

                        <TableBody>

                            {data.map(r => (
                                <TableRow key={r.Month}>
                                    <TableCell><TextNoWrap variant='body2'>{r.Month}</TextNoWrap></TableCell>
                                    <TableCell align='right'>{r.event_count}</TableCell>
                                    <TableCell align='right'>{`$${abbreviateNumber(r.gross_total, 0, 1)}`}</TableCell>
                                    <TableCell align='right'>{`$${abbreviateNumber(r.our_fee_total, 0, 1)}`}</TableCell>
                                    <TableCell align='right'>{r.order_count}</TableCell>
                                    <TableCell align='right'>{r.ticket_count}</TableCell>
                                    <TableCell align='right'>{`$${r.avg_tkt_price.toFixed(0)}`}</TableCell>
                                    <TableCell align='right'>{`${r.avg_fee_percent.toFixed(1)}%`}</TableCell>
                                    <TableCell align='right'>{`$${r.avg_fee_per_ticket.toFixed(2)}`}</TableCell>
                                    <TableCell align='right'>{r.avg_tix_per_order.toFixed(1)}</TableCell>
                                </TableRow>
                            ))}

                        </TableBody>

                    </Table>

                </TableContainer>

            </CardContent>

        </Root>
    )
}

const Root = styled(Card)({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width :'100%'
});

const Status = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
});

const TableCellHeader = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold'
}));

const TextNoWrap = styled(Typography)({
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
});
