import React from 'react';
import { styled, Chip } from '@material-ui/core';
import moment from 'moment-timezone';


export default function PayoutChip(props) {
    const now = moment();
    const end = moment(props.payoutDate);
    const finalPayout = props.finalPayout;
    const inTransit = moment.duration(now.diff(end)).asDays() <= 2

    if (inTransit) {
        return (
            <TransitChip label='In Transit' />
        )
    }
    else if (finalPayout)
        return (
            <PaidChip label='Final Payout' />
        )
    else {
        return (
            <PaidChip label='Paid' />
        )
    }
}


const TransitChip = styled(Chip)(({ theme }) => ({
    color: theme.palette.info.main,
    borderColor: theme.palette.info.main,
}));

TransitChip.defaultProps = { variant: 'outlined', size: 'small' };


const PaidChip = styled(Chip)(({ theme }) => ({
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
}));

PaidChip.defaultProps = { variant: 'outlined', size: 'small' };