import React from 'react';

// Material-UI
import { Table, TableHead, TableBody, TableRow, TableCell as _TableCell } from '@material-ui/core';
import { styled, Typography } from '@material-ui/core';

// Components
import PayoutChip from 'components/payouts/PayoutChip';

// Utility
import { formatPrice } from 'utility/numbers';
import moment from 'moment-timezone';


export default function EventPayoutsTable(props) {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeadCell align='left'>WHEN</TableHeadCell>
                    <TableHeadCell align='right'>TOTAL</TableHeadCell>
                    <TableHeadCell align='right'>STATUS</TableHeadCell>
                </TableRow>
            </TableHead>
            <TableBody>

                <TableRow>
                    <TableCellEmpty></TableCellEmpty>
                </TableRow>

                {props.payouts.length > 0 && props.payouts.map(p => (
                    <Payout key={p.payout_id} {...p} />
                ))}

                {props.payouts.length === 0 && (
                    <TableRow>
                        <TableCell align='center' colSpan={6}>
                            <Typography variant='subtitle2'>No payouts found</Typography>
                        </TableCell>
                    </TableRow>
                )}

            </TableBody>
        </Table>
    )
}


function Payout(props) {

    const date = moment(props.payout_date).get('year') === moment().get('year')
        ? moment(props.payout_date).format('MMM Do')
        : moment(props.payout_date).format('MMM Do YYYY')
    
    return (
        <TableRow>
            <TableCell align='left'>{date}</TableCell>
            <TableCell align='right'>{formatPrice(props.payout_amt)}</TableCell>
            <TableCell width={120} align='right'>
                <PayoutChip
                    payoutDate={props.payout_date}
                    finalPayout={Boolean(props.final_payout_flag)}
                />
            </TableCell>
        </TableRow>
    )
}

const TableHeadCell = styled(_TableCell)(({ theme }) => ({
    ...theme.typography.subHeader
}));

const TableCell = styled(_TableCell)(({ theme }) => ({
    border: 'none',
    paddingTop: 0
}));

const TableCellEmpty = styled(_TableCell)(({ theme }) => ({
    border: 'none',
    paddingBottom: 0
}));