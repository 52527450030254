import React from 'react';

// Material UI
import { styled, Box, Dialog as _Dialog, AppBar as _AppBar, Toolbar, IconButton, Slide, Button, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


// Can be used as a wrapper for any component that needs to display in a fullscreen modal
export default function FullScreenModal(props) {
    const { open, onClose, title, cancelText } = props;

    function handleClose(e, reason) {
        if (reason !== 'backdropClick') {
            onClose();
        }
    }

    return (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar position='sticky'>
                <Toolbar>
                    <Box display='flex'>
                        <Box alignSelf='flex-start'>
                            {cancelText ? (
                                <CancelButton edge="start" color="inherit" onClick={onClose} aria-label="close" startIcon={<CancelIcon />}>
                                    {cancelText}
                                </CancelButton>
                            ) : (
                                <CloseButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                                    <CancelIcon />
                                </CloseButton>
                            )}
                        </Box>
                        <Box position='absolute' style={{ left: '50%', transform: 'translate(-50%,0)' }} alignSelf='center'>
                            <Title>{title}</Title>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            {open && props.children}
        </Dialog>
    )
}

const Dialog = styled(_Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        backgroundColor: theme.palette.grey[200]
    }
}));

const AppBar = styled(_AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.light
}));

const CancelButton = styled(Button)(({ theme }) => ({
    color: theme.palette.common.white
}));

const CancelIcon = styled(CloseIcon)(({ theme }) => ({
    color: theme.palette.common.white
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.common.white
}));

const Title = styled(Typography)(({ theme }) => ({
    fontSize: '1.5rem',
    color: theme.palette.common.white
}));