import React from 'react';
import { styled, Typography, Box, Card, CardHeader, CardContent } from '@material-ui/core';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { formatPhoneWithDashes } from 'utility/numbers';
import moment from 'moment-timezone';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { useNotification } from 'context/notification';


export default function OrderDetails(props) {
    const { admin } = props;
    const {
        tran_id,
        event_title,
        host_name,
        status_financial,
        refunded_at,
        decline_message,
        created_at,
        brand,
        last4,
        first_name,
        last_name,
        email,
        phone,
        company_name,
        address1,
        city,
        prov_state,
        postal_code
    } = props.order;

    const { createNotification } = useNotification();

    const copyToClipboard = async (value) => {
        try {
            await navigator.clipboard.writeText(value);
            createNotification('Copied to clipboard!')
        } catch(e) {
            createNotification('Unable to copy to clipboard')
        }
    }

    return (
        <Card>
            <CardHeader title='Summary' />
            <CardContent>

                <Box mb={2}>
                    <OrderStatus
                        status_financial={status_financial}
                        refunded_at={refunded_at}
                        decline_message={decline_message}
                    />
                </Box>

                {props.order && (
                    <Table size='small'>
                        <TableBody>

                            <TableRow>
                                <TableCell width={80}>Order #</TableCell>
                                <TableCell onClick={() => copyToClipboard('F' + tran_id)} align='right'>F{tran_id}</TableCell>
                            </TableRow>
                            

                            <TableRow>
                                <TableCell width={80}>Order Date</TableCell>
                                <TableCell align='right'>{created_at ? moment(created_at).format('MMM D, YYYY h:mm a') : ''}</TableCell>
                            </TableRow>

                            {(brand && last4) && (
                                <TableRow width={80}>
                                    <TableCell>Payment</TableCell>
                                    <TableCell align='right'>{brand + ' ending with ' + last4}</TableCell>
                                </TableRow>
                            )}

                            <TableRow>
                                <TableCell width={80}>Event</TableCell>
                                <TableCell align='right'>{event_title}</TableCell>
                            </TableRow>
                            
                            {admin === true && (
                                <TableRow>
                                    <TableCell width={80}>Host</TableCell>
                                    <TableCell align='right'>{host_name}</TableCell>
                                </TableRow>
                            )}

                            <TableRow>
                                <HeaderCell><HeaderText>Customer</HeaderText></HeaderCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell onClick={() => copyToClipboard(first_name + ' ' + last_name)} align='right'>{first_name + ' ' + last_name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell onClick={() => copyToClipboard(email)} align='right'>{email}</TableCell>
                            </TableRow>
                            {phone ? (
                                <TableRow>
                                    <TableCell>Phone</TableCell>
                                    <TableCell onClick={() => copyToClipboard(formatPhoneWithDashes(phone))} align='right'>{formatPhoneWithDashes(phone)}</TableCell>
                                </TableRow>
                            ) : null}
                            {company_name ? (
                                <TableRow>
                                    <TableCell>Company Name</TableCell>
                                    <TableCell onClick={() => copyToClipboard(company_name)} align='right'>{company_name}</TableCell>
                                </TableRow>
                            ) : null}
                            {address1 ? (
                                <TableRow>
                                    <TableCell>Address</TableCell>
                                    <TableCell onClick={() => copyToClipboard(address1)} align='right'>{address1}</TableCell>
                                </TableRow>
                            ) : null}

                            <TableRow>
                                <TableCell>Location</TableCell>
                                <TableCell onClick={() => copyToClipboard(`${city}, ${prov_state} ${postal_code}`)} align='right'>{city}, {prov_state} {postal_code}</TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                )}
            </CardContent>
        </Card>
    );
}


function OrderStatus(props) {
    const { status_financial, refunded_at, decline_message } = props;

    if (status_financial >= 1000 && status_financial <= 1999) {
        return (
            <Alert severity='success' icon={<CheckCircleOutlinedIcon fontSize='inherit' />}>
                <AlertTitle style={{ marginBottom: 0 }}>Approved</AlertTitle>
            </Alert>
        )
    }

    if (status_financial === 3000) {
        return (
            <Alert severity='warning' icon={<RotateLeftIcon fontSize='inherit' />}>
                <AlertTitle>Refunded</AlertTitle>
                This order was refunded {moment(refunded_at).format('MMM D, YYYY h:mm a')}
            </Alert>
        )
    }

    if (status_financial >= 2000 && status_financial <= 2999) {
        return (
            <Alert severity='error'>
                <AlertTitle>Declined</AlertTitle>
                {decline_message || 'This order was declined'}
            </Alert>
        )
    }
}


const HeaderCell = styled(TableCell)(({ theme }) => ({
    border: 'none',
}));

const HeaderText = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(2),
    fontWeight: 'bold'
}));