import React from 'react';

// Material UI
import { styled, AppBar, Toolbar, Button, Box, Typography, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// Utility
import { useHistory } from 'react-router-dom';
import { useEvent } from 'context/event';
import moment from 'moment-timezone';


/**
 * Displays on all screen sizes
 * 
 * This app bar shows when the user is viewing an event
 * Its only function is to let the user navigate back to the Event screen
 */


export default function EventAppBar() {
    const event = useEvent();
    const history = useHistory();


    let publishStatus;

    if (event.live_flag === 1) {
        event.unlisted_flag === 1
            ? publishStatus = 'Unlisted'
            : publishStatus = 'Published'
    }

    if (event.live_flag === 0) {
        publishStatus = 'Draft'
    }

    const endDate = moment.tz(event.end_date, event.time_zone);
    const currentDate = moment().tz(event.time_zone);

    if (endDate < currentDate) {
        publishStatus = 'concluded';
    }

    
    return (
        <Box displayPrint='none'>

            <TopAppBar position='fixed'>

                <SdToolbar>

                    <BackButtonDesktop onClick={() => history.push(`/events`)} startIcon={<ArrowBackIcon />}>Events</BackButtonDesktop>
                    <BackButtonMobile onClick={() => history.push(`/events`)}><ArrowBackIcon /></BackButtonMobile>

                    <Box display='flex' width='100%' justifyContent='flex-end'>
                        <Box width={['260px', '460px', '800px', '1100px']}>
                            <Title>{event.event_title || ''}</Title>
                            <Box display='flex' width='100%' justifyContent='flex-end'>
                                <Subtitle variant='subtitle1'>{event.start_date ? moment(event.start_date).format('MMM Do YYYY') : ''}</Subtitle>
                                {publishStatus === 'Draft' && <Draft variant='subtitle1'>{publishStatus}</Draft>}
                                {publishStatus === 'Unlisted' && <Unlisted variant='subtitle1'>{publishStatus}</Unlisted>}
                                {publishStatus === 'Published' && <Published variant='subtitle1'>{publishStatus}</Published>}
                            </Box>
                        </Box>
                    </Box>

                </SdToolbar>

            </TopAppBar>

            <Toolbar />

        </Box>
    )
}


const TopAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.dark,
    zIndex: theme.zIndex.drawer + 1,
    color: '#000'
}));

const SdToolbar = styled(Toolbar)(({ theme }) => ({
    [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    }
}));

const BackButtonDesktop = styled(Button)(({ theme }) => ({
    color: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
        display: 'none'
    }
}));

const BackButtonMobile = styled(IconButton)(({ theme }) => ({
    color: theme.palette.common.white,
    [theme.breakpoints.up('md')]: {
        display: 'none'
    }
}));


const Title = styled(Typography)(({ theme }) => ({
    width: '100%',
    textAlign: 'end',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '20px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.common.white
}));

const Subtitle = styled(Typography)(({ theme }) => ({
    display: 'inline',
    color: theme.palette.grey[200],
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
}));

const Draft = styled(Typography)(({ theme }) => ({
    display: 'inline',
    marginLeft: theme.spacing(1),
    color: theme.palette.warning.main,
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
}));

const Unlisted = styled(Typography)(({ theme }) => ({
    display: 'inline',
    marginLeft: theme.spacing(1),
    color: theme.palette.info.main,
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
}));

const Published = styled(Typography)(({ theme }) => ({
    display: 'inline',
    marginLeft: theme.spacing(1),
    color: theme.palette.success.main,
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
}));