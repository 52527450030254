import { createContext, useContext } from 'react';


export const EventContext = createContext();
export const EventDispatchContext = createContext();

export function useEvent() {
    return useContext(EventContext);
}

export function useEventDispatch() {
    return useContext(EventDispatchContext);
}