import React, { useReducer, useEffect } from 'react';

// Components
import { Hidden, Box } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';
import EventAppBar from 'components/navigation/EventAppBar';
import EventDrawer from 'components/navigation/EventDrawer';
import EventTabs from 'components/navigation/EventTabs';

// Pages
import EventDashboard from 'pages/event/EventDashboard';
import EventDetails from 'pages/event/EventDetails';
import Orders from 'pages/Orders';
import OnsiteOrders from 'pages/event/OnsiteOrders';
import Products from 'pages/Products';
import Discounts from 'pages/Discounts';
import Checkout from 'pages/event/Checkout';
import Publish from 'pages/event/Publish';
import Payouts from 'pages/event/Payouts';
import Registration from 'pages/event/Registration';
import Downloads from 'pages/event/Downloads';
import Promote from 'pages/event/Promote';
import fetchExpresso from 'utility/fetchExpresso';

// Other
import { EventContext, EventDispatchContext } from 'context/event';
import PageNotFound from 'pages/PageNotFound';


const defaultState = {};

function reducer(state, action) {
    switch (action.type) {
        case 'set_event':
            return { ...state, ...action.event };

        default:
            throw new Error();
    }
};


export default function EventNavigator(props) {
    const path = props.match.path;
    const eventId = props.match.params.id;

    const [event, dispatch] = useReducer(reducer, defaultState);
    
    const eventLoaded = event !== defaultState;


    useEffect(() => {
        fetchExpresso(`/apiv2/events/${eventId}`)
            .then(async res => {
                if (res.status === 200) {
                    const data = await res.json();
                    dispatch({
                        type: 'set_event',
                        event: { ...data }
                    });
                }
            })
            .catch(e => null)
    }, [eventId]);


    return (
        <EventContext.Provider value={event}>
            <EventDispatchContext.Provider value={dispatch}>

                <Box display='flex' flexDirection={'column'} width='100%' height='100%'>
                    

                    <EventAppBar />

                    <Hidden mdUp>
                        <EventTabs />
                    </Hidden>


                    <Box display='flex' flexGrow={1}>
                        

                        <Hidden smDown>
                            <EventDrawer />
                        </Hidden>

                        {eventLoaded && (
                            <Box flexGrow={1} maxWidth={'100%'}>
                                <Switch>
                                    <Route exact path={`${path}/`} component={EventDashboard} />
                                    <Route exact path={`${path}/details`} component={EventDetails} />
                                    <Route exact path={`${path}/checkout`} component={Checkout} />
                                    <Route exact path={`${path}/orders`} component={Orders} />
                                    <Route exact path={`${path}/onsite`} component={OnsiteOrders} />
                                    <Route exact path={`${path}/tickets`} component={Products} />
                                    <Route exact path={`${path}/discounts`} component={Discounts} />
                                    <Route exact path={`${path}/payouts`} component={Payouts} />
                                    <Route exact path={`${path}/publish`} component={Publish} />
                                    <Route exact path={`${path}/registration`} component={Registration} />
                                    <Route exact path={`${path}/downloads`} component={Downloads} />
                                    <Route exact path={`${path}/promote`} component={Promote} />

                                    <Route path="*" component={PageNotFound} />
                                </Switch>
                            </Box>
                        )}


                    </Box>


                </Box>

            </EventDispatchContext.Provider>
        </EventContext.Provider>
    );
}