import React, { useState, useEffect } from 'react';
import { styled, Typography, Box, Button, Link, CircularProgress } from '@material-ui/core';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import fetchExpresso from 'utility/fetchExpresso';
import moment from 'moment-timezone';


export function useInbox(hostId, admin) {
    const [state, setState] = useState({
        messages: [],
        status: 'loading' // loading, error, success
    });

    const unreadCount = state.messages.reduce((p, n) => {
        if (n.clicked) {
            return p;
        } else {
            return p + 1;
        }
    }, 0);

    useEffect(() => {
        fetchExpresso(`/apiv2/hosts/${hostId}/inbox`)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error();
                }
                return res.json();
            })
            .then(data => {
                setState(s => ({ status: 'success', messages: data }));
            })
            .catch(e => {
                setState(s => ({ status: 'error', messages: [] }));
            });
    }, [hostId]);

    const markAllRead = () => {
        // Don't mark as read if user is admin
        if (admin) return;

        // Mark messages as read locally
        setState(s => ({
            ...s,
            messages: s.messages.map(m => {
                m.clicked = 1;
                return m;
            })
        }));
        
        // Mark messages as read on the api
        fetchExpresso(`/apiv2/hosts/${hostId}/inbox`, { method: 'PUT' })
            .catch(err => null)
    };
    
    const markRead = (id) => {
        // Don't mark as read if user is admin
        if (admin) return;

        // Mark messages as read locally
        setState(s => ({
            ...s,
            messages: s.messages.map(m => {
                if (m.inbox_id === id) {
                    m.clicked = 1;
                }
                return m;
            })
        }));
        
        // Mark messages as read on the api
        fetchExpresso(`/apiv2/hosts/${hostId}/inbox/${id}`, { method: 'PUT' })
            .catch(() => null)
    };

    return { state, unreadCount, markAllRead, markRead };
}

export function InboxDesktop(props) {
    const { state, markAllRead, markRead } = props;
    const { messages, status } = state;

    const unreadMessages = messages.some(m => m.clicked === 0);


    return (
        <Box>
            <Header>
                <Title>Inbox</Title>
                <Button color='secondary' onClick={markAllRead} disabled={unreadMessages === false} startIcon={<DoneAllIcon />}>Mark all read</Button>
            </Header>

            <Box p={2} minWidth={360}>
                <Table>
                    <TableBody>
                        {status === 'success' && messages.map(m => (
                            <TableRow key={m.inbox_id}>
                                <StatusTableCell>
                                    {m.clicked === 1
                                        ? <ReadIndicator />
                                        : <UnreadIndicator />
                                    }
                                </StatusTableCell>
                                <TitleTableCell>
                                    <TitleLink href={m.url} onClick={() => markRead(m.inbox_id)} target="_blank" rel='noopener'>{m.title}</TitleLink>
                                </TitleTableCell>
                                <TableCell align='right'>
                                    <DateText>{moment(m.created_at).fromNow()}</DateText>
                                </TableCell>
                            </TableRow>
                        ))}
                        {(status === 'success' && messages.length === 0) && (
                            <TableRow>
                                <StatusCell align='center' colSpan={3}>
                                    Your inbox is empty
                                </StatusCell>
                            </TableRow>
                        )}
                        {status === 'loading' && (
                            <TableRow>
                                <StatusCell align='center' colSpan={3}>
                                    <CircularProgress size={24} />
                                </StatusCell>
                            </TableRow>
                        )}
                        {status === 'error' && (
                            <TableRow>
                                <StatusCell align='center' colSpan={3}>
                                    Unable to load messages
                                </StatusCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Box>
        </Box>
    );
}

export function InboxMobile(props) {
    const { state, markAllRead, markRead } = props;
    const { messages, status } = state;


    const unreadMessages = messages.some(m => m.clicked === 0);


    return (
        <Box>
            <Header>
                <Title>Inbox</Title>
                <Button color='secondary' onClick={markAllRead} disabled={unreadMessages === false} startIcon={<DoneAllIcon />}>Mark all read</Button>
            </Header>

            <Box p={1}>
                {status === 'success' && messages.map(m => (
                    <Box key={m.inbox_id} display='flex' alignItems={'center'} paddingY={1}>
                        <Box width={8}>
                            {m.clicked === 1
                                ? <ReadIndicator />
                                : <UnreadIndicator />
                            }
                        </Box>
                        <Box paddingX={1}>
                            <TitleLink href={m.url} onClick={() => markRead(m.inbox_id)} target="_blank" rel='noopener'>{m.title}</TitleLink>
                            <DateText>{moment(m.created_at).fromNow()}</DateText>
                        </Box>
                    </Box>
                ))}
                {(status === 'success' && messages.length === 0) && (
                    <Box display='flex' justifyContent={'center'} alignItems={'center'} p={2}>
                        <Typography>Your inbox is empty</Typography>
                    </Box>
                )}
                {status === 'loading' && (
                    <Box display='flex' justifyContent={'center'} alignItems={'center'} p={2}>
                        <CircularProgress size={24} />
                    </Box>
                )}
                {status === 'error' && (
                    <Box display='flex' justifyContent={'center'} alignItems={'center'} p={2}>
                        <Typography>Unable to load messages</Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
}

const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[500]
}));

const Title = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold'
}));

const StatusTableCell = styled(TableCell)(({ theme }) => ({
    paddingLeft: 0,
    paddingRight: 0
}));

const TitleTableCell = styled(TableCell)(({ theme }) => ({
    paddingLeft: theme.spacing(1)
}));

const TitleLink = styled(Link)(({ theme }) => ({
    color: theme.palette.grey[900],
    fontWeight: 600
}));

const DateText = styled(Typography)(({ theme }) => ({
    ...theme.typography.caption,
    color: theme.palette.grey[700]
}));

const StatusCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 0
}));

const UnreadIndicator = styled(Box)(({ theme }) => ({
    height: 8,
    width: 8,
    backgroundColor: theme.palette.success.main,
    borderRadius: 8,
}));

const ReadIndicator = styled(Box)(({ theme }) => ({
    height: 8,
    width: 8,
    backgroundColor: 'transparent',
    borderRadius: 8,
}));