import React, { useState } from 'react';

import { styled, Box, Typography, Divider as _Divider, IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import moment from 'moment-timezone';
import { useAuth } from 'context/auth';



export default function CommentList(props) {
    const { comments, onEdit, onDelete } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const { auth } = useAuth();


    const [menu, setMenu] = useState({ open: false, id: null });


    const handleMenuOpen = (event, id) => {
        setAnchorEl(event.currentTarget);
        setMenu({ open: true, id: id });
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setMenu({ open: false, id: null });
    };

    const handleEdit = () => {
        onEdit(menu.id);
        handleMenuClose();
    };
    
    const handleDelete = () => {
        onDelete(menu.id)
        handleMenuClose();
    };


    return (
        <>
            {comments.map((n,i) => (
                <Box key={n.comment_id}>

                    <Box display='flex'>

                        <CommentAvatar>{formatName(n.name)}</CommentAvatar>

                        <Box flex={1}>
                            <CommentAuthor>{n.name}</CommentAuthor>

                            <CommentText>{n.comment}</CommentText>

                            <Box display='flex' alignItems='center' justifyContent='space-between'>
                                <CommentDate>
                                    {moment(n.comment_time_stamp).fromNow()}
                                    {n.comment_time_stamp !== n.edit_time_stamp ? ' (Edited)' : ''}
                                </CommentDate>
                                {n.user_id === auth.userId && (
                                    <IconButton size='small' onClick={e => handleMenuOpen(e, n.comment_id)}>
                                        <MoreHorizIcon fontSize='small' />
                                    </IconButton>
                                )}
                            </Box>

                        </Box>

                    </Box>


                    {comments.length > 1 && i < comments.length - 1 && (
                        <Divider />
                    )}
                </Box>
            ))}

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleEdit}>
                    <EditIcon color='action' />
                    <MenuItemText>Edit Comment</MenuItemText>
                </MenuItem>
                <MenuItem onClick={handleDelete}>
                    <DeleteIcon color='action' />
                    <MenuItemText>Delete Comment</MenuItemText>
                </MenuItem>
            </Menu>
        </>
    )
}


// Convert full name to initials
function formatName(name) {
    if (typeof name === 'string' === false || name.trim().length === 0) {
        return '';
    }

    const nameArray = name.trim().split(' ');

    let value = name[0].toUpperCase();

    if (nameArray.length > 1) {
        // Last name exists
        value += nameArray[1][0].toUpperCase();
    }

    return value;
}

const CommentAvatar = styled(Box)(({ theme }) => ({
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
    fontWeight: 'bold',
    color: 'white',
    marginRight: theme.spacing(1)
}));

const CommentAuthor = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    display: 'inline'
}));

const CommentDate = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[700],
    textAlign: 'right',
}));
CommentDate.defaultProps = { variant: 'caption' }

const CommentText = styled(Typography)(({ theme }) => ({
    whiteSpace: 'pre-wrap',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
}));

const Divider = styled(_Divider)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
}));

const MenuItemText = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(1)
}));