import React from 'react';

// Utility
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from 'context/auth';


// Wrapper component for all protected pages
// Redirect user based on their authentication token
export default function PrivateRoute({component: Component, ...rest}) {
    const { auth } = useAuth();
    const { hostRequired } = rest;

    return (
        <Route
            {...rest}
            render={(props) => {
                if (!auth) { return <Redirect to='/login' /> }
                if (auth.hostId || !hostRequired) { return <Component {...props} /> }
                if (!auth.hostId) { return <Redirect to='/host_select' />}
            }}
        />
    )
}