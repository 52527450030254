import React, { useState } from 'react';

// Material-UI
import { styled, useTheme } from '@material-ui/core/styles';
import { TextField, Button, Box, Grid, Typography, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

// Material Date Picker
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';

import { Section, Header, Content } from 'components/ui/forms';

// Utility
import moment from 'moment-timezone';
import { getMomentFromDateAndTime } from 'utility/validation';
import { CA_TIMEZONES } from 'utility/dates';


/**
 * Form specifically used for creating a copy of an existing event
 * EventForm.js could have been reused, but I created a new form component in the interest of time :)
 */

export default function EventCopyForm(props) {
    // Props
    const { defaultValues, onSubmit, onCancel, loading } = props;

    // State
    const [values, setValues] = useState({
        title: { value: defaultValues?.title ?? '', error: false, message: '' },
        description: { value: defaultValues?.description ?? '', error: false, message: '' },
        startDate: { value: defaultValues?.startDate ?? moment({ hour: 12, minute: 0 }), error: false, message: '' },
        startTime: { value: defaultValues?.startDate ?? moment({ hour: 12, minute: 0 }), error: false, message: '' },
        endDate: { value: defaultValues?.endDate ?? moment({ hour: 23, minute: 45 }), error: false, message: '' },
        endTime: { value: defaultValues?.endDate ?? moment({ hour: 23, minute: 45 }), error: false, message: '' },
        timezone: { value: defaultValues?.timezone ?? '', error: false, message: '' },
        rangeError: false
    });

    const { title, description, startDate, startTime, endDate, endTime, timezone, rangeError } = values;

    // Hooks
    const theme = useTheme();

    function handleFormChange(e) {
        const { id, value } = e.target;
        setValues({ ...values, [id]: { value, error: false, message: '' } });
    }

    function handleStartDateChange(value) {
        setValues({...values, startDate: { value, error: false, message: '' }});
    }
    
    function handleStartTimeChange(value) {
        setValues({...values, startTime: { value, error: false, message: '' }});
    }

    function handleEndDateChange(value) {
        setValues({ ...values, endDate: { value, error: false, message: '' } });
    }

    function handleEndTimeChange(value) {
        setValues({ ...values, endTime: { value, error: false, message: '' } });
    }

    function handleSubmit() {
        let newState = { ...values };

        // Check for empty fields
        Object.keys(values).forEach((key) => {
            (String(values[key].value).trim() === '')
                ? newState[key] = { ...values[key], error: true, message: 'This field cannot be empty' }
                : newState[key] = { ...values[key] }
        });

        // Check for valid start and end dates
        ['startDate', 'endDate'].forEach((key) => {
            if (!moment(values[key].value).isValid()) {
                newState[key] = { ...values[key], error: true, message: 'Please enter a valid date' }
            } else {
                newState[key] = { ...values[key] }
            }
        });

        // Check for valid start and end times
        ['startTime', 'endTime'].forEach((key) => {
            if (!moment(values[key].value).isValid()) {
                newState[key] = { ...values[key], error: true, message: 'Please enter a valid time' }
            } else {
                newState[key] = { ...values[key] }
            }
        });

        // Check if end date comes after start date
        if (!newState.startDate.error && !newState.endDate.error && !newState.startTime.error && !newState.endTime.error) {
            const fullStartDate = getMomentFromDateAndTime(newState.startDate.value, newState.startTime.value)
            const fullEndDate = getMomentFromDateAndTime(newState.endDate.value, newState.endTime.value)

            if (fullEndDate < fullStartDate) {
                newState.rangeError = true;
            } else {
                newState.rangeError = false;
            }
        } else {
            newState.rangeError = false;
        }

        // Return if any fields are invalid
        if (Object.keys(newState).some((key) => newState[key].error || newState.rangeError)) {
            setValues(newState);
            return null;
        }
        const startDate = getMomentFromDateAndTime(values.startDate.value, values.startTime.value).format('YYYY-MM-DD HH:mm:00');
        const endDate = getMomentFromDateAndTime(values.endDate.value, values.endTime.value).format('YYYY-MM-DD HH:mm:59');

        const formData = {
            title: values.title.value,
            description: values.description.value,
            startDate: startDate,
            endDate: endDate,
            timezone: values.timezone.value
        }

        onSubmit(formData)
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Box display='flex' justifyContent='center' width='100%'>
                <Box display='flex' flexDirection='column' flexGrow={1} mt={4} mb={5} maxWidth={theme.breakpoints.values.sm}>


                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Alert severity='info'>
                                This will copy your event, including any tickets and add-ons (bundles excluded). You may change some of the details below. After copying, please review all of your event details before publishing.
                            </Alert>
                        </Grid>
                        <Grid item xs={12}>
                            <Section>

                                <Header>Description</Header>

                                <Content>

                                    <SdTextField id='title' value={title.value} error={title.error} helperText={title.message} onChange={handleFormChange} variant='outlined' label='Event Title' />

                                    <SdTextField id='description' multiline value={description.value} error={description.error} helperText={description.message} onChange={handleFormChange} variant='outlined' label='Event Description' />
                                
                                </Content>

                            </Section>
                        </Grid>

                        <Grid item xs={12}>
                            <Section>
                                    
                                <Header>Time & Date</Header>

                                <SubHeader>Let people know when the event is happening.</SubHeader>

                                <Content>
                                
                                    <Grid container>

                                        <Grid item xs={6}>
                                            <Box mr={1}>
                                                <SdDatePicker id='startDate' value={startDate.value} error={startDate.error} helperText={startDate.message} onChange={handleStartDateChange} format='yyyy-MM-dd' inputVariant='outlined' label='Start Date' />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Box ml={1}>
                                                <SdTimePicker id='startTime' value={startTime.value} error={startTime.error} helperText={startTime.message} onChange={handleStartTimeChange} format='hh:mm a' inputVariant='outlined' label='Start Time' />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Box mr={1}>
                                                <SdDatePicker id='endDate' value={endDate.value} error={endDate.error} helperText={endDate.message} onChange={handleEndDateChange} format='yyyy-MM-dd' inputVariant='outlined' label='End Date' />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Box ml={1}>
                                                <SdTimePicker id='endTime' value={endTime.value} error={endTime.error } helperText={endTime.message} onChange={handleEndTimeChange} format='hh:mm a' inputVariant='outlined' label='End Time' />
                                            </Box>
                                        </Grid>

                                    </Grid>

                                    {rangeError && <ErrorMessage>End date cannot come before start date</ErrorMessage>}

                                    <SdTextField id='timezone' value={timezone.value} error={timezone.error} helperText={timezone.message} onChange={handleFormChange} select InputLabelProps={{ shrink: true }} SelectProps={{ native: true }} variant='outlined' label='Timezone'>
                                        {timezone.value === '' && <option value=''></option>}
                                        {CA_TIMEZONES.map((tz) => <option key={tz.name} value={tz.name}>{tz.fullName}</option>)}
                                    </SdTextField>

                                </Content>

                            </Section>
                        </Grid>
                    </Grid>

                    <Box mt={4} display='flex' justifyContent='flex-end'>
                        <CancelButton variant='outlined' onClick={onCancel} disabled={loading}>Cancel</CancelButton>
                        <SaveButton variant='outlined' onClick={handleSubmit} disabled={loading}>
                            {loading ? <CircularProgress color='secondary' size={18} /> : 'Copy'}
                        </SaveButton>
                    </Box>

                </Box>

            </Box>
        </MuiPickersUtilsProvider>
    )
}

const SubHeader = styled(Typography)({
    marginBottom: 8
})

const SdTextField = styled(TextField)({
    marginBottom: '24px',
    width: '100%'
})

const SdDatePicker = styled(KeyboardDatePicker)({
    marginBottom: '24px',
    width: '100%'
})

const SdTimePicker = styled(KeyboardTimePicker)({
    marginBottom: '24px',
    width: '100%'
})

const ErrorMessage = styled('p')(({ theme }) => ({
    color: theme.palette.error.main
}));

const SaveButton = styled(Button)(({ theme }) => ({
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main
}));

const CancelButton = styled(Button)({
    marginRight: '1rem'
});