import React, { useState } from 'react';
import { useNotification } from 'context/notification';
import fetchExpresso from 'utility/fetchExpresso';
import EventForm from 'components/forms/EventForm';
import moment from 'moment-timezone';
import { Page } from 'components/ui/layout';


// Default the timezone field to the current users timezone
const defaultValues = {
    timezone: moment.tz.guess()
}


export default function EventCreator(props) {
    const { hostId, onCreate, onCancel } = props;

    const [loading, setLoading] = useState(false);

    const { createNotification } = useNotification();
    
    const handleSubmit = (formData) => {
        if (loading) return;
        setLoading(true);

        // Convert tax % to decimal value
        if (formData.taxRate) {
            formData.taxRate = formData.taxRate / 100;
        }
        if (formData.posTaxRate) {
            formData.posTaxRate = formData.posTaxRate / 100;
        }

        // Add https prefix to call-to-action if user set one
        if (formData.ctaButtonURL) {
            formData.ctaButtonURL = 'https://' + formData.ctaButtonURL;
        }

        fetchExpresso(`/apiv2/events/`, {
            method: 'POST',
            body: { ...formData, hostId: hostId }
        })
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    throw new Error();
                }
            })
            .then(data => {
                createNotification('Event Created');
                onCreate(data.eventId);
            })
            .catch(() => {
                createNotification('There was a problem creating your event');
                setLoading(false);
            })
    }
    
    return (
        <Page width='md'>
            <EventForm
                defaultValues={defaultValues}
                onSubmit={handleSubmit}
                onCancel={onCancel}
                loading={loading}
                enableTaxEditor={true}
                enablePosTaxEditor={true}
            />
        </Page>
    );
}