import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';



export default function PageNotFound(props) {
    return (
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' mt={12}>
            <Typography variant='h4'>Page not Found</Typography>
            <Box height={32} />
            <Button variant='outlined' onClick={() => props.history.push('/')}>Go to Home</Button>
        </Box>
    )
}
