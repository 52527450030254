import React from 'react';

import { Box, Grid } from '@material-ui/core';

// Components
import AuditDevices from 'pages/admin/audit/AuditDevices'
import AuditIncidentals from 'pages/admin/audit/AuditIncidentals'



export default function AuditEventEditor(props) {
    const { event, onUpdate, onClose } = props;

    // Responsive padding (p=[2,4]) does not work if it's the root component
    // Something to look into when there's time
    return (
        <Box>
            <Box p={[2,4]}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6} lg={3}>
                        <AuditDevices
                            eventId={event.eventId}
                            onUpdate={onUpdate}
                            onClose={onClose}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={9}>
                        <AuditIncidentals
                            eventId={event.eventId}
                            onUpdate={onUpdate}
                            onClose={onClose}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}