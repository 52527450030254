import React, { useState } from 'react';
import { useNotification } from 'context/notification';
import fetchExpresso from 'utility/fetchExpresso';
import HostPricingForm from 'components/forms/HostPricingForm';


export default function HostPricingCreator(props) {
    const { hostId, hostName, onUpdate, onCancel } = props;

    const [loading, setLoading] = useState(false);

    const { createNotification } = useNotification();

    // Default the CC fees since they don't change often
    const defaultValues = {
        ccPercent: 2.9,
        ccFlatFee: 0.30,
    }
    
    const handleSubmit = (formData) => {
        if (loading) return;
        setLoading(true);

        fetchExpresso(`/apiv2/hosts/${hostId}/host_pricing`, {
            method: 'POST',
            body: {
                setDefault: false,
                fields: formData,
            }
        })
            .then(res => {
                if (res.status === 200) {
                    createNotification('Host Pricing Created');
                    onUpdate();
                } else {
                    createNotification('There was a problem creating the host pricing record');
                    setLoading(false);
                }
            })
            .catch((e) => {
                createNotification('There was a problem creating the host pricing record');
                setLoading(false);
            })
    }
    
    return (
        <HostPricingForm
            allowEdit={true}
            defaultValues={defaultValues}
            onSubmit={handleSubmit}
            onCancel={onCancel}
            loading={loading}
            hostName={hostName}
        />
    );
}