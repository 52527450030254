import React, { createContext, useContext, useState } from 'react';


export const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider(props) {

  const [auth, setAuth] = useState(JSON.parse(localStorage.getItem(process.env.REACT_APP_JWT_KEY)));

  // Set authentication token in localStorage and authToken context
  const login = (token) => {
    localStorage.setItem(process.env.REACT_APP_JWT_KEY, JSON.stringify(token));
    setAuth(token);
  }

  const logout = () => {
    localStorage.removeItem(process.env.REACT_APP_JWT_KEY);
    setAuth();
  }

  const setHost = (id, name) => {
    const token = { ...auth, hostId: id, hostName: name };
    localStorage.setItem(process.env.REACT_APP_JWT_KEY, JSON.stringify(token));
    setAuth(token);
  }


  return (
    <AuthContext.Provider value={{ auth, login, logout, setHost }}>
        {props.children}
    </AuthContext.Provider>
  )
}