import React from 'react';

// Material UI
import { styled, makeStyles, Box, LinearProgress, Typography } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

// Utility
import { formatPrice } from 'utility/numbers';
import Tooltip from './popups/Tooltip';


export default function DiscountList(props) {
    const { discounts, onEdit } = props;

    const classes = useStyles();


    const handleEdit = (params) => {
        const discountCode = params.getValue(params.id, 'c2');

        if (discountCode !== 'sys_discount') {
            // Don't allow user to edit system owned discount codes
            onEdit(params.id)
        }
    };

    const rows = discounts.map(d => ({
        id: d.disc_id,
        c1: { code: d.discount_code, visible: d.visible },
        c2: d.discount_code,
        c3: { percent: d.disc_order_pct, amount: d.disc_order_amt },
        c4: d.disc_order_total,
        c5: { used: d.qty_used, total: d.qty_total_available },
        c6: d.ticket_count,
        c7: { scans: d.scan_count, total: d.ticket_count },
        c8: d.scan_pct,
        c9: d.scan_pct
    }));

    const columns = [
        { field: 'c1', headerName: 'STATUS',        minWidth: 100,  maxWidth: 100,  hideSortIcons: true,
            renderCell: (params) => {
                if (params.value.code === 'sys_discount') {
                    return <AdminIcon />
                } else {
                    return params.value.visible ? <Active /> : <Inactive />
                }
            }
        },
        { field: 'c2', headerName: 'CODE',          minWidth: 180,  maxWidth: 200,  hideSortIcons: true, flex: 1,
            renderCell: (params) => {
                if (params.value === 'sys_discount') {
                    return (
                        <Box display='flex' alignItems={'center'}>
                            sys_discount 
                            <Tooltip message='This is a system-generated discount code and is not available to customers. When you create an order from the admin portal, “sys_discount” is assigned to the complimentary order'>
                                <InfoText>What's this?</InfoText>
                            </Tooltip>
                        </Box>
                    )
                } else {
                    return params.value
                }
            }
        },
        { field: 'c3', headerName: 'VALUE',         minWidth: 120,  maxWidth: 140,  hideSortIcons: true, align: 'right', headerAlign: 'right',
            valueFormatter: (params) => params.value.percent ? Number((params.value.percent*100).toFixed(2)) + '% off' : formatPrice(params.value.amount) + ' off'
        },
        { field: 'c4', headerName: 'DISCOUNTED',    minWidth: 130,  maxWidth: 130,  hideSortIcons: true, align: 'right', headerAlign: 'right',
            valueFormatter: params => formatPrice(params.value)
        },
        { field: 'c5', headerName: 'USES',          minWidth: 100,  maxWidth: 120,  hideSortIcons: true, align: 'right', headerAlign: 'right',
            valueFormatter: (params) => `${params.value.used} / ${params.value.total ?? '-'}`
        },
        { field: 'c6', headerName: 'TIX',           minWidth: 60,   maxWidth: 80,   hideSortIcons: true, align: 'right', headerAlign: 'right' },
        { field: 'c7', headerName: 'CHECKINS',      minWidth: 110,  maxWidth: 130,  hideSortIcons: true, align: 'right', headerAlign: 'right',
            valueFormatter: (params) => `${params.value.scans} / ${params.value.total ?? '-'}`
        },
        { field: 'c8', headerName: ' ',             minWidth: 60,   maxWidth: 80,   hideSortIcons: true, align: 'right', headerAlign: 'right',
            valueFormatter: (params) => `${params.value}%`},
        { field: 'c9', headerName: ' ',             minWidth: 120,  maxWidth: 120,  hideSortIcons: true, flex: 1,
            renderCell: (params) => <ProgressBar variant='determinate' color='secondary' value={params.value} />
        },
    ]

    return (
        <Box height={'100%'} className={classes.root}>
            <DG
            sortingMode='server'
                onRowClick={handleEdit}
                disableSelectionOnClick
                rows={rows}
                columns={columns}
                disableColumnMenu
                getRowClassName={(params) => `discount-row--${params.getValue(params.id, 'c2') === 'sys_discount' ? 'system' : 'user'}`}
                sortingOrder={['desc', 'asc']}
            />
        </Box>
    )
}


const DG = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-cell:focus": {
        outline: 'none'
    },
    "& .MuiDataGrid-columnHeader:focus": {
        outline: 'none',
    }
}));

const Active = styled('div')(({ theme }) => ({
    height: '1rem',
    width: '1rem',
    backgroundColor: theme.palette.success.main,
    borderRadius: '.5rem',
    marginLeft: theme.spacing(1)
}));

const Inactive = styled('div')(({ theme }) => ({
    height: '1rem',
    width: '1rem',
    backgroundColor: theme.palette.grey.A200,
    borderRadius: '.5rem',
    marginLeft: theme.spacing(1)
}));

const AdminIcon = styled(SupervisorAccountIcon)(({ theme }) => ({
    height: '1.2rem',
    width: '1.2rem',
    color: theme.palette.grey.A200,
    marginLeft: theme.spacing(1)
}));

const ProgressBar = styled(LinearProgress)(({ theme }) => ({
    height: 12,
    backgroundColor: theme.palette.grey[300],
    width: '100%'
}));

const InfoText = styled(Typography)(({ theme }) => ({
    color: theme.palette.info.main,
    textDecoration: 'underline',
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: 12,
    marginTop: '1px'
}));
InfoText.defaultProps = { variant: 'body2' }


const useStyles = makeStyles(
    (theme) => {  
      return {
        root: {
          '& .discount-row--user': {
            '&:hover': {
                backgroundColor: theme.palette.action.hover,
                cursor: 'pointer',
            },
          },
          '& .discount-row--system': {
            backgroundColor: theme.palette.grey[100],
            '&:hover': {
                backgroundColor: theme.palette.grey[100]
            }
          },
        },
      };
    }
  );