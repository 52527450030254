import { useState, useEffect } from 'react';
import { styled, useTheme, Box, Button, Typography } from '@material-ui/core';



export function Page(props) {
    const theme = useTheme();

    let maxWidth = theme.breakpoints.values.lg;

    if (props.width) {
        maxWidth = theme.breakpoints.values[props.width];
    }
    
    return (
        <Box ml='auto' mr='auto' maxWidth={maxWidth}>
            <Box p={[2, 4]} pb={[14]}>
                {props.children}
            </Box>
        </Box>
    )
}

export function AdminPage(props) {
    return (
        <Box px={2} pt={4} pb={6} height='100%'>
            {props.children}
        </Box>
    )
}

export function ScrollRestoreContainer(props) {
    const [ready, setReady] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setReady(true);
        }, 50);
    }, []);

    if (ready === false) {
        return null;
    }
    
    return props.children;
}

export const ControlBar = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
}));

export const ControlBarHeader = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    marginRight: 'auto'
}));
ControlBarHeader.defaultProps = { variant: 'h4' };

export const ControlBarButton = styled(Button)(({ theme }) => ({
    marginLeft: theme.spacing(2)
}));
ControlBarButton.defaultProps = { color: 'secondary', variant: 'contained' }