import React, { useState } from 'react';

// Material UI
import { styled, IconButton, Popover, AppBar, Toolbar, Button, Typography, Box, Badge } from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

// Utility
import { useHistory, useLocation } from 'react-router-dom';

// Components
import { useInbox, InboxDesktop } from 'components/Inbox';

/**
 * Desktop Only
 * 
 * Shows a top app bar for navigating to other root level screens
 * 
 * See RootTabs.js for the mobile version of this menu
 */

export default function RootAppBar(props) {
    const { admin, hostId } = props;

    // Inbox
    const inbox = useInbox(hostId, admin);
    const [inboxAnchorElement, setInboxAnchorElement] = useState(null);

    // Hooks
    const history = useHistory();
    const location = useLocation();

    const currentPage = location.pathname.replace(/\//g, '') || ''; // Regex: Remove all instances of '/' character in the pathname


    return (
        <Box displayPrint='none'>

            <TopAppBar position='fixed'>

                <Toolbar style={{width: '100%'}}>

                    <MenuButton key='' onClick={() => history.push(`/`)} disabled={currentPage === ''}>
                        {currentPage === ''
                            ? <MenuTextActive variant='inherit'>Home</MenuTextActive>
                            : <Typography variant='inherit'>Home</Typography>
                        }
                    </MenuButton>

                    <MenuButton key='events' onClick={() => history.push(`/events`)} disabled={currentPage === 'events'}>
                        {currentPage === 'events'
                            ? <MenuTextActive variant='inherit'>Events</MenuTextActive>
                            : <Typography variant='inherit'>Events</Typography>
                        }
                    </MenuButton>

                    <MenuButton key='resources' onClick={() => history.push(`/resources`)} disabled={currentPage === 'resources'}>
                        {currentPage === 'resources'
                            ? <MenuTextActive variant='inherit'>Resources</MenuTextActive>
                            : <Typography variant='inherit'>Resources</Typography>
                        }
                    </MenuButton>

                    <MenuButton key='settings' onClick={() => history.push(`/settings`)} disabled={currentPage === 'settings'}>
                        {currentPage === 'settings'
                            ? <MenuTextActive variant='inherit'>Settings</MenuTextActive>
                            : <Typography variant='inherit'>Settings</Typography>
                        }
                    </MenuButton>


                    {/* Inbox menu button + popover to display inbox items */}

                    <IconButton onClick={(e) => setInboxAnchorElement(e.currentTarget)}>
                        <Badge badgeContent={inbox.unreadCount} color='error'>
                            <MailIcon />
                        </Badge>
                    </IconButton>

                    <Popover
                        open={Boolean(inboxAnchorElement)}
                        anchorEl={inboxAnchorElement}
                        onClose={() => setInboxAnchorElement(null)}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >
                        <InboxDesktop {...inbox} />
                    </Popover>


                    {admin && (
                        <AdminButton key='admin' onClick={() => history.push('/admin')} disabled={currentPage === 'admin'}>
                            {currentPage === 'admin'
                                ? <MenuTextActive variant='inherit'>Admin</MenuTextActive>
                                : <Typography variant='inherit'>Admin</Typography>
                            }
                        </AdminButton>
                    )}

                </Toolbar>

            </TopAppBar>

            <Toolbar />

        </Box>
    )
}


const TopAppBar = styled(AppBar)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.background.dark
}));

const MenuButton = styled(Button)(({ theme }) => ({
    color: theme.palette.common.white
}));

const AdminButton = styled(Button)(({ theme }) => ({
    color: theme.palette.common.white,
    marginLeft: 'auto'
}));

const MenuTextActive = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.white,
    fontWeight: 900,
    textDecoration: 'underline'
}));

const MailIcon = styled(MailOutlineIcon)(({ theme }) => ({
    color: 'white'
}));