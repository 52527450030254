import React from 'react';
import { styled, Box, CircularProgress, Typography, TextField, IconButton, Hidden, Divider } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { TableContainer, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import StoreIcon from '@material-ui/icons/Store';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import LocalMallIcon from '@material-ui/icons/LocalMall';


export default function ProductPicker(props) {
    const { products, bundles, error, status, onProductUpdate, onBundleUpdate } = props;

    if (status === 'loading') {
        return (
            <Box display='flex' height='100%' alignItems='center' justifyContent='center'>
                <CircularProgress />
            </Box>
        )
    }

    if (status === 'error' || status !== 'success') {
        return (
            <Box display='flex' height='100%' alignItems='center' justifyContent='center'>
                <Typography variant='subtitle1'>There was a problem loading your tickets</Typography>
            </Box>
        )
    }


    const handleProductUpdate = (id, value) => {
        const newValue = Number(value);

        if (Number.isInteger(newValue) && newValue >= 0 && newValue <= 99) {
            onProductUpdate(id, Number(value))
        }
    };
    
    const handleBundleUpdate = (id, value) => {
        const newValue = Number(value);

        if (Number.isInteger(newValue) && newValue >= 0 && newValue <= 99) {
            onBundleUpdate(id, Number(value))
        }
    };


    return (
        <Card>
            <CardHeader title='Build Order' />
            <CardContent>
                <Hidden smDown>
                    <DesktopProductSelect
                        products={products}
                        bundles={bundles}
                        onProductUpdate={handleProductUpdate}
                        onBundleUpdate={handleBundleUpdate}
                        error={error}
                    />
                </Hidden>
                <Hidden mdUp>
                    <MobileProductSelect
                        products={products}
                        bundles={bundles}
                        onProductUpdate={handleProductUpdate}
                        onBundleUpdate={handleBundleUpdate}
                        error={error}
                    />
                </Hidden>
            </CardContent>
        </Card>
    )
}


function DesktopProductSelect(props) {
    const { products, bundles, onProductUpdate, onBundleUpdate, error } = props;

    return (
        <TableContainer component={Box}>
            <Table size='small'>
                <TableBody>

                    {bundles.map(item => (
                        <TableRow key={item.id}>
                            <TableCell>
                            <Box flex={2} display='flex' alignItems='center'>
                                <LocalMallIcon color='secondary' />
                                <Box ml={1}>{item.name}</Box>
                            </Box>
                            </TableCell>
                            <TableCell width={130}>
                                <Availability icon status={item.status} />
                            </TableCell>
                            <TableCell width={120} align='right'>
                                <Box display='flex' alignItems='center'>
                                    <IconButton color='secondary' onClick={() => onBundleUpdate(item.id, item.quantity - 1)}>
                                        <RemoveIcon />
                                    </IconButton>
                                    <TicketField
                                        error={error}
                                        value={item.quantity}
                                        onFocus={e => e.target.select()}
                                        onChange={(e) => onBundleUpdate(item.id, e.target.value)}
                                    />
                                    <IconButton onClick={() => onBundleUpdate(item.id, item.quantity + 1)}>
                                        <AddIcon />
                                    </IconButton>
                                </Box>
                            </TableCell>
                        </TableRow>
                    ))}

                    {products.map(item => (
                        <TableRow key={item.id}>
                            <TableCell>
                                <ProductType
                                    type={item.type}
                                    name={item.name}
                                />
                            </TableCell>
                            <TableCell width={130}>
                                <Availability icon status={item.status} />
                            </TableCell>
                            <TableCell width={120} align='right'>
                                <Box display='flex' alignItems='center'>
                                    <IconButton color='secondary' onClick={() => onProductUpdate(item.id, item.quantity - 1)}>
                                        <RemoveIcon />
                                    </IconButton>
                                    <TicketField
                                        error={error}
                                        value={item.quantity}
                                        onFocus={e => e.target.select()}
                                        onChange={(e) => onProductUpdate(item.id, e.target.value)}
                                    />
                                    <IconButton onClick={() => onProductUpdate(item.id, item.quantity + 1)}>
                                        <AddIcon />
                                    </IconButton>
                                </Box>
                            </TableCell>
                        </TableRow>
                    ))}

                </TableBody>
            </Table>
        </TableContainer>
    )
}

function MobileProductSelect(props) {
    const { products, bundles, onProductUpdate, onBundleUpdate, error } = props;

    return (
        <>
            {bundles.map(item => (
                <Box key={item.id}>
                    <TicketName>{item.name}</TicketName>
                    <Availability status={item.status} />

                    <Box display='flex' alignItems='center' mt={2}>
                        <IconButton color='secondary' onClick={() => onBundleUpdate(item.id, item.quantity - 1)}>
                            <RemoveIcon fontSize='large' />
                        </IconButton>
                        <TicketField
                            error={error}
                            value={item.quantity}
                            onFocus={e => e.target.select()}
                            onChange={(e) => onBundleUpdate(item.id, e.target.value)}
                        />
                        <IconButton color='secondary' onClick={() => onBundleUpdate(item.id, item.quantity + 1)}>
                            <AddIcon fontSize='large' />
                        </IconButton>
                    </Box>

                    <Box my={2}>
                        <Divider />
                    </Box>
                </Box>
            ))}
            {products.map(item => (
                <Box key={item.id}>
                    <TicketName>{item.name}</TicketName>
                    <Availability status={item.status} />

                    <Box display='flex' alignItems='center' mt={2}>
                        <IconButton color='secondary' onClick={() => onProductUpdate(item.id, item.quantity - 1)}>
                            <RemoveIcon fontSize='large' />
                        </IconButton>
                        <TicketField
                            error={error}
                            value={item.quantity}
                            onFocus={e => e.target.select()}
                            onChange={(e) => onProductUpdate(item.id, e.target.value)}
                        />
                        <IconButton color='secondary' onClick={() => onProductUpdate(item.id, item.quantity + 1)}>
                            <AddIcon fontSize='large' />
                        </IconButton>
                    </Box>

                    <Box my={2}>
                        <Divider />
                    </Box>
                </Box>
            ))}
        </>
    )
}


function ProductType(props) {
    switch (props.type) {
        case 1:
            return (
                <Box flex={2} display='flex' alignItems='center'>
                    <ConfirmationNumberIcon color='secondary' />
                    <Box ml={1}>{props.name}</Box>
                </Box>
            )
        case 2:
            return (
                <Box flex={1} display='flex' alignItems='center'>
                    <AddShoppingCartIcon color='secondary' />
                    <Box ml={1}>{props.name}</Box>
                </Box>
            )
        default:
            return null;
    }
}

function Availability(props) {

    if (props.icon === true) {
        switch (props.status) {
            case 'on_sale':
                return (
                    <Box flex={1} display='flex' alignItems='center'>
                        <StoreActiveIcon />
                        <Typography variant='caption'>On Sale</Typography>
                    </Box>
                )
            case 'sale_not_started':
                return (
                    <Box flex={1} display='flex' alignItems='center'>
                        <StoreInactiveIcon />
                        <Typography variant='caption'>Sale not Started</Typography>
                    </Box>
                )
            case 'sale_concluded':
                return (
                    <Box flex={1} display='flex' alignItems='center'>
                        <StoreInactiveIcon />
                        <Typography variant='caption'>Sale Concluded</Typography>
                    </Box>
                )
            case 'sold_out':
                return (
                    <Box flex={1} display='flex' alignItems='center'>
                        <StoreInactiveIcon />
                        <Typography variant='caption'>Sold Out</Typography>
                    </Box>
                )
            case 'hidden':
                return (
                    <Box flex={1} display='flex' alignItems='center'>
                        <TicketHiddenIcon />
                        <Typography variant='caption'>Hidden</Typography>
                    </Box>
                )
            default:
                return (
                    <Typography>-</Typography>
                )
        }
    } else {
        switch (props.status) {
            case 'on_sale':
                return <Typography variant='caption'>On Sale</Typography>
            case 'sale_not_started':
                return <Typography variant='caption'>Sale not Started</Typography>
            case 'sale_concluded':
                return <Typography variant='caption'>Sale Concluded</Typography>
            case 'sold_out':
                return <Typography variant='caption'>Sold Out</Typography>
            case 'hidden':
                return <Typography variant='caption'>Hidden</Typography>
            default:
                return <Typography>-</Typography>
        }
    }

}


const TicketField = styled(TextField)(({ theme }) => ({
    width: 56
}));
TicketField.defaultProps = {
    variant: 'outlined',
    size: 'small',
    inputProps: {
        style: { textAlign: 'center' }
    }
}

const TicketName = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold'
}));

const AddIcon = styled(AddCircleOutlineIcon)(({ theme }) => ({
    color: theme.palette.success.main
}));

const RemoveIcon = styled(RemoveCircleOutlineIcon)(({ theme }) => ({
    color: theme.palette.error.main
}));



const StoreActiveIcon = styled(StoreIcon)(({ theme }) => ({
    color: theme.palette.success.main,
    marginRight: theme.spacing(1)
}));

const StoreInactiveIcon = styled(StoreIcon)(({ theme }) => ({
    color: theme.palette.grey.A200,
    marginRight: theme.spacing(1)
}));

const TicketHiddenIcon = styled(VisibilityOffIcon)(({ theme }) => ({
    color: theme.palette.grey.A200,
    marginRight: theme.spacing(1)
}));