import React from 'react';
import { styled, Grid, TextField as _TextField } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';



export default function CustomerForm(props) {
    const { onChange, validFields } = props;
    const { firstName, lastName, email, phone, company, address, city, province, postalCode, country } = props.customer;


    return (
        <Card>
            <CardHeader title='Customer Contact Info' />
            <CardContent>
                <Grid container spacing={4}>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            label='First Name *'
                            value={firstName}
                            error={!validFields.firstName}
                            onChange={e => onChange({ firstName: e.target.value })}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            label='Last Name *'
                            value={lastName}
                            error={!validFields.lastName}
                            onChange={e => onChange({ lastName: e.target.value })}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label='Email *'
                            type='email'
                            value={email}
                            error={!validFields.email}
                            onChange={e => onChange({ email: e.target.value })}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            label='Phone Number'
                            type='tel'
                            value={phone}
                            error={!validFields.phone}
                            onChange={e => onChange({ phone: e.target.value.replace(/[^0-9]/g,'').slice(0,12) })}
                        />
                    </Grid>
                    
                    <Grid item xs={12} md={6}>
                        <TextField
                            label='Company Name'
                            value={company}
                            error={!validFields.company}
                            onChange={e => onChange({ company: e.target.value })}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label='Address'
                            value={address}
                            error={!validFields.address}
                            onChange={e => onChange({ address: e.target.value })}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label='City'
                            value={city}
                            error={!validFields.city}
                            onChange={e => onChange({ city: e.target.value })}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <ProvinceSelect
                            region={country}
                            province={province}
                            error={!validFields.province}
                            onChange={e => onChange({ province: e.target.value })}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label={country === 'US' ? 'ZIP' : 'Postal Code'}
                            value={postalCode}
                            error={!validFields.postalCode}
                            onChange={e => onChange({ postalCode: e.target.value.replace(/[^0-9a-zA-Z]/g,'') })}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextFieldSelect
                            label='Country'
                            value={country}
                            error={!validFields.country}
                            onChange={e => onChange({ country: e.target.value })}>
                            <option value="CA">Canada</option>
                            <option value="US">United States</option>
                        </TextFieldSelect>
                    </Grid>

                </Grid>
            </CardContent>
        </Card>
    )
}



function ProvinceSelect(props) {
    const { region, province, onChange, error } = props;

    switch (region) {

        case "CA":
            return (
                <TextFieldSelect
                    label='Province'
                    value={province}
                    error={error}
                    onChange={(e) => onChange(e)}>
                        <option value=""></option>
                        <option value="AB">Alberta</option>
                        <option value="BC">British Columbia</option>
                        <option value="MB">Manitoba</option>
                        <option value="NB">New Brunswick</option>
                        <option value="NL">Newfoundland and Labrador</option>
                        <option value="NS">Nova Scotia</option>
                        <option value="NU">Nunavut</option>
                        <option value="NT">Northwest Territories</option>
                        <option value="ON">Ontario</option>
                        <option value="PE">Prince Edward Island</option>
                        <option value="QC">Quebec</option>
                        <option value="SK">Saskatchewan</option>
                        <option value="YT">Yukon</option>
                </TextFieldSelect>
            )            
        
        case "US":
            return (
                <TextFieldSelect
                    id='province'
                    label='State'
                    value={province}
                    error={error}
                    onChange={(e) => onChange(e)}>
                    <option value=""></option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                    <option value="PR">Puerto Rico</option>
                    <option value="VI">Virgin Islands</option>
                    <option value="MP">Northern Mariana Islands</option>
                    <option value="GU">Guam</option>
                    <option value="AS">American Samoa</option>
                    <option value="PW">Palau</option>
                </TextFieldSelect>
            )
        default:
            break;
    }
}


const TextField = styled(_TextField)(({ theme }) => ({}));
TextField.defaultProps = {
    variant: 'outlined',
    fullWidth: true
};

const TextFieldSelect = styled(_TextField)(({ theme }) => ({
    width: '100%'
}));
TextFieldSelect.defaultProps = {
    select: true,
    variant: 'outlined',
    SelectProps: { native: true }
}