import React from 'react';
import { Card } from '@material-ui/core';
import { styled } from '@material-ui/core';
import Chart from 'react-apexcharts';


export default function AuditChart(props) {
    const { eventWeeks } = props;

    const today = eventWeeks.find(d => d.weeksOut === 0).start;


    const series = [
        { name: 'FD+ Scanners', data: eventWeeks.map(d => d.totalPhones) },
        { name: 'Stripe Readers', data: eventWeeks.map(d => d.totalReaders) },
    ];

    const options = {
        annotations: {
            xaxis: [{
                x: today,
                strokeDashArray: 0,
                borderColor: "#775DD0",
                label: {
                  borderColor: "#775DD0",
                  style: {
                    color: "#fff",
                    background: "#775DD0"
                  },
                  text: "This Week"
                }
              }]
        },
        chart: {
            stacked: true,
            zoom: {
                enabled: false
            }
        },
        title: {
            text: 'Hardware',
            offsetX: 0,
            style: {
                fontSize: '24px',
            }
        },
        subtitle: {
            text: 'Subtitle',
            offsetX: 0,
            style: {
                fontSize: '14px',
            }
        },
        legend: {
            onItemClick: {
                toggleDataSeries: true
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: false,
            curve: 'straight'
        },
        fill: {
            type: 'solid'
        },
        xaxis: {
            categories: eventWeeks.map(d => d.start),
            labels: {
                rotateAlways: true,
                maxHeight: 60
            }
        }
    }

    return (
        <Root>
            <Chart
                height='100%'
                width='100%'
                type='area'
                options={options}
                series={series}
            />
        </Root>
    )
}


const Root = styled(Card)(({ theme }) => ({
    padding: theme.spacing(1),
    height: 280
}));