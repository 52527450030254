import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography, Button } from '@material-ui/core';
import { useNotification } from 'context/notification';
import fetchExpresso from 'utility/fetchExpresso';
import EventForm from 'components/forms/EventForm';
import moment from 'moment-timezone';
import { Page } from 'components/ui/layout';
import { useAuth } from 'context/auth';
import { useEventDispatch } from 'context/event';


export default function EventDetails(props) {
    const eventId = props.match.params.id;
    const { auth } = useAuth();
    const hostId = auth.hostId;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [defaultValues, setDefaultValues] = useState(null);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [readOnly, setReadOnly] = useState(false);

    const { createNotification } = useNotification();
    const dispatch = useEventDispatch();


    const initializeDefaultValues = (event) => {
        // Set image to null if a placeholder image is returned from the server
        let imageUrl = event.image_url;

        if (String(imageUrl).includes('placeholder')) {
            imageUrl = null;
        }

        // Convert tax rate from decimal to %
        let taxRate = event.tax_rate;
        let posTaxRate = event.pos_tax_rate;

        // Handle rounding, precision errors, and trailing 0s
        if (event.tax_rate) {
            taxRate = parseFloat((taxRate * 100).toFixed(4));
        }
        if (event.pos_tax_rate) {
            posTaxRate = parseFloat((posTaxRate * 100).toFixed(4));
        }

        setDefaultValues({
            type: event.event_type_id,
            title: event.event_title,
            description: event.event_desc,
            image: imageUrl,
            venueName: event.address1,
            venueAddress: event.address2,
            city: event.city,
            province: event.prov_state,
            postalCode: event.postal_code,
            country: event.country,
            startDate: moment(event.start_date),
            endDate: moment(event.end_date),
            timezone: event.time_zone,
            taxName: event.tax_name,
            taxRate: taxRate ? taxRate : null,
            posTaxRate: posTaxRate ? posTaxRate : null,
            orderCount: event.orderCount,
            posOrderCount: event.posOrderCount,
            createdAtMsg: `Created ${moment(event.created_at).fromNow()} by ${event.created_by}`,
            updatedAtMsg: `Updated ${moment(event.updated_at).fromNow()}` + (event.updated_by ? ` by ${event.updated_by}` : '')
        });
    };


    useEffect(() => {
        (async () => {
            try {
                setDefaultValues(null);
    
                const results = await Promise.all([
                    fetchExpresso(`/apiv2/events/${eventId}`),
                    fetchExpresso(`/apiv2/events/${eventId}/payouts`),
                ]);

                if (results[0].status !== 200 || results[1].status !== 200) {
                    throw new Error();
                }

                const event = await results[0].json();
                const payouts = await results[1].json();

                // Make event details read only if the event has been paid out in full
                const readOnly = payouts.some(p => p.final_payout_flag === 1);
                setReadOnly(readOnly);

                // Set default form values so user can revert changes if needed
                initializeDefaultValues(event);

                setLoading(false);

            } catch(error) {
                setError(true);
                setLoading(false);
            }
        })()
    }, [eventId]);

    
    const handleEdit = (formData) => {
        if (loading) return;
        setLoading(true);

        // Convert tax % to decimal value
        if (formData.taxRate) {
            formData.taxRate = formData.taxRate / 100;
        }
        if (formData.posTaxRate) {
            formData.posTaxRate = formData.posTaxRate / 100;
        }

        // Only include start and end date fields if one or both were changed
        // Including the start/end dates will trigger the products to update as well
        if (defaultValues.startDate.isSame(formData.startDate) && defaultValues.endDate.isSame(formData.endDate)) {
            formData.startDate = undefined
            formData.endDate = undefined
        }

        fetchExpresso(`/apiv2/events/${eventId}`, {
            method: 'PUT',
            body: { ...formData, hostId: hostId }
        })
            .then(res => {
                if (res.status === 200) {
                    createNotification('Event Updated');
                    fetchExpresso(`/apiv2/events/${eventId}`)
                        .then(res => res.json())
                        .then(event => {
                            dispatch({ type: 'set_event', event: event }); // Update global event object with latest changes
                            initializeDefaultValues(event); // Update default values so user can revert to the latest event values
                        })
                        .catch((e) => null)
                } else {
                    createNotification('There was a problem updating your event');
                }
            })
            .catch(() => {
                createNotification('There was a problem updating your event');
            })
            .finally(() => {
                setLoading(false);
                setUnsavedChanges(false);
            })
    }

    if (error) {
        return (
            <Page>
                <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' mt={12}>
                    <Typography variant='h5'>There was a problem finding your event</Typography>
                    <Box height={32} />
                    <Button variant='outlined' onClick={() => props.history.push('/events')}>Back to Events</Button>
                </Box>
            </Page>
        )
    }

    // Waiting for default values to load
    if (defaultValues === null) {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' width={1} height={1}>
                <CircularProgress />
            </Box>
        )
    }


    const enableTaxEditor = !defaultValues.orderCount;
    const enablePosTaxEditor = !defaultValues.posOrderCount;


    return (
        <Page width='md'>

            <EventForm
                editMode
                readOnly={readOnly}
                unsavedChanges={unsavedChanges}
                onFormChange={() => setUnsavedChanges(true)}
                onFormReset={() => setUnsavedChanges(false)}
                defaultValues={defaultValues}
                enableTaxEditor={enableTaxEditor}
                enablePosTaxEditor={enablePosTaxEditor}
                onSubmit={handleEdit}
                loading={loading}
            />

        </Page>
    )
}