import moment from 'moment-timezone';

// All canadian timezones sorted by offset
// Used to generate timezone export values below
const TIMEZONES = moment.tz.zonesForCountry('CA', true).sort((a,b) => b.offset - a.offset);


export const CA_TIMEZONES = TIMEZONES.map(tz => {
    return {
        name: tz.name,
        offset: moment.tz(moment(), tz.name).format('Z'),
        fullName: '(GMT' + moment.tz(moment(), tz.name).format('Z') + ') ' + tz.name.replace('_', ' '),
    }
});