import React from 'react';

// Material UI
import { styled, Box, AppBar, Tabs, Tab } from '@material-ui/core';
import { useRouteMatch, useHistory } from 'react-router-dom';


/**
 * Mobile Only
 * See AdminDrawer.js for the desktop version of this menu
 */

export default function AdminTabs() {

    const history = useHistory();

    const dashboard  = useRouteMatch('/admin/');
    const events     = useRouteMatch('/admin/events');
    const ops        = useRouteMatch('/admin/operations');
    const orders     = useRouteMatch('/admin/orders');
    const hosts      = useRouteMatch('/admin/hosts');
    const users      = useRouteMatch('/admin/users');
    const reports    = useRouteMatch('/admin/reports');
    const calculator = useRouteMatch('/admin/calculator');

    // Default to dashboard. '/admin/' wont match any of the urls above, but should display the dashboard
    let currentPage = '/';
    let isExact = false;
    
    if (dashboard) {
        isExact = dashboard.isExact;
        currentPage = '/';
    }

    if (events) {
        isExact = events.isExact;
        currentPage = '/events';
    }
    
    if (ops) {
        isExact = ops.isExact;
        currentPage = '/operations';
    }

    if (orders) {
        isExact = orders.isExact;
        currentPage = '/orders';
    }

    if (hosts) {
        isExact = hosts.isExact;
        currentPage = '/hosts';
    }

    if (users) {
        isExact = users.isExact;
        currentPage = '/users';
    }

    if (reports) {
        isExact = reports.isExact;
        currentPage = '/reports';
    }

    if (calculator) {
        isExact = calculator.isExact;
        currentPage = '/calculator';
    }



    const handleNavigation = (e, newValue) => {
        if (newValue !== currentPage || isExact === false) {
            history.push(`/admin${newValue}`);
        }
    };
    

    return (
        <Box displayPrint='none'>
            <AppBar position="static">
                <TabMenu value={currentPage} variant='scrollable' scrollButtons='auto' onChange={handleNavigation} aria-label="simple TabMenu example">
                    <Tab value="/"           label="Dashboard"    {...a11yProps('dashboard')}  />
                    <Tab value="/operations" label="Ops"          {...a11yProps('ops')}        />
                    <Tab value="/hosts"      label="Hosts"        {...a11yProps('hosts')}      />
                    <Tab value="/events"     label="Events"       {...a11yProps('events')}     />
                    <Tab value="/users"      label="Users"        {...a11yProps('users')}      />
                    <Tab value="/orders"     label="Order Lookup" {...a11yProps('orders')}     />
                    <Tab value="/reports"    label="Reports"      {...a11yProps('reports')}    />
                    <Tab value="/calculator" label="Calculator"   {...a11yProps('calculator')} />
                </TabMenu>
            </AppBar>
        </Box>
    )
}


function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}


const TabMenu = styled(Tabs)(({ theme }) => ({
    flexGrow: 1,
    width: '100%'
}));