import React, { useState, useEffect } from 'react';

// Material-UI
import { Paper } from '@material-ui/core';
import { styled, CircularProgress, Typography, Box } from '@material-ui/core';

// Utility
import fetchExpresso from 'utility/fetchExpresso';
import moment from 'moment-timezone';



export default function PerMinuteTaps() {

    const [state, setState] = useState({
        data: 0, // orders per minute
        loading: true,
        error: false,
        refresh: 0,
        lastLoad: moment()
    });


    useEffect(() => {
        fetchExpresso(`/admin/reports/taps_per_minute`)
            .then(async res => {
                if (res.status !== 200) {
                    setState(s => ({ ...s, loading: false, error: true }));
                    return;
                }

                const { avg_pos_orders_per_min } = await res.json();

                setState(s => ({ ...s, data: avg_pos_orders_per_min, loading: false, error: false }));
            })
            .catch(e => {
                setState(s => ({ ...s, loading: false, error: true }));
            })
    }, [state.refresh]);


    // *** Periodically Refresh Data *** //

    useEffect(() => {
        const interval = setInterval(() => {
            setState(s => ({ ...s, refresh: s.refresh + 1, lastLoad: moment() }));
        }, 1000 * 60 * 5);

        return () => {
            clearInterval(interval);
        }
    }, []);


    if (state.loading) {
        return (
            <Root>
                <CircularProgress disableShrink />
            </Root>
        );
    }

    if (state.error) {
        return (
            <Root>
                <Typography>Unable to load</Typography>
            </Root>
        );
    }

    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Root>
                <Section>
                    <Typography variant='h6'>Taps</Typography>
                </Section>
                <Section>
                    <Typography variant='h4'>{state.data.toFixed(1)}/m</Typography>
                </Section>
                <Section>
                    <Typography variant='caption'>updated {state.lastLoad.format('h:mmA')}</Typography>
                </Section>
            </Root>
        </Box>
    )
}

const Root = styled(Paper)(({ theme }) => ({
    height: '152px',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
}));

const Section = styled(Box)(({ theme }) => ({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
}));