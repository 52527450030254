import { styled, CircularProgress, Box, Typography } from '@material-ui/core';


export function LoadingPage() {
    return (
        <Box display='flex' justifyContent='center' alignItems='center' height='80%' width='100%'>
            <CircularProgress />
        </Box>
    );
}

export function ErrorPage(props) {
    return (
        <Box display='flex' justifyContent='center' alignItems='center' height='80%' width='100%'>
            <Typography>{props.message || 'Unable to load page'}</Typography>
        </Box>
    )
}

export function LoadingState() {
    return (
        <Container>
            <CircularProgress />
        </Container>
    )
}

export function ErrorState({ message }) {
    return (
        <Container>
            <p>{message}</p>
        </Container>
    )
}

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: 1,
    paddingTop: theme.spacing(5),
    justifyContent: 'center',
    alignItems: 'center'
}));