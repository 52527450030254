import React, { useState } from 'react';
import { useNotification } from 'context/notification';
import fetchExpresso from 'utility/fetchExpresso';
import DiscountForm from 'components/forms/DiscountForm';


export default function DiscountCreator(props) {
    const { eventId, usedCodes, onSave, onCancel } = props;

    const [loading, setLoading] = useState(false);

    const { createNotification } = useNotification();
    
    const handleSubmit = (formData) => {
        if (loading) return;
        setLoading(true);

        // Convert discount percent to decimal value
        const discountPercent = formData.discountPercent / 100 || null;

        fetchExpresso('/apiv2/discounts/', {
            method: 'POST',
            body: {
                ...formData,
                eventId,
                discountPercent
            }
        })
            .then(res => {
                if (res.status === 200) {
                    createNotification('Created new discount code');
                    onSave();
                }
                else if (res.status === 409) {
                    createNotification('This code is already in use');
                    setLoading(false);
                }
                else {
                    createNotification('Unable to create discount');
                    setLoading(false);
                }
            })
            .catch(err => {
                createNotification('Unable to create discount code');
                setLoading(false);
            })
    }
    
    return (
        <DiscountForm
            usedCodes={usedCodes}
            onSubmit={handleSubmit}
            onCancel={onCancel}
            loading={loading}
        />
    );
}