import { styled, Box, ButtonBase, Button, Typography } from "@material-ui/core";


export function ActionButton(props) {
    return <StyledActionButton {...props} />
}

const StyledActionButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'rgba(25, 118, 210, 0.1)',
    color: theme.palette.secondary.main
}));

StyledActionButton.defaultProps = {
    variant: 'contained',
    disableElevation: true
}


export function EventShortcut(props) {
    const { id, name, image, status, onClick } = props;

    return (
        <EventButton focusRipple onClick={() => onClick(id)}>
            <Box height='46px'>
                <Image src={image} />
            </Box>
            <Box minWidth={0} mx={2}>
                <EventName>{name}</EventName>
                {status === 'draft'    && <OrangeText>Draft</OrangeText>}
                {status === 'upcoming' && <BlueText>Upcoming</BlueText>}
                {status === 'live'     && <GreenText>Live</GreenText>}
            </Box>
        </EventButton>
    )
}

const EventButton = styled(ButtonBase)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    height: '48px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px'
}));

const EventName = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
}));

const Image = styled('img')(({ theme }) => ({
    objectFit: 'contain',
    height: '100%',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    textAlign: 'left'
}));

const BlueText = styled(Typography)(({ theme }) => ({
    color: theme.palette.info.main,
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: '12px'
}));

const GreenText = styled(Typography)(({ theme }) => ({
    color: theme.palette.success.main,
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: '12px'
}));

const OrangeText = styled(Typography)(({ theme }) => ({
    color: theme.palette.warning.main,
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: '12px'
}));