import React from 'react';

// Material UI
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { styled, Button } from '@material-ui/core';


export default function ConfirmAction(props) {
    // Props
    const { open, onConfirm, onCancel, title, description, destructive, confirmText } = props;
    
    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{description}</DialogContent>
            <DialogActions>
                <CancelButton color='secondary' onClick={onCancel}>Cancel</CancelButton>
                {destructive === true
                    ? <DestructiveButton onClick={onConfirm}>{confirmText ? confirmText : 'Yes'}</DestructiveButton>
                    : <ConfirmButton onClick={onConfirm}>{confirmText ? confirmText : 'Yes'}</ConfirmButton>
                }
            </DialogActions>
        </Dialog>
    )
}

const CancelButton = styled(Button)(({ theme }) => ({
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    '&:hover': {
        borderColor: theme.palette.warning.main,
    }
}));
CancelButton.defaultProps = { variant: 'outlined' }

const DestructiveButton = styled(Button)(({ theme }) => ({
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
        backgroundColor: theme.palette.error.main,
    }
}));
DestructiveButton.defaultProps = { variant: 'contained' }

const ConfirmButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
        backgroundColor: theme.palette.success.main,
    }
}));
ConfirmButton.defaultProps = { variant: 'contained' }