import React from 'react';

// Material-UI
import { makeStyles } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';


export default function CustomTooltip(props) {
    const { color, message, placement } = props;
    const c = useStyles();

    let tooltipStyle;

    switch (color) {
        case 'grey':
            tooltipStyle = c.messageGrey
            break;
        case 'green':
            tooltipStyle = c.messageGreen
            break;
        case 'yellow':
            tooltipStyle = c.messageYellow
            break;
        case 'orange':
            tooltipStyle = c.messageOrange
            break;
        case 'red':
            tooltipStyle = c.messageRed
            break;
        default:
            tooltipStyle = c.messageBlue
            break;
    }

    return (
        <Tooltip arrow classes={{ tooltip: tooltipStyle, arrow: c.tooltipArrow }} disableFocusListener placement={placement || 'top'} enterTouchDelay={0} leaveTouchDelay={5000} title={message || ''}>
            {props.children}
        </Tooltip>
    )
}

const useStyles = makeStyles(theme => ({
    tooltipIcon: {
        padding: 0
    },
    messageGrey: {
        fontSize: '1rem'
    },
    messageGreen: {
        backgroundColor: theme.palette.success.main,
        fontSize: '1rem'
    },
    messageBlue: {
        backgroundColor: theme.palette.secondary.main,
        fontSize: '1rem'
    },
    messageYellow: {
        backgroundColor: '#fcd32d',
        fontSize: '1rem'
    },
    messageOrange: {
        backgroundColor: theme.palette.warning.main,
        fontSize: '1rem'
    },
    messageRed: {
        backgroundColor: theme.palette.error.main,
        fontSize: '1rem'
    },
    tooltipArrow: {
        color: theme.palette.secondary.main
    }
}))