import React, { useState } from 'react';

// Material UI
import { styled, Box, Typography, Paper, Button, Hidden } from '@material-ui/core';
import { TableContainer, Table, TableHead, TableBody, TableFooter, TableRow, TableCell } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import ContactlessIcon from '@material-ui/icons/Contactless';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import MessageIcon from '@material-ui/icons/Message';

// Components
import AuditListItem from 'pages/admin/audit/AuditListItem'



export default function AuditList(props) {
    const { weeksOut, weekStart, weekEnd, totalPhones, totalReaders, totalUnconfirmed, events, eventFilter, onClick } = props;

    const [expanded, setExpanded] = useState(false);

    let headerText = 'This Week';
    let headerDates = `${weekStart} - ${weekEnd}`;

    if (weeksOut === 1) {
        headerText = 'Next Week'
    }

    if (weeksOut > 1) {
        headerText = weeksOut + ' Weeks Out';
    }

    if (weeksOut === -1) {
        headerText = 'Last Week'
    }

    if (weeksOut < -1) {
        headerText = (weeksOut * -1) + ' Weeks Ago'
    }


    let filteredEvents = events;

    if (eventFilter) {
        // User provided a search value to filter by
        const searchRegex = new RegExp(eventFilter.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');

        filteredEvents = events.filter((e) => {
            return searchRegex.test(e.event) || searchRegex.test(e.host)
        });
    }


    return (
        <Root>

            <Toolbar borderBottom={expanded ? undefined : '1px solid #ccc'}>
                <Box display='flex' flexGrow={1} alignItems={'center'} justifyContent={'space-between'}>
                    <EventHeader>{headerText}</EventHeader>

                    <Hidden smDown>
                        <Box display={'flex'} alignItems={'center'}>
                            {totalUnconfirmed > 0 && (
                                <Box ml={2} display={'flex'} alignItems={'center'}>
                                    <TotalText>{totalUnconfirmed}</TotalText>
                                    <QuestionIcon />
                                </Box>
                            )}
                            <Box ml={2} width={40} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                                <TotalText>{totalPhones}</TotalText>
                                <PhoneIcon />
                            </Box>
                            <Box ml={2} width={40} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                                <TotalText>{totalReaders}</TotalText>
                                <ReaderIcon />
                            </Box>
                        </Box>
                    </Hidden>
                </Box>
                <Box ml={2} width={190} display={'flex'} justifyContent={'flex-end'}>
                    <Button onClick={() => setExpanded(!expanded)} endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                        <EventHeader>{headerDates}</EventHeader>
                    </Button>
                </Box>
            </Toolbar>
            
            {expanded === true && (
                <TableContainer component={Paper}>
                    <Table>
                        <Head>
                            <Row>
                                <HeadCell width={20}></HeadCell>
                                <HeadCell width={20} align='center'>DAYS</HeadCell>
                                <HeadCell>EVENT</HeadCell>
                                <HeadCell width={20}><MessageIcon htmlColor='black' /></HeadCell>
                                <HeadCell width={20}><RoomIcon htmlColor='black' /></HeadCell>
                                <HeadCell width={20}><PhoneAndroidIcon htmlColor='black' /></HeadCell>
                                <HeadCell width={20}><ContactlessIcon htmlColor='black' /></HeadCell>
                                <HeadCell width={20}><ReceiptIcon htmlColor='black' /></HeadCell>
                            </Row>
                        </Head>
                        <Body>
                            {filteredEvents.length === 0 && (
                                <Row>
                                    <Cell align='center' colSpan={8}>No events found</Cell>
                                </Row>
                            )}
                            {filteredEvents.map(e => (
                                <AuditListItem
                                    key={e.eventId}
                                    weeksOut={weeksOut}
                                    event={e}
                                    onClick={() => onClick(e)}
                                />
                            ))}
                        </Body>
                        {events.length > 0 && (
                            <Footer>
                                <Row>
                                    <Cell colSpan={5}></Cell>
                                    <Cell align='center'>
                                        <TotalText>{totalPhones}</TotalText>
                                    </Cell>
                                    <Cell align='center'>
                                        <TotalText>{totalReaders}</TotalText>
                                    </Cell>
                                </Row>
                            </Footer>
                        )}
                    </Table>
                </TableContainer>
            )}
        </Root>
    )
}


const Root = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(4)
}));

const Toolbar = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
}));

const Head = styled(TableHead)(({ theme }) => ({
    backgroundColor: theme.palette.grey[300]
}));

const HeadCell = styled(TableCell)(({ theme }) => ({
    ...theme.typography.subHeader,
    fontSize: '14px',
    fontWeight: 'bold',
}));

const Body = styled(TableBody)(({ theme }) => ({
    '&:hover': { cursor: 'pointer' }
}));

const Cell = TableCell;

const Row = styled(({ disabled, ...other }) => <TableRow {...other} />)({
    opacity:         (props) => props.disabled ? 0.5 : 1,
    backgroundColor: (props) => props.disabled ? '#eee' : 'white',
});

const Footer = styled(TableFooter)(({ theme }) => ({
    backgroundColor: theme.palette.grey[300]
}));

const EventHeader = styled(Typography)(({ theme }) => ({
    fontSize: '18px',
    textTransform: 'none', // allows us to render inside a button without autocapitalizing the text
    fontWeight: 'bold'
}));
EventHeader.defaultProps = { variant: 'caption' }

const TotalText = styled(Typography)(({ theme }) => ({
    color: '#000',
    fontWeight: 'bold',
    fontSize: '18px'
}));


const QuestionIcon = styled(HelpOutlineIcon)(({ theme }) => ({
    marginLeft: theme.spacing(0.5)
}));

const PhoneIcon = styled(PhoneAndroidIcon)(({ theme }) => ({
    marginLeft: theme.spacing(0.5)
}));

const ReaderIcon = styled(ContactlessIcon)(({ theme }) => ({
    marginLeft: theme.spacing(0.5)
}));