import React, { useState } from 'react';

// Material UI
import { ControlBar, ControlBarButton, ControlBarHeader } from 'components/ui/layout';
import { Paper, Button, Box, Divider, styled, useTheme, useMediaQuery, Typography, IconButton } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SwapVertIcon from '@material-ui/icons/SwapVert';

// Components
import { ActionButton } from 'components/ui/buttons';
import ProductTitle from 'components/products/ProductTitle';
import ProductCreator from 'components/forms/ProductCreator';
import ProductEditor from 'components/forms/ProductEditor';
import FullScreenModal from 'components/popups/FullScreenModal';
import Tooltip from 'components/popups/Tooltip';


// Utility
import { useAuth } from 'context/auth';
import ReactGA from 'react-ga'
import { useEvent } from 'context/event';
import { formatPrice } from 'utility/numbers';
import fetchExpresso from 'utility/fetchExpresso';
import { useNotification } from 'context/notification';


export default function ProductList(props) {
    const { eventId, products, enableSort, onProductSort, toggleProductSorting, onProductSortSubmit, onUpdate } = props;

    // State
    const [modal, setModal] = useState({
        open: false,
        productId: null
    });

    const [copyId, setCopyId] = useState(null); // Id of the product being copied


    // Utility
    const event = useEvent();
    const { auth } = useAuth();
    const { createNotification } = useNotification();
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('md'));

    const hostId = auth.hostId;


    const handleModalOpen = () => setModal({ open: true, productId: null });
    const handleModalClose = () => setModal({ open: false, productId: null });

    function handleProductSelect(id) {
        ReactGA.event({ category: "Tickets", action: 'View Product ', label: `${hostId}` });
        setModal({ open: true, productId: id })
    }
    
    function handleProductCreate() {
        ReactGA.event({ category: "Tickets", action: 'Create Product ', label: `${hostId}` });
        setModal({ open: false, productId: null });
        onUpdate();
    }
   
    function handleProductEdit() {
        ReactGA.event({ category: "Tickets", action: 'Edit Product ', label: `${hostId}` });
        setModal({ open: false, productId: null });
        onUpdate();
    }
    
    function handleProductDelete() {
        ReactGA.event({ category: "Tickets", action: 'Delete Product ', label: `${hostId}` });
        setModal({ open: false, productId: null });
        onUpdate();
    }

    const handleProductCopy = (id) => {
        if (copyId) return;
        setCopyId(id);

        fetchExpresso(`/apiv2/products/?sourceId=${id}`, {
            method: 'POST',
            body: { hostId: hostId, eventId: eventId }
        })
            .then(res => {
                if (res.status === 200) {
                    handleProductCreate();
                    createNotification('Copied ticket');
                } else {
                    createNotification('Unable to copy ticket.');
                }
            })
            .catch(() => {
                createNotification('Unable to copy ticket.');
            })
            .finally(() => {
                setCopyId(null);
            })
    };

    return (
        <>
            <ControlBar>
                <ControlBarHeader>Admission Tickets</ControlBarHeader>
                <ControlBarButton onClick={handleModalOpen}>New Ticket</ControlBarButton>
            </ControlBar>

            {mobileView === true && (
                <MobileProductList
                    event={event}
                    products={products}
                    onProductSelect={handleProductSelect}
                    onProductCopy={handleProductCopy}
                    copyId={copyId}
                    onProductSort={onProductSort}
                    enableSort={enableSort}
                    toggleProductSorting={toggleProductSorting}
                    onProductSortSubmit={onProductSortSubmit}
                />
            )}

            {mobileView === false && (
                <DesktopProductList
                    event={event}
                    products={products}
                    onProductSelect={handleProductSelect}
                    onProductCopy={handleProductCopy}
                    copyId={copyId}
                    onProductSort={onProductSort}
                    enableSort={enableSort}
                    toggleProductSorting={toggleProductSorting}
                    onProductSortSubmit={onProductSortSubmit}
                />
            )}

            <FullScreenModal open={modal.open} onClose={handleModalClose} title={modal.productId ? 'Edit Ticket' : 'Create Ticket'} cancelText='Cancel'>
                {modal.productId ? (
                    <ProductEditor
                        hostId={hostId}
                        eventId={eventId}
                        productId={modal.productId}
                        onCancel={handleModalClose}
                        onEdit={handleProductEdit}
                        onDelete={handleProductDelete}
                    />
                ) : (
                    <ProductCreator
                        hostId={hostId}
                        eventId={eventId}
                        onCreate={handleProductCreate}
                        onCancel={handleModalClose}
                    />
                )}
            </FullScreenModal>
        </>
    )
}

/**
 * This component is used to display the product list on desktop devices.
 * AddonList.js also uses this component.
 */
export const DesktopProductList = ({ addon, event, products, onProductSelect, onProductSort, enableSort, toggleProductSorting, onProductSortSubmit, onProductCopy, copyId }) => (
    <TableContainer component={Paper}>
        <Table>
            <TableHead>
                <TableRow>
                    <HeaderCell>NAME</HeaderCell>
                    <HeaderCell width={60} align='right'>ONLINE</HeaderCell>
                    <HeaderCell width={60} align='right'>IN-PERSON</HeaderCell>
                    <HeaderCell width={80} align='right'>SOLD</HeaderCell>
                    <HeaderCell width={90} align='right'>CHECKED-IN</HeaderCell>
                    <HeaderCell colSpan={2}></HeaderCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {products.length === 0 && (
                    <TableRow>
                        <TableCell align='center' colSpan={6}>
                            {addon === true ? 'No add-ons found' : 'No tickets found'}
                        </TableCell>
                    </TableRow>
                )}

                {products.map((product, index) => (
                    <TableRow key={product.product_id}>
                        <TableCell>
                            <ProductTitle
                                name={product.prod_name}
                                visible={product.visible}
                                startDate={product.sale_start_date}
                                endDate={product.sale_end_date}
                                timezone={event.time_zone}
                                qtyLeft={product.qty_left}
                                accessCode={product.access_code}
                                onEdit={() => onProductSelect(product.product_id)}
                            />
                        </TableCell>
                        <TableCell align='right'>{formatPrice(product.price)}</TableCell>
                        {product.pos_visible ? (
                            <TableCell align='right'>{product.pos_price === null ? formatPrice(product.price) : formatPrice(product.pos_price)}</TableCell>
                        ) : (
                            <TableCell align='right'>n/a</TableCell>
                        )}
                        <TableCell align='right'>{product.qty_sold} / {product.total_available}</TableCell>
                        <TableCell align='right'>{product.scan_count} / {product.qty_sold}</TableCell>

                        {enableSort === true ? (
                            <TableCell align='right' width={180}>
                                <ArrowButton disabled={index === 0} onClick={() => onProductSort(product.product_id, 'up')}>
                                    <ArrowDropUpIcon />
                                </ArrowButton>
                                <ArrowButton disabled={index === products.length - 1} onClick={() => onProductSort(product.product_id, 'down')}>
                                    <ArrowDropDownIcon />
                                </ArrowButton>
                            </TableCell>
                        ) : (
                            <TableCell align='right' width={180}>
                                <ActionButton disabled={copyId !== null} onClick={() => onProductCopy(product.product_id)} startIcon={copyId === product.product_id ? undefined : <FileCopyIcon />}>{copyId === product.product_id ? 'Copying' : 'Copy'}</ActionButton>
                                <ActionButton style={{ marginLeft: 8 }} onClick={() => onProductSelect(product.product_id)}>Edit</ActionButton>
                            </TableCell>
                        )}
                    </TableRow>
                ))}
            </TableBody>
            {products.length > 1 && (
                <TableFooter>
                    <TableRow>
                        {enableSort === true ? (
                            <TableCell align='right' colSpan={6}>
                                <CancelButton onClick={toggleProductSorting}>Cancel</CancelButton>
                                <SaveButton onClick={onProductSortSubmit}>Save</SaveButton>
                            </TableCell>
                        ) : (
                            <TableCell align='right' colSpan={6}>
                                <Tooltip message='Your customers will see your tickets in the order listed above. You can change the Display Order here.'>
                                    <span>
                                        <ActionButton startIcon={<SwapVertIcon />} onClick={toggleProductSorting}>Set Display Order</ActionButton>
                                    </span>
                                </Tooltip>
                            </TableCell>
                        )}
                    </TableRow>
                </TableFooter>
            )}
        </Table>
    </TableContainer>
);


/**
 * This component is used to display the product list on mobile devices.
 * AddonList.js also uses this component.
 */
export const MobileProductList = ({ addon, event, products, onProductSelect, onProductSort, enableSort, toggleProductSorting, onProductSortSubmit, onProductCopy, copyId }) => (
    <Box>
        {products.length === 0 && (
            <Box height={128} display='flex' justifyContent='center' alignItems='center'>
                <Typography>{addon === true ? 'No add-ons found' : 'No tickets found'}</Typography>
            </Box>
        )}
        {products.map((product, index) => (
            <MobileListItem key={product.product_id}>
                <ProductTitle
                    name={product.prod_name}
                    visible={product.visible}
                    startDate={product.sale_start_date}
                    endDate={product.sale_end_date}
                    timezone={event.time_zone}
                    qtyLeft={product.qty_left}
                    accessCode={product.access_code}
                    onEdit={() => onProductSelect(product.product_id)}
                />

                <Box mt={4}>

                    <Box display={'flex'} justifyContent={'space-between'} p={1}>
                        <Typography>Online Price:</Typography>
                        <Typography>{formatPrice(product.price)}</Typography>
                    </Box>
                    <Divider />
                    <Box display={'flex'} justifyContent={'space-between'} p={1}>
                        <Typography>In-Person Price:</Typography>
                        {product.pos_visible
                            ? <Typography>{product.pos_price === null ? formatPrice(product.price) : formatPrice(product.pos_price)}</Typography>
                            : <Typography>n/a</Typography>
                        }                                        
                    </Box>
                    <Divider />
                    <Box display={'flex'} justifyContent={'space-between'} p={1}>
                        <Typography>Sold:</Typography>
                        <Typography>{product.qty_sold} / {product.total_available}</Typography>
                    </Box>
                    <Divider />
                    <Box display={'flex'} justifyContent={'space-between'} p={1}>
                        <Typography>Checked-in:</Typography>
                        <Typography>{product.scan_count} / {product.qty_sold}</Typography>
                    </Box>

                
                    {enableSort === true ? (
                        <Box display='flex' justifyContent={'flex-end'} alignItems={'flex-end'} pt={2}>
                            <ArrowButton disabled={index === 0} onClick={() => onProductSort(product.product_id, 'up')}>
                                <ArrowDropUpIcon />
                            </ArrowButton>
                            <ArrowButton disabled={index === products.length - 1} onClick={() => onProductSort(product.product_id, 'down')}>
                                <ArrowDropDownIcon />
                            </ArrowButton>
                        </Box>
                    ) : (
                        <Box display='flex' justifyContent={'flex-end'} alignItems={'flex-end'} pt={2}>
                            <ActionButton disabled={copyId !== null} onClick={() => onProductCopy(product.product_id)} startIcon={copyId === product.product_id ? undefined : <FileCopyIcon />}>{copyId === product.product_id ? 'Copying' : 'Copy'}</ActionButton>
                            <ActionButton style={{ marginLeft: 8 }} onClick={() => onProductSelect(product.product_id)}>Edit</ActionButton>
                        </Box>
                    )}
                </Box>
            </MobileListItem>
        ))}

        {(products.length > 1 && enableSort === true) && (
            <Box display='flex' justifyContent={'flex-end'} py={2}>
                <CancelButton onClick={toggleProductSorting}>Cancel</CancelButton>
                <SaveButton onClick={onProductSortSubmit}>Save</SaveButton>
            </Box>
        )}

        {(products.length > 1 && enableSort === false) && (
            <Box display='flex' justifyContent={'flex-end'} py={2}>
                <Tooltip message='Your customers will see your tickets in the order listed above. You can change the Display Order here.'>
                    <span>
                        <ActionButton startIcon={<SwapVertIcon />} onClick={toggleProductSorting}>Set Display Order</ActionButton>
                    </span>
                </Tooltip>
            </Box>
        )}
    </Box>
);


const MobileListItem = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
}));

const HeaderCell = styled(TableCell)(({ theme }) => ({
    ...theme.typography.subHeader
}));

const SaveButton = styled(Button)(({ theme }) => ({
    color: theme.palette.success.main,
    marginRight: theme.spacing(1)
}));

const CancelButton = styled(Button)(({ theme }) => ({
    color: theme.palette.warning.main,
    marginRight: theme.spacing(1)
}));

const ArrowButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.grey[200],
    marginLeft: theme.spacing(1)
}));
ArrowButton.defaultProps = { size: 'small', disableRipple: true };