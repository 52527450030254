import React from 'react';

// Material-UI
import { styled, CircularProgress, Box, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

// Utility
import { formatPrice } from 'utility/numbers';
import useExpresso from 'hooks/useExpresso';

export default function HostPricingDetails(props) {
    const { eventId, hostId } = props;

    let endpoint = `/apiv2/events/${eventId}`;

    if (hostId) {
        endpoint = `/apiv2/hosts/${hostId}/host_pricing`;
    }

    const [data, loading, error]  = useExpresso(endpoint, 'GET', null, null, [eventId]);


    // RETURN: Loading
    if (loading) {
        return (
            <Box display='flex' height='100%' width='100%' alignItems='center' justifyContent='center'>
                <CircularProgress />
            </Box>
        );
    }

    // RETURN: Error
    if (error || !data) {
        return (
            <Box display='flex' height='100%' width='100%' alignItems='center' justifyContent='center'>
                <p>There was a problem getting your pricing details</p>
            </Box>
        )
    }

    return (
        <Root>

            <Header>Convenience Fee</Header>
            
            {data.b4t_fee_order_pct > 0 && (
                <Text><b>{`${Number((data.b4t_fee_order_pct * 100).toFixed(2))}%${data.taxable_b4t_fees === 1 ? ' + HST ' : ''}`}</b> per order</Text>
            )}
            {data.b4t_fee_order_amt > 0 && (
                <Text><b>{`${formatPrice(data.b4t_fee_order_amt)}${data.taxable_b4t_fees === 1 ? ' + HST ' : ''}`}</b> per order</Text>
            )}
            {data.b4t_fee_line_item_amt > 0 && (
                <Text><b>{`${formatPrice(data.b4t_fee_line_item_amt)}${data.taxable_b4t_fees === 1 ? ' + HST ' : ''}`}</b> per ticket</Text>
            )}

            <Header>Credit Card Fee</Header>

            {data.cc_fee_order_pct > 0 && (
                <Text><b>{`${(data.cc_fee_order_pct * 100).toFixed(2)}%`}</b> per order</Text>
            )}
            {data.cc_fee_order_amt > 0 && (
                <Text><b>{formatPrice(data.cc_fee_order_amt)}</b> per order</Text>
            )}

            <Footer>

                <NoteContainer>
                    <CheckIcon fontSize='small' />
                    {data.host_absorbs_b4t_fees
                        ? <Note>Host absorbs convenience fee</Note>
                        : <Note>Purchaser covers convenience fee</Note>
                    }
                </NoteContainer>

                <NoteContainer>
                    <CheckIcon fontSize='small' />
                    {data.host_absorbs_cc_fees
                        ? <Note>Host absorbs credit card fee</Note>
                        : <Note>Purchaser covers credit card fee</Note>
                    }
                </NoteContainer>

                <NoteContainer>
                    <CheckIcon fontSize='small' />
                    <Note>Credit card transaction descriptor: FD*&nbsp;{data.cc_statement_descriptor || 'FRONTDOOR+'}</Note>
                </NoteContainer>

            </Footer>

        </Root>
    )
}


const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
});

const Header = styled('h4')(({ theme }) => ({
    ...theme.typography.subHeader,
    marginBottom: 0
}));

const Text = styled(Typography)({
    marginLeft: 36,
    marginTop: 8
});

const Footer = styled('div')({
    marginTop: 36
});

const NoteContainer = styled('div')({
    display: 'flex',
    alignItems: 'center'
});

const Note = styled('h4')(({ theme }) => ({
    ...theme.typography.subHeader,
    margin: 0,
    marginLeft: '4px'
}));