import React from 'react';
import ReactDOM from 'react-dom';
import 'index.css';
import RootNavigator from 'components/navigation/RootNavigator';
import * as serviceWorker from 'serviceWorker';

// Context
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from 'context/theme';
import { AuthProvider } from 'context/auth';
import { NotificationProvider } from 'context/notification';

ReactDOM.render((
    <NotificationProvider>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <RootNavigator />
        </ThemeProvider>
      </AuthProvider>
    </NotificationProvider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
