import React, { useState } from 'react';
import { styled, Box, Typography, CircularProgress } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { ActionButton } from 'components/ui/buttons';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import LanguageIcon from '@material-ui/icons/Language';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ConfirmAction from 'components/popups/ConfirmAction';
import { useNotification } from 'context/notification';
import fetchExpresso from 'utility/fetchExpresso';
import { formatPrice } from 'utility/numbers';



export default function AdminControls(props) {
    const { order, tranId, eventId, enableRefund, enableReceipt, onRefund } = props;

    const [confirmRefund, setConfirmRefund] = useState(false);
    const [loadingRefund, setLoadingRefund] = useState(false);

    const { createNotification } = useNotification();


    function markRefunded() {
        fetchExpresso(`/apiv2/orders/${tranId}/refund`, {
            method: 'PUT',
            body: { eventId: eventId }
        })
            .then((res) => {
                if (res.status === 200) {
                    createNotification('Order has been refunded');
                    onRefund();
                } else {
                    createNotification('There was a problem with the refund');
                    setLoadingRefund(false);
                }
            })
            .catch(() => {
                createNotification('There was a problem with the refund');
                setLoadingRefund(false);
            });
        
        // Close confirmation dialog and set loading state
        setConfirmRefund(false);
        setLoadingRefund(true);
    }


    return (
        <Card>
            <CardHeader
                title={<Box display='flex' alignItems='center'><SupervisorAccountIcon /><Title>Admin Controls</Title></Box>}
                subheader='For use by FrontDoor+ admins only' />
            <CardContent>
                <Box display='flex' flexDirection='column' alignItems='flex-start'>
                    {(enableReceipt && order.stripe_url) && (
                        <ActionButton href={order.stripe_url} target="_blank" rel='noopener noreferrer' startIcon={<LanguageIcon />}>View Stripe Receipt</ActionButton>
                    )}

                    {(enableRefund && loadingRefund === false) && (
                        <Box mt={1}>
                            <ActionButton onClick={() => setConfirmRefund(true)} startIcon={<MoneyOffIcon />}>
                                Issue Stripe Refund
                            </ActionButton>
                        </Box>
                    )}
                    {(enableRefund && loadingRefund === true) && (
                        <Box mt={1}>
                            <ActionButton disabled startIcon={<CircularProgress color='inherit' size={18} />}>
                                Issue Stripe Refund
                            </ActionButton>
                        </Box>
                    )}

                    <Header>Convenience Fee</Header>
                    
                    {order.b4t_fee_order_pct > 0 && (
                        <Text><b>{`${Number((order.b4t_fee_order_pct * 100).toFixed(2))}%${order.taxable_b4t_fees === 1 ? ' + HST ' : ''}`}</b> per order</Text>
                    )}
                    {order.b4t_fee_order_amt > 0 && (
                        <Text><b>{`${formatPrice(order.b4t_fee_order_amt)}${order.taxable_b4t_fees === 1 ? ' + HST ' : ''}`}</b> per order</Text>
                    )}
                    {order.b4t_fee_line_item_amt > 0 && (
                        <Text><b>{`${formatPrice(order.b4t_fee_line_item_amt)}${order.taxable_b4t_fees === 1 ? ' + HST ' : ''}`}</b> per ticket</Text>
                    )}

                    {order.b4t_fee_min_amt > 0 && (
                        <Text><b>{formatPrice(order.b4t_fee_min_amt)}</b> minimum</Text>
                    )}
                    {order.b4t_fee_max_amt > 0 && (
                        <Text><b>{formatPrice(order.b4t_fee_max_amt)}</b> maximum</Text>
                    )}

                    <Header>Credit Card Fee</Header>

                    {order.cc_fee_order_pct > 0 && (
                        <Text><b>{`${(order.cc_fee_order_pct * 100).toFixed(2)}%`}</b> per order</Text>
                    )}
                    {order.cc_fee_order_amt > 0 && (
                        <Text><b>{formatPrice(order.cc_fee_order_amt)}</b> per order</Text>
                    )}
                </Box>
            </CardContent>

            <ConfirmAction
                title={'Stripe Refund'}
                description={'This refund will be issued directly through Stripe and the customer will have their funds returned.'}
                open={confirmRefund}
                onConfirm={markRefunded}
                onCancel={() => setConfirmRefund(false)}
            />
        </Card>
    );
}


const Title = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
    display: 'inline'
}));
Title.defaultProps = { variant: 'h6' }


const Header = styled('h4')(({ theme }) => ({
    ...theme.typography.subHeader,
    marginBottom: 0
}));

const Text = styled(Typography)({
    marginLeft: 36,
    marginTop: 8
});