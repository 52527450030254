import React, { useEffect, useState } from 'react';
import { styled, TextField, Button, Typography } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';



export default function TextareaDialog(props) {
    const { open, title, defaultValue, disabled, onConfirm, onClose } = props;
    const maxLength = props.maxLength || 2000;
    
    const [value, setValue] = useState(defaultValue || '');

    const handleSubmit = () => {
        onConfirm(value.trim())
    };

    // Clear text field when dialog is closed
    useEffect(() => {
        if (open === false) {
            setValue('');
        }
    }, [open]);


    const error = value.length > maxLength;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth='sm'
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {open === true && (
                    <TextField
                        autoFocus
                        multiline
                        variant='outlined'
                        minRows={5}
                        maxRows={5}
                        defaultValue={value}
                        onChange={(e) => setValue(e.target.value)}
                        fullWidth
                        error={error}
                    />
                )}
                {error
                    ? <CounterError>{String(value.length)} of {maxLength}</CounterError>
                    : <Counter>{String(value.length)} of {maxLength}</Counter>
                }
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={onClose}>Cancel</CancelButton>
                <SubmitButton disabled={disabled || error || value.trim().length === 0} onClick={handleSubmit}>Save</SubmitButton>
            </DialogActions>
        </Dialog>
    )
}


const Counter = styled(Typography)(({ theme }) => ({
    ...theme.typography.caption,
    textAlign: 'right',
    marginTop: theme.spacing(1),
    color: theme.palette.grey[700]
}));

const CounterError = styled(Typography)(({ theme }) => ({
    ...theme.typography.caption,
    textAlign: 'right',
    marginTop: theme.spacing(1),
    color: theme.palette.error.main
}));

const CancelButton = styled(Button)(({ theme }) => ({
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    marginLeft: theme.spacing(2),
}));
CancelButton.defaultProps = { variant: 'outlined' }

const SubmitButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
}));
SubmitButton.defaultProps = { variant: 'contained' }