import React, { useState } from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Link } from '@material-ui/core';

// Utility
import { useAuth } from 'context/auth';
import fetchExpresso from 'utility/fetchExpresso';
import { Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

// Images
import logo from '../images/logo.png';


export default function Login() {
    // State
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [loggingIn, setLogginIn] = useState(false);

    // Hooks
    const { auth, login } = useAuth();
    const classes = useStyles();


    // Login API call
    function submitLogin() {
        setLogginIn(true);

        fetchExpresso(`/apiv2/auth/portal`, {
            method: 'POST',
            body: { username, password }
        })
            .then(async res => {
                setLogginIn(false);

                if (res.status !== 200) {
                    setError(true)
                    return null
                }

                const data = await res.json();

                login({ ...data }); // { userId, hostId, hostName, token, admin }
    
                ReactGA.event({ category: "Authentication", action: 'Log In', label: `${ data.hostId ? data.hostId : 'SuperAdmin'}` });
            })
            .catch(() => setLogginIn(false))
    }

    //Redirect to Home or Host Select page
    if (auth) {
        return <Redirect to='/' />;
    }

    // Submit login if enter key is hit
    function onKeyPress(e) {
        if (e.key === 'Enter') {
            submitLogin();
        }
    }


    return (
        <div className={classes.container}>
            <form className={classes.form}>
                <img alt='FrontDoor+ Logo' src={logo} className={classes.logo} />
                <TextField className={classes.textField} error={error} onKeyPress={onKeyPress} value={username} onChange={e => setUsername(e.target.value)} label="Email" type='email' variant="outlined" />
                <TextField className={classes.textField} error={error} onKeyPress={onKeyPress} value={password} onChange={e => setPassword(e.target.value)} label="Password" type="password" variant="outlined" />
                {error && <p className={classes.error}>Incorrect email/password</p>}
                <Button color='secondary' className={classes.button} disabled={loggingIn} onClick={submitLogin} variant="contained">Submit</Button>
                <Link color='secondary' href={`${process.env.REACT_APP_PUBLIC_URL}/forgotpassword`} variant="body2" className={classes.link} >Forgot password?</Link>
            </form>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    link: {
        marginTop: theme.spacing(2)
    },
    container: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    logo: {
        width: '500px',
        maxWidth: '100%',
        marginBottom: '90px'
    },
    textField: {
        width: '250px',
        margin: '10px'
    },
    error: {
        margin: '10px'
    },
    button: {
        width: '250px',
        fontWeight: 'bold',
        marginTop: '10px'
    }
}))