import React, { useState, useEffect } from 'react';
import { styled, Box, Button, Typography, CircularProgress, Card as _Card, CardHeader, CardContent } from '@material-ui/core';
import { Page, ControlBar, ControlBarHeader } from 'components/ui/layout';
import fetchExpresso from 'utility/fetchExpresso';
import Chart from 'react-apexcharts';



export default function Registration(props) {
    const eventId = props.match.params.id;

    const [state, setState] = useState({
        status: 'loading',
        questions: []
    });

    useEffect(() => {

        fetchExpresso(`/apiv2/events/${eventId}/registration/summary`)
            .then(async res => {
                if (res.status === 404) {
                    // No questions found for this event
                    setState(s => ({ ...s, status: 'error' }))
                }
                if (res.status !== 200) {
                    // Unknown error
                    setState(s => ({ ...s, status: 'error' }))
                }

                const questions = await res.json();

                setState(s => ({ ...s, status: 'success', questions: questions }))
            })
            .catch(() => setState(s => ({ ...s, status: 'error' })))
    }, [eventId]);


    if (state.status === 'loading') {
        return (
            <Page>
                <ControlBar>
                    <ControlBarHeader>Checkout Form</ControlBarHeader>
                </ControlBar>

                <Box display='flex' justifyContent='center' alignItems='center' height={72}>
                    <CircularProgress />
                </Box>
            </Page>
        )
    }

    if (state.status === 'error') {
        return (
            <Page>
                <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' mt={12}>
                    <Typography variant='h5'>There was a problem finding your checkout form summary</Typography>
                    <Box height={32} />
                    <Button variant='outlined' onClick={() => props.history.goBack()}>Back</Button>
                </Box>
            </Page>
        )
    }


    return (
        <Page>

            <ControlBar>
                <ControlBarHeader>Checkout Form</ControlBarHeader>
            </ControlBar>

            {state.questions.map(q => (
                <CustomChart
                    key={q.q_id}
                    question={q}
                />
            ))}

        </Page>
    )
}


function CustomChart(props) {
    switch (props.question.q_type_id) {
        case 1:
        case 2:
            return <TextResponse {...props} />
        case 3:
        case 4:
        case 5:
            return <BarChart {...props} />
        case 6:
            return <FullDate {...props} />
        case 9:
            return <ShortDate {...props} />
        case 7:
            return <Age {...props} />
        case 8:
            return <YesOrNo {...props} />
        case 998:
            return <ImageBlock {...props} />
        case 999:
            return <TextBlock {...props} />
        default:
            return null;
    }
}

function TextResponse(props) {
    const { q_desc, required, result } = props.question;

    return (
        <Card>
            <CardHeader
                disableTypography
                title={<>
                    {Boolean(required) && <Required />}
                    <TitleBold>{q_desc}</TitleBold>
                </>}
            />
            <CardContent>
                <Typography variant='h3'>{result.total_responses}</Typography>
                <Typography variant='caption'>Total Responses</Typography>
            </CardContent>
        </Card>
    )
}

function BarChart(props) {
    const { q_desc, required, result } = props.question;

    const categories = result.map(q => q.option_desc);

    const series = [{
        name: q_desc,
        data: result.map(q => q.answer_count)
    }]


    const options = {
        chart: {
            type: 'bar',
            width: '100%'
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
            }
        },
        dataLabels: { enabled: false },
        xaxis: {
            categories: categories,
        },
        yaxis: {
            labels: {
                maxWidth: 240
            }
        },
        responsive: [{
            breakpoint: 900,
            options: {
                yaxis: {
                    labels: {
                        maxWidth: 100
                    }
                },
            },
        }]
    }
    
    return (
        <Card>
            <CardHeader
                disableTypography
                title={<>
                    {Boolean(required) && <Required />}
                    <TitleBold>{q_desc}</TitleBold>
                </>}
            />
            <CardContent>
                {result.length > 0 ? (
                    <Chart
                        type='bar'
                        height={getBarChartHeight(categories.length)}
                        options={options}
                        series={series}
                    />
                ) : (
                    <NoResponses />
                )}
            </CardContent>
        </Card>
    )
}

function ShortDate(props) {
    const { q_desc, required, result } = props.question;

    const categories = result.map(q => q.short_date);

    const series = [{
        name: q_desc,
        data: result.map(q => q.date_count)
    }]


    const options = {
        chart: {
            type: 'bar',
            width: '100%'
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
            }
        },
        dataLabels: { enabled: false },
        xaxis: {
            categories: categories,
        },
        responsive: [{
            breakpoint: 900,
            options: {
                yaxis: {
                    labels: {
                        maxWidth: 100
                    }
                },
            },
        }]
    }
    
    return (
        <Card>
            <CardHeader
                disableTypography
                title={<>
                    {Boolean(required) && <Required />}
                    <TitleBold>{q_desc}</TitleBold>
                </>}
            />
            <CardContent>
                {result.length > 0 ? (
                    <Chart
                        type='bar'
                        height={getBarChartHeight(categories.length)}
                        options={options}
                        series={series}
                    />
                ) : (
                    <NoResponses />
                )}

            </CardContent>
        </Card>
    )
}

function FullDate(props) {
    const { q_desc, required, result } = props.question;

    const categories = result.map(q => q.full_date);

    const series = [{
        name: q_desc,
        data: result.map(q => q.date_count)
    }]


    const options = {
        chart: {
            type: 'bar',
            width: '100%'
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
            }
        },
        dataLabels: { enabled: false },
        xaxis: {
            categories: categories,
        },
        responsive: [{
            breakpoint: 900,
            options: {
                yaxis: {
                    labels: {
                        maxWidth: 100
                    }
                },
            },
        }]
    }
    
    return (
        <Card>
            <CardHeader
                disableTypography
                title={<>
                    {Boolean(required) && <Required />}
                    <TitleBold>{q_desc}</TitleBold>
                </>}
            />
            <CardContent>
                {result.length > 0 ? (
                    <Chart
                        type='bar'
                        height={getBarChartHeight(categories.length)}
                        options={options}
                        series={series}
                    />
                ) : (
                    <NoResponses />
                )}
            </CardContent>
        </Card>
    )
}

function Age(props) {
    const { q_desc, required, result } = props.question;

    const noResults = result.min_age === null || result.avg_age === null || result.max_age === null;

    return (
        <Card>
            <CardHeader
                disableTypography
                title={<>
                    {Boolean(required) && <Required />}
                    <TitleBold>{q_desc}</TitleBold>
                </>}
            />

            <CardContent>
                {noResults ? (
                    <NoResponses />
                ) : (
                    <Box display='flex' justifyContent='space-around' my={2}>
                        <Box>
                            <AgeValue>{result.min_age}</AgeValue>
                            <AgeLabel>Youngest</AgeLabel>
                        </Box>
                        <Box>
                            <AgeValue>{result.avg_age}</AgeValue>
                            <AgeLabel>Average</AgeLabel>
                        </Box>
                        <Box>
                            <AgeValue>{result.max_age}</AgeValue>
                            <AgeLabel>Oldest</AgeLabel>
                        </Box>
                    </Box>
                )}
            </CardContent>
        </Card>
    )
}

function YesOrNo(props) {
    const { q_desc, required, result } = props.question;

    let series = [0];

    if (result.yes_count) {
        series = [Math.round((result.yes_count / result.total_count) * 100)];
    }

    const options = {
        chart: {
            type: 'radialBar',
            offsetY: 60,
            sparkline: { enabled: true }
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                dataLabels: {
                    value: {
                        offsetY: -45,
                        fontSize: '22px'
                    }
                  },
            },
        },
        labels: ['Yes']
    }

    return (
        <Card>
            <CardHeader
                disableTypography
                title={<>
                    {Boolean(required) && <Required />}
                    <Title>{q_desc}</Title>
                </>}
            />
            <CardContent>
                    {result.total_count > 0 ? (
                        <Box display='flex' justifyContent='center'>
                            <Box height={400} width={400}>
                                <Chart
                                    type='radialBar'
                                    height='100%'
                                    width='100%'
                                    options={options}
                                    series={series}
                                />
                            </Box>
                        </Box>
                    ) : (
                        <NoResponses />
                    )}
            </CardContent>
        </Card>
    )
}


function ImageBlock(props) {
    const { image_url, image_link } = props.question;

    return (
        <ImageContainer>
            {image_link ? (
                <ImageLink href={image_link} target="_blank" rel="noopener noreferrer">
                    <Image src={image_url} alt='' />
                </ImageLink>
            ) : (
                <Image src={image_url} alt='' />
            )}
        </ImageContainer>
    )
}


function TextBlock(props) {
    const { q_desc } = props.question;

    return (
        <Box mt={5} mb={5}>
            <BlockContent variant='body1'>{q_desc}</BlockContent>
        </Box>
    )
}




function getBarChartHeight(length) {
    let chartHeight = 240;
    if (length > 8) chartHeight = 400;
    if (length > 12) chartHeight = 500;

    return chartHeight;
}


function NoResponses() {
    return (
        <Box display='flex' flexDirection='column' alignItems='center'>
            <Typography variant='h6'>No reponses</Typography>
            <Typography variant='caption'>When customers fill in your checkout form, their answers will be summarized here</Typography>
        </Box>
    )
}


const Card = styled(_Card)(({ theme }) => ({
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
}));

const TitleBold = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    whiteSpace: 'pre-wrap'
}));
TitleBold.defaultProps = { variant: 'h6', display: 'inline' };

const Title = styled(Typography)(({ theme }) => ({
    whiteSpace: 'pre-wrap',
}));
Title.defaultProps = { display: 'inline' };

const Required = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.error.dark
}));
Required.defaultProps = { variant: 'h6', display: 'inline', children: '* ' };

const AgeValue = styled(Typography)(({ theme }) => ({
    textAlign: 'center'
}));
AgeValue.defaultProps = { variant: 'h3' }

const AgeLabel = styled(Typography)(({ theme }) => ({
    textAlign: 'center'
}));
AgeLabel.defaultProps = { variant: 'caption', display: 'block', align: 'center' }

const BlockContent = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    whiteSpace: 'pre-wrap'
}));

const ImageContainer = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '520px',
    [theme.breakpoints.down('sm')]: {
        width: '100%'
    }
}));

const ImageLink = styled('a')(({ theme }) => ({
    width: '100%'
}));

const Image = styled('img')({
    width: '100%',
    borderRadius: '4px',
    objectFit: 'contain'
});