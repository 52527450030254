import React, { useEffect, useReducer, useRef } from 'react';
import { styled, Box } from '@material-ui/core';

import FullScreenModal from 'components/popups/FullScreenModal';
import OrderCreateForm from 'components/orders/OrderCreateForm';
import OrderReview from 'components/orders/OrderReview';
import PostOrderSummary from 'components/orders/PostOrderSummary';
import fetchExpresso from 'utility/fetchExpresso';

import { useEvent } from 'context/event';
import { useNotification } from 'context/notification';
import moment from 'moment-timezone';
import { ErrorPage } from 'components/ui/states';



const defaultState = {
    page: 1,
    cart: {
        products: [],
        bundles: [],
        status: 'loading', // loading, error, success
    },
    customer: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        company: '',
        address: '',
        city: '',
        province: '',
        postalCode: '',
        country: 'CA'
    },
    order: {
        loading: false,
        orderId: null
    }
}


function reducer(state, action) {
    switch (action.type) {
        case 'setCart':
            return { ...state, cart: { products: action.products, bundles: action.bundles, status: 'success' } };

        case 'setCartError':
            return { ...state, cart: { products: [], bundles: [], status: 'error' } }

        case 'updateCartProducts':
            return { ...state, cart: { ...state.cart, products: state.cart.products.map(i => {
                if (action.id === i.id) {
                    return { ...i, quantity: action.value };
                } else {
                    return i;
                }
            })}}
        
        case 'updateCartBundles':
            return { ...state, cart: { ...state.cart, bundles: state.cart.bundles.map(i => {
                if (action.id === i.id) {
                    return { ...i, quantity: action.value };
                } else {
                    return i;
                }
            })}}

        case 'updateCustomer':
            return { ...state, customer: { ...state.customer, ...action.form }};

        case 'setOrderLoading':
            return { ...state, order: { ...state.order, loading: action.value }};

        case 'setOrder':
            return { ...state, page: 3, order: { ...state.order, loading: false, orderId: action.id } };

        case 'next':
            return { ...state, page: state.page + 1 };

        case 'back':
            return { ...state, page: state.page - 1 };
        
        case 'restart':
            return { ...defaultState, cart: {
                products: state.cart.products.map(i => ({ ...i, quantity: 0 })),
                bundles: state.cart.bundles.map(i => ({ ...i, quantity: 0 })),
                status: 'success' 
            }};

        case 'defaultState':
            return defaultState;

        default:
            throw new Error();
    }
}



export default function OrderCreateModal(props) {
    const { open, onClose, eventId, onOrderCreate } = props;

    const { createNotification } = useNotification();
    const event = useEvent();
    const timezone = event.time_zone;
    
    const [state, dispatch] = useReducer(reducer, defaultState);

    let modalTitle;
    if (state.page === 1) modalTitle = 'Create Order';
    if (state.page === 2) modalTitle = 'Review Order';
    if (state.page === 3) modalTitle = 'Share Order';


    const eventIdRef = useRef();

    useEffect(() => {
        // Event ID has changed. Reset state for this component.
        if (eventId !== eventIdRef.current) {
            dispatch({ type: 'defaultState' });
            eventIdRef.current = eventId;
        }
        
        // Load or reload products whenever the modal is opened (or event id changes)
        if (open === true) {

            Promise.all([
                fetchExpresso(`/apiv2/events/${eventId}/products`),
                fetchExpresso(`/apiv2/events/${eventId}/bundles`)
            ])
                .then(async result => {
                    if (result[0].status !== 200 || result[1].status !== 200) {
                        throw new Error();
                    }

                    let products = await result[0].json();
                    let bundles = await result[1].json();

                    products = products.map(i => {
                        let status = 'on_sale';
        
                        if (moment(i.sale_start_date).tz(timezone, true) > moment()) status = 'sale_not_started';
                        if (moment(i.sale_end_date).tz(timezone, true) < moment()) status = 'sale_concluded';
                        if (i.qty_left <= 0) status = 'sold_out';
                        if (i.visible === 0) status = 'hidden';

                        return {
                            id: i.product_id,
                            name: i.prod_name,
                            type: i.prod_type_id,
                            status: status,
                            quantity: 0
                        }
                    });

                    bundles = bundles.map(i => {
                        let status = 'on_sale';
        
                        if (moment(i.sale_start_date).tz(timezone, true) > moment()) status = 'sale_not_started';
                        if (moment(i.sale_end_date).tz(timezone, true) < moment()) status = 'sale_concluded';
                        if (i.qty_left <= 0) status = 'sold_out';
                        if (i.visible === 0) status = 'hidden';

                        return {
                            id: i.bundle_id,
                            name: i.bundle_name,
                            status: status,
                            quantity: 0
                        }
                    })

                    dispatch({ type: 'setCart', products, bundles });
                })
                .catch(() => dispatch({ type: 'setCartError' }))
        }
    }, [open, eventId, timezone]);



    const handleClose = () => {
        onClose();
        setTimeout(() => {
            dispatch({ type: 'defaultState' });
        }, 500);
    };


    const handleSubmit = () => {
        const { firstName, lastName, email, phone, company, address, city, province, postalCode, country } = state.customer;

        dispatch({ type: 'setOrderLoading', value: true });

        fetchExpresso('/apiv2/orders/', {
            method: 'POST',
            body: {
                eventId: eventId,
                customer: {
                    firstName: firstName.trim(),
                    lastName: lastName.trim(),
                    email: email.trim(),
                    phone: phone.trim() || null,
                    company: company.trim() || null,
                    address: address.trim() || null,
                    city: city.trim() || null,
                    province: province || null,
                    postalCode: postalCode.trim() || null,
                    country: country
                },
                products: state.cart.products.filter(i => i.quantity > 0),
                bundles: state.cart.bundles.filter(i => i.quantity > 0),
            }
        })
            .then(async res => {
                if (res.status !== 200) {
                    throw new Error();
                }

                const order = await res.json();

                dispatch({ type: 'setOrder', id: order.tran_id });

                onOrderCreate(); // Notifies <Order /> component that an order was created. Order list will refresh.
            })
            .catch(() => {
                createNotification('There was a problem creating your order');
                dispatch({ type: 'setOrderLoading', value: false });
            })
    };


    // No tickets are available. Orders cannot be created
    const ticketsNotAvailable = (state.cart.status === 'success' && state.cart.products.length === 0 && state.cart.bundles.length === 0);


    return (
        <FullScreenModal open={open} onClose={handleClose} title={modalTitle} cancelText='Cancel'>

            {ticketsNotAvailable ? (
                <ErrorPage message='No tickets found. Please ensure you have created at least one ticket before making an order.' />
            ) : (
                <Root p={[2,4]}>
                    {open === true && state.page === 1 && (
                        <OrderCreateForm
                            customer={state.customer}
                            cart={state.cart}
                            onCartProductUpdate={(id, value) => dispatch({ type: 'updateCartProducts', id, value })}
                            onCartBundleUpdate={(id, value) => dispatch({ type: 'updateCartBundles', id, value })}
                            onCustomerUpdate={(form) => dispatch({ type: 'updateCustomer', form })}
                            onSubmit={() => dispatch({ type: 'next' })}
                            onCancel={handleClose}
                        />
                    )}

                    {open === true && state.page === 2 && (
                        <OrderReview
                            customer={state.customer}
                            cart={state.cart}
                            loading={state.order.loading}
                            onCancel={() => dispatch({ type: 'back' })}
                            onSubmit={handleSubmit}
                        />
                    )}

                    {open === true && state.page === 3 && (
                        <PostOrderSummary
                            orderId={state.order.orderId}
                            customer={state.customer}
                            products={state.cart.products}
                            bundles={state.cart.bundles}
                            onClose={handleClose}
                            onRestart={() => dispatch({ type: 'restart' })}
                        />
                    )}
                </Root>
            )}

        </FullScreenModal>
    )
}


const Root = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey[100],
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
}));