import React, { useState } from 'react';

// Material UI
import { styled, useTheme } from '@material-ui/core/styles';
import { Box, CircularProgress, InputAdornment, FormControlLabel, Switch, Paper } from '@material-ui/core';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';

// Components
import HostPricingDetails from './HostPricingDetails';
import ConfirmAction from 'components/popups/ConfirmAction';

// Utility
import { useNotification } from 'context/notification';
import fetchExpresso from 'utility/fetchExpresso';
import useExpresso from 'hooks/useExpresso';
import { validateURL } from 'utility/validation';


export default function HostFormContainer(props) {
    if (props.hostId) {
        return <HostEditForm {...props} editMode={true} />
    }
    else {
        return <HostCreateForm {...props} editMode={false} />
    }
}


function HostCreateForm(props) {
    const { onUpdate, onCancel } = props;

    const [loading, setLoading] = useState(false);
    const { createNotification } = useNotification();

    const handleSubmit = (form) => {
        setLoading(true);

        fetchExpresso(`/apiv2/hosts/`, { method: 'POST', body: form })
            .then(res => {
                if (res.status === 200) {
                    createNotification('Created new host');
                    onUpdate();
                } else {
                    throw new Error();
                }
            })
            .catch(() => createNotification('There was a problem creating your host'))
            .finally(() => setLoading(false))
    };

    return (
        <HostForm
            editMode={false}
            loading={loading}
            onSubmit={handleSubmit}
            onCancel={onCancel}
        />
    )
}

function HostEditForm(props) {
    const { onUpdate, onCancel, hostId } = props;

    const [host, loadingHost, error]  = useExpresso(`/apiv2/hosts/${hostId}`, 'GET', null, null, []);

    const [loading, setLoading] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleteResults, setDeleteResults] = useState(null);

    const { createNotification } = useNotification();


    const handleSubmit = async (form) => {
        setLoading(true);

        fetchExpresso(`/apiv2/hosts/${hostId}`, { method: 'PUT', body: form })
            .then(res => {
                if (res.status === 200) {
                    createNotification('Host updated');
                    onUpdate();
                } else {
                    throw new Error();
                }
            })
            .catch(() => createNotification('There was a problem updating your host'))
            .finally(() => setLoading(false))
    };

    const handleDelete = () => {
        setConfirmDelete(false);
        setLoading(true);

        // Delet Host
        fetchExpresso(`/apiv2/hosts/${props.hostId}`, { method: 'DELETE' })
            .then(res => {
                if (res.status !== 200) throw new Error();
                return res.json();
            })
            .then(data => setDeleteResults(Object.entries(data)))
            .catch(() => createNotification('There was a problem deleting your host'))
            .finally(() => setLoading(false))
    }

    if (loadingHost) {
        return (
            <Box display='flex' flex={1} flexDirection='column' justifyContent='center' alignItems='center'>
                <CircularProgress />
            </Box>
        )
    }
    
    if (error) {
        return (
            <Box display='flex' flex={1} flexDirection='column' justifyContent='center' alignItems='center'>
                <p>Error loading host</p>
            </Box>
        )
    }

    if (host) {
        const { url_website, url_facebook, url_twitter, url_instagram, url_tiktok, connect_id } = host;

        return (
            <>
                <HostForm
                    hostId={hostId}
                    loading={loading}
                    editMode={true}
                    onSubmit={handleSubmit}
                    onDelete={handleDelete}
                    onCancel={onCancel}
                    name={host.host_name}
                    description={host.host_desc}
                    phone={host.contact_phone}
                    email={host.contact_email}
                    subDomain={host.sub_domain}
                    ccStatementDescriptor={host.cc_statement_descriptor}
                    website={url_website}
                    facebook={url_facebook}
                    twitter={url_twitter}
                    instagram={url_instagram}
                    tiktok={url_tiktok}
                    connectEnabled={Boolean(connect_id)}
                />
                <ConfirmAction
                    open={confirmDelete}
                    destructive
                    onCancel={() => setConfirmDelete(false)}
                    onConfirm={handleDelete}
                    confirmText='Delete'
                    title='Delete this host?'
                    description='This will delete all data associated with this host, including events, tickets, and purchase records'
                />

                {deleteResults !== null && (
                    <Dialog open onClose={onUpdate}>
                        <DialogTitle>Results</DialogTitle>
                        <DialogContent>
                            <Table>
                                <TableBody>
                                    {deleteResults.map(p => (
                                        <TableRow key={p[0]}>
                                            <TableCell>{p[0]}</TableCell>
                                            <TableCell>{p[1]}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </DialogContent>
                        <DialogActions>
                            <Button color='secondary' onClick={onUpdate}>OK</Button>
                        </DialogActions>
                    </Dialog>
                )}
            </>
        )
    }
}

function HostForm(props) {
    // Props
    const { onSubmit, onCancel, onDelete, hostId, editMode, loading } = props;

    // State - Form Values
    const [name, setName] = useState(props.name ||'');
    const [description, setDescription] = useState(props.description ||'');
    const [phone, setPhone] = useState(props.phone ||'');
    const [email, setEmail] = useState(props.email ||'');
    const [subDomain, setSubDomain] = useState(props.subDomain ||'');
    const [ccStatementDescriptor, setCcStatementDescriptor] = useState(props.ccStatementDescriptor ||'');
    const [website, setWebsite] = useState(props.website ||'');
    const [facebook, setFacebook] = useState(props.facebook ||'');
    const [twitter, setTwitter] = useState(props.twitter ||'');
    const [instagram, setInstagram] = useState(props.instagram ||'');
    const [tiktok, setTiktok] = useState(props.tiktok ||'');

    // Creates a stripe connect account for host by default
    const [connectEnabled, setConnectEnabled] = useState(props.connectEnabled ?? false);

    // State - Form Errors
    const [nameInvalid, setNameInvalid] = useState(false);
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [websiteInvalid, setWebsiteInvalid] = useState(false);
    const [facebookInvalid, setFacebookInvalid] = useState(false);
    const [twitterInvalid, setTwitterInvalid] = useState(false);
    const [instagramInvalid, setInstagramInvalid] = useState(false);
    const [tiktokInvalid, setTiktokInvalid] = useState(false);

    const theme = useTheme();


    function handleSubmit() {
        if (loading) return null;

        // Error Handling
        let formHasError = false;

        if (name.trim() === '') {
            setNameInvalid(true);
            formHasError = true;
        } else {
            setNameInvalid(false)
        }

        // only validate email if user provided one
        if (email.trim() !== '' && /.+@.+\..+/.test(email.trim()) === false) {
            setEmailInvalid(true);
            formHasError = true;
        } else {
            setEmailInvalid(false)
        }

        // Validate 
        if (validateURL(website) === false && website.trim() !== '') {
            setWebsiteInvalid(true);
            formHasError = true;
        } else {
            setWebsiteInvalid(false);
        }
        if (validateURL(facebook) === false && facebook.trim() !== '') {
            setFacebookInvalid(true);
            formHasError = true;
        } else {
            setFacebookInvalid(false);
        }
        if (validateURL(twitter) === false && twitter.trim() !== '') {
            setTwitterInvalid(true);
            formHasError = true;
        } else {
            setTwitterInvalid(false);
        }
        if (validateURL(instagram) === false && instagram.trim() !== '') {
            setInstagramInvalid(true);
            formHasError = true;
        } else {
            setInstagramInvalid(false);
        }
        if (validateURL(tiktok) === false && tiktok.trim() !== '') {
            setTiktokInvalid(true);
            formHasError = true;
        } else {
            setTiktokInvalid(false);
        }

        if (formHasError) {
            return null;
        }

        onSubmit({
            name,
            description,
            phone,
            email,
            subDomain,
            ccStatementDescriptor,
            website: website.trim() || '',
            facebook: facebook.trim() || '',
            twitter: twitter.trim() || '',
            instagram: instagram.trim() || '',
            tiktok: tiktok.trim() || '',
            connectEnabled: connectEnabled
        });
    }
    

    return (
        <Box width={1} display='flex' flexDirection='column' alignItems='center'>
            <Box width={[0.9, 0.9, theme.breakpoints.values.md - 60]} my={6} display='flex' justifyContent={editMode ? 'space-between' : 'center'} flexWrap='wrap'>

                <Box width={[1, 1, 0.6]} display='flex' flexDirection='column'>

                    <FormContainer>

                    <TextField
                        required
                        variant='outlined'
                        margin='normal'
                        label='Host Name'
                        value={name}
                        error={nameInvalid}
                        onChange={e => setName(e.target.value)}
                    />

                    <TextField
                        variant='outlined'
                        margin='normal'
                        label='Description'
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                    />

                    <TextField
                        variant='outlined'
                        margin='normal'
                        label='Phone'
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                    />

                    <TextField
                        variant='outlined'
                        margin='normal'
                        label='Email'
                        value={email}
                        error={emailInvalid}
                        onChange={e => setEmail(e.target.value.replace(/ /g, ''))}
                    />

                    <TextField
                        variant='outlined'
                        margin='normal'
                        label='Sub Domain'
                        value={subDomain}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">.frontdoor.plus</InputAdornment>,
                        }}
                        onChange={e => setSubDomain(e.target.value.replace(/ /g, ''))}
                    />

                    <TextField
                        variant='outlined'
                        margin='normal'
                        label='CC Statement Descriptor'
                        value={ccStatementDescriptor}
                        onChange={e => setCcStatementDescriptor(e.target.value)}
                    />

                    <TextField
                        type='url'
                        variant='outlined'
                        margin='normal'
                        label='Website URL'
                        placeholder='https://example.com'
                        error={websiteInvalid}
                        helperText={websiteInvalid ? 'Make sure your link is properly formatted. Example: https://example.com ' : undefined}
                        value={website}
                        onChange={e => setWebsite(e.target.value.replace(/ /g, ''))}
                    />

                    <TextField
                        type='url'
                        variant='outlined'
                        margin='normal'
                        label='Facebook URL'
                        placeholder='https://facebook.com/example'
                        error={facebookInvalid}
                        helperText={facebookInvalid ? 'Make sure your link is properly formatted. Example: https://facebook.com/example' : undefined}
                        value={facebook}
                        onChange={e => setFacebook(e.target.value.replace(/ /g, ''))}
                    />

                    <TextField
                        type='url'
                        variant='outlined'
                        margin='normal'
                        label='Twitter URL'
                        placeholder='https://twitter.com/example'
                        error={twitterInvalid}
                        helperText={twitterInvalid ? 'Make sure your link is properly formatted. Example: https://twitter.com/example' : undefined}
                        value={twitter}
                        onChange={e => setTwitter(e.target.value.replace(/ /g, ''))}
                    />

                    <TextField
                        type='url'
                        variant='outlined'
                        margin='normal'
                        label='Instagram URL'
                        placeholder='https://instagram.com/example'
                        error={instagramInvalid}
                        helperText={instagramInvalid ? 'Make sure your link is properly formatted. Example: https://instagram.com/example' : undefined}
                        value={instagram}
                        onChange={e => setInstagram(e.target.value.replace(/ /g, ''))}
                    />

                    <TextField
                        type='url'
                        variant='outlined'
                        margin='normal'
                        label='Tiktok URL'
                        placeholder='https://www.tiktok.com/@example'
                        error={tiktokInvalid}
                        helperText={tiktokInvalid ? 'Make sure your link is properly formatted. Example: https://www.tiktok.com/@example' : undefined}
                        value={tiktok}
                        onChange={e => setTiktok(e.target.value.replace(/ /g, ''))}
                    />

                    <Box mt={2} mb={4}>
                        <FormControlLabel
                            control={<Switch checked={connectEnabled} onChange={(e) => setConnectEnabled(e.target.checked)} />}
                            label="Enable Stripe Connect"
                            // Don't allow Stripe Connect to be turned off if it is already enabled
                            disabled={editMode && props.connectEnabled === true}
                        />
                    </Box>

                    <Box display='flex' justifyContent='flex-end' mb={4}>
                        {(editMode && process.env.REACT_APP_ENV === 'development') && (
                            <DeleteButton variant='outlined' onClick={onDelete} disabled={loading}>Delete</DeleteButton>
                        )}
                        <CancelButton variant='outlined' onClick={onCancel} disabled={loading}>Cancel</CancelButton>
                        <SaveButton variant='outlined' onClick={handleSubmit} disabled={loading}>Save</SaveButton>
                    </Box>

                    </FormContainer>

                </Box>

                {editMode && (
                    <Box width={[1, 1, 0.3]} mt={1}>
                        <Card>
                            <CardHeader title='Fee Breakdown' />
                            <CardContent>
                                <HostPricingDetails hostId={hostId} />
                            </CardContent>
                        </Card>
                    </Box>
                )}

            </Box>
        </Box>
    )
}

const FormContainer = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
}));

const SaveButton = styled(Button)(({ theme }) => ({
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main
}));

const CancelButton = styled(Button)({
    marginRight: '1rem'
});

const DeleteButton = styled(Button)(({ theme }) => ({
    marginRight: 'auto',
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
}));