import React, { useState, useCallback } from 'react'
import { styled } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import Cropper from 'react-easy-crop';


export default function ImageCropper(props) {
    const { image, aspect, onSave, onClose } = props;

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);


    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, []);

    const handleCropSave = async () => {
        const croppedImage = await getCroppedImg(image, croppedAreaPixels);
        onSave(croppedImage);
    };


    return (
        <Root>

            <CropContainer>

                <Cropper
                    image={image}
                    crop={crop}
                    aspect={aspect || 1/1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                />

            </CropContainer>

            <ButtonContainer>
            
                <CancelButton variant='outlined' onClick={onClose}>Cancel</CancelButton>

                <SaveButton color='secondary' variant='contained' onClick={handleCropSave}>Save</SaveButton>

            </ButtonContainer>
            
            
        </Root>
    )
}


const Root = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
});

const CropContainer = styled(Box)({
    display: 'flex',
    position: 'relative',
    width: '100%',
    background: '#333',
    flex: 4
});

const ButtonContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flex: 1
});

const SaveButton = styled(Button)({
    margin: '16px',
    width: '100px'
});

const CancelButton = styled(Button)(({ theme }) => ({
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    margin: '16px',
    width: '100px'
}));


const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', (error) => reject(error))
        image.src = url
    })

async function getCroppedImg(imageSrc, pixelCrop) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
  
    if (!ctx) {
      return null
    }
  
    // set canvas size to match the bounding box
    canvas.width = image.width
    canvas.height = image.height

    ctx.clearRect(0, 0, image.width, image.height);
    ctx.fillStyle = 'white';
    ctx.fillRect(0,0, image.width, image.height);
  
    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(image.width / 2, image.height / 2)
    ctx.translate(-image.width / 2, -image.height / 2)
  
    // draw rotated image
    ctx.drawImage(image, 0, 0)
  
    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height
    )
  
    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height
  
    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0)
  
    // As Base64 string
    return canvas.toDataURL('image/jpeg', 0.9);
  }