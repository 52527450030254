import React, { useState } from 'react';

// Material UI
import { styled, Box, Button, TextField, Typography, Paper } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';

// Components
import FullScreenModal from 'components/popups/FullScreenModal';
import { ActionButton } from 'components/ui/buttons';

//Utility
import { useAuth } from 'context/auth';
import { useNotification } from 'context/notification';
import useExpresso from 'hooks/useExpresso';
import fetchExpresso from 'utility/fetchExpresso';
import ReactGA from 'react-ga';


export default function Profile() {
    const { auth } = useAuth();
    const { createNotification } = useNotification();

    // Hooks
    const [userInfo] = useExpresso(`/api/users/get_user`, 'POST', null, { userId: auth.userId }, [auth.userId]);
    const [modalStatus, setModalStatus] = useState(false)

    const [passwordState, setPasswordState] = useState({
        oldPassword: '',
        newPassword1: '',
        newPassword2: ''
    })

    const [errorMessage, setErrorMessage] = useState(null)

    const hostId = auth.hostId;

    function updatePassword() {
        if (passwordState.newPassword1.length < 8) {
            setErrorMessage('New password must be at least eight characters long')
            return
        }

        if (passwordState.newPassword1 === passwordState.newPassword2) {

            fetchExpresso(`/api/auths/update_password`, {
                method: 'POST',
                body: {
                    userId: auth.userId,
                    oldPassword: passwordState.oldPassword,
                    newPassword: passwordState.newPassword1
                }
            })
                .then(res => res.json())
                .then(data => {
                    if (data.err === false) {
                        setPasswordState({
                            oldPassword: '',
                            newPassword1: '',
                            newPassword2: ''
                        })
                        createNotification(data.message);
                        setModalStatus(false)

                        ReactGA.event({ category: "Profile", action: 'Update Password Complete', label: `${hostId}` });
                    } else {
                        setErrorMessage(data.message)
                    }
                })
                .catch(() => {
                    setErrorMessage('There was a problem updating your password')
                });
        } else {
            setErrorMessage('Two new passwords must match')
        }

    }

    function onModalClose() {
        setModalStatus(false)
    }

    function openModal() {
        setModalStatus(true)
        ReactGA.event({ category: "Profile", action: 'Update Password', label: `${hostId}` });
    }

    function onPasswordChange(e) {
        const value = e.target.value;
        setPasswordState({ ...passwordState, [e.target.id]: value });
        setErrorMessage(null);
    }

    return (
        <Card>
            <CardHeader title='My Login' />

            <CardContent>

                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography>Name:</Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography>{!userInfo ? '' : userInfo.rows[0].name}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography>Email:</Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography>{!userInfo ? '' : userInfo.rows[0].email}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography>Password:</Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <ActionButton onClick={openModal}>Change Password</ActionButton>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

            </CardContent>


            <FullScreenModal open={modalStatus} onClose={onModalClose} title={'Update Password'}>

                <Box display='flex' justifyContent='center' width='100%'>

                    <FormContainer>

                        <TextInput required type="password" variant="outlined" id="oldPassword" value={passwordState.oldPassword} onChange={onPasswordChange} label="Old Password" />
                        <TextInput required type="password" variant="outlined" id="newPassword1" value={passwordState.newPassword1} onChange={onPasswordChange} label="New Password" />
                        <TextInput required type="password" variant="outlined" id="newPassword2" value={passwordState.newPassword2} onChange={onPasswordChange} label="Confirm New Password" />

                        {errorMessage && <Typography color='error'>{errorMessage}</Typography>}

                        <Box display='flex' justifyContent='flex-end'>
                            <CancelButton variant="outlined" onClick={onModalClose}>Cancel</CancelButton>
                            <UpdateButton variant="outlined" onClick={updatePassword}>Update Password</UpdateButton>
                        </Box>


                    </FormContainer>

                </Box>

            </FullScreenModal>

        </Card>
    )
}


const FormContainer = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: theme.spacing(4),
    maxWidth: theme.breakpoints.values.sm,
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
    }
}));

const TextInput = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(2)
}));

const CancelButton = styled(Button)(({ theme }) => ({
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
}));

const UpdateButton = styled(Button)(({ theme }) => ({
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    marginLeft: theme.spacing(2)
}));
