import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Material UI
import { styled, Toolbar, Box, Drawer } from '@material-ui/core';
import { List, ListItem, ListItemIcon, ListItemText, Divider as _Divider } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@material-ui/icons/Person';
import GetAppIcon from '@material-ui/icons/GetApp';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import EventIcon from '@material-ui/icons/Event';
import SearchIcon from '@material-ui/icons/Search';


/**
 * Desktop Only
 * See AdminTabs.js for the mobile version of this menu
 */

export default function AdminDrawer() {
    const history = useHistory();

    const dashboard  = useRouteMatch({ path: '/admin/', exact: true })
    const events     = useRouteMatch({ path: '/admin/events' })
    const ops        = useRouteMatch({ path: '/admin/operations' })
    const orders     = useRouteMatch({ path: '/admin/orders' })
    const hosts      = useRouteMatch({ path: '/admin/hosts' })
    const users      = useRouteMatch({ path: '/admin/users' })
    const reports    = useRouteMatch({ path: '/admin/reports' })
    const calculator = useRouteMatch({ path: '/admin/calculator' })    

    return (
        <Box displayPrint='none'>

            <DesktopDrawer variant="permanent">

                <Toolbar />

                <DrawerContainer>

                    <List>

                        <MenuItem
                            title='Dashboard'
                            icon={<DashboardIcon />}
                            active={Boolean(dashboard)}
                            disabled={Boolean(dashboard?.isExact)}
                            onClick={() => history.push('/admin/')}
                        />

                        <MenuItem
                            title='Ops'
                            icon={<EventIcon />}
                            active={Boolean(ops)}
                            disabled={Boolean(ops?.isExact)}
                            onClick={() => history.push('/admin/operations')}
                        />

                        <Divider />

                        <MenuItem
                            title='Hosts'
                            icon={<BusinessIcon />}
                            active={Boolean(hosts)}
                            disabled={Boolean(hosts?.isExact)}
                            onClick={() => history.push('/admin/hosts')}
                        />

                        <MenuItem
                            title='Events'
                            icon={<EventIcon />}
                            active={Boolean(events)}
                            disabled={Boolean(events?.isExact)}
                            onClick={() => history.push('/admin/events')}
                        />

                        <MenuItem
                            title='Users'
                            icon={<PersonIcon />}
                            active={Boolean(users)}
                            disabled={Boolean(users?.isExact)}
                            onClick={() => history.push('/admin/users')}
                        />

                        <Divider />

                        <MenuItem
                            title='Order Lookup'
                            icon={<SearchIcon />}
                            active={Boolean(orders)}
                            disabled={Boolean(orders?.isExact)}
                            onClick={() => history.push('/admin/orders')}
                        />

                        <MenuItem
                            title='Reports'
                            icon={<GetAppIcon />}
                            active={Boolean(reports)}
                            disabled={Boolean(reports?.isExact)}
                            onClick={() => history.push('/admin/reports')}
                        />
                    

                        <MenuItem
                            title='Calculator'
                            icon={<LocalAtmIcon />}
                            active={Boolean(calculator)}
                            disabled={Boolean(calculator?.isExact)}
                            onClick={() => history.push('/admin/calculator')}
                        />

                    </List>

                </DrawerContainer>

            </DesktopDrawer>

        </Box>
    )
}


/**
 * Menu Item Props:
 *  active: The menu item is highlighted
 *  disabled: The menu item can't be clicked on
 */

function MenuItem(props) {

    if (props.active) {
        return (
            <ListButton button onClick={props.disabled ? undefined : props.onClick}>
                <ListItemIconActive>{props.icon}</ListItemIconActive>
                <ListItemTextActive primary={props.title} />
            </ListButton>
        )
    } else {
        return (
            <ListButton button onClick={props.onClick}>
                <ListItemIconInactive>{props.icon}</ListItemIconInactive>
                <ListItemTextInactive primary={props.title} />
            </ListButton>
        )
    }
}


const DrawerContainer = styled('div')(({ theme }) => ({
    overflow: 'auto',
    backgroundColor: theme.palette.background.light,
    height: '100%'
}));

const ListButton = styled(ListItem)(({ theme }) => ({
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(8),
    '&:hover': {
        backgroundColor: theme.palette.grey[700]
    }
}));

const ListItemIconActive = styled(ListItemIcon)(({ theme }) => ({
    color: theme.palette.primary.main
}));

const ListItemTextActive = styled(ListItemText)(({ theme }) => ({
    color: theme.palette.primary.main
}));

const ListItemIconInactive = styled(ListItemIcon)(({ theme }) => ({
    color: theme.palette.common.white
}));

const ListItemTextInactive = styled(ListItemText)(({ theme }) => ({
    color: theme.palette.common.white
}));

const DesktopDrawer = styled(Drawer)(({ theme }) => ({
    width: 250,
    flexShrink: 0
}));

const Divider = styled(_Divider)(({ theme }) => ({
    backgroundColor: theme.palette.grey[100],
}));