import React from 'react';

// Material-UI
import { styled } from '@material-ui/core/styles';
import { Button, Paper, CircularProgress } from '@material-ui/core';



export default function FormSubmitBar(props) {
    const { unsavedChanges, loading, onCancel, onSubmit, disabled } = props;

    return (
        <EditModeActions elevation={24}>
            {unsavedChanges ? (
                <>
                    {loading ? (
                        <LoadingSpinner color='secondary' size={34} />
                    ) : (
                        <>
                            <EditActionButton variant='contained' disabled={disabled} onClick={onCancel}>Cancel</EditActionButton>
                            <SaveActionButton variant='contained' disabled={disabled} onClick={onSubmit}>Save Changes</SaveActionButton>
                        </>
                    )}
                </>
            ) : (
                <>
                    <EditActionButtonDisabled variant='contained' disabled={disabled}>Cancel</EditActionButtonDisabled>
                    <SaveActionButtonDisabled variant='contained' disabled={disabled}>Save Changes</SaveActionButtonDisabled>
                </>
            )}
        </EditModeActions>
    )
}



const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
    marginLeft: theme.spacing(2),
}));

const EditActionButton = styled(Button)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
}));

const SaveActionButton = styled(Button)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
    color: theme.palette.getContrastText(theme.palette.success.main)
}));


const EditActionButtonDisabled = styled(Button)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.grey[700],
    '&:hover': {
        cursor: 'initial',
        backgroundColor: theme.palette.grey[400],
    }
}));
EditActionButtonDisabled.defaultProps = { disableRipple: true }

const SaveActionButtonDisabled = styled(Button)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.grey[700],
    '&:hover': {
        cursor: 'initial',
        backgroundColor: theme.palette.grey[400],
    }
}));
SaveActionButtonDisabled.defaultProps = { disableRipple: true }

const EditModeActions = styled(Paper)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 'auto',
    left: 230,
    bottom: 0,
    right: 0,
    height: theme.spacing(7),
    backgroundColor: theme.palette.background.light,
    borderRadius: 0,
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
        left: 0,
        right: 0
    }
}));

