import React, { useState, useEffect } from 'react';
import { styled, Box, Button, Typography, Grid, Card, CardContent, ButtonGroup, CircularProgress } from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Schedule';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import StoreIcon from '@material-ui/icons/Store';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import DoneIcon from '@material-ui/icons/Done';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { Alert } from '@material-ui/lab';

// Components
import { Page, ControlBar, ControlBarHeader } from 'components/ui/layout';
import { AlertButton } from 'components/ui/alerts';
import ConfirmAction from 'components/popups/ConfirmAction';
import FullScreenModal from 'components/popups/FullScreenModal';
import BundleEditModal from 'components/bundles/BundleEditModal';
import ProductEditor from 'components/forms/ProductEditor';
import Tooltip from 'components/popups/Tooltip';

// Utility
import fetchExpresso from 'utility/fetchExpresso';
import moment from 'moment-timezone';
import { formatPrice } from 'utility/numbers';
import { useNotification } from 'context/notification';
import { useEvent, useEventDispatch } from 'context/event';
import { useAuth } from 'context/auth';
import { ConnectedAccount } from 'stripe';


export default function Publish(props) {
    const eventId = props.match.params.id;
    const { auth } = useAuth();
    const { createNotification } = useNotification();
    const dispatch = useEventDispatch();
    const eventDetails = useEvent();


    const [state, setState] = useState({
        status: 'loading',  // loading, error, success
        event: null,        // null, object
        publishStatus: null, // publish, unlisted, draft
        connect: null, // host must be able to accept payments before they can publish their event to the storefront
        updatingStatus: false, // set true when updating live/unlisted flags
        confirmDelete: false, // true: show warning about deleting events
        deletingEvent: false, // true: event is currently being deleted
        confirmDraft: false, // true: show warning about setting event to draft mode
    });

    const { event, publishStatus, connect, updatingStatus, confirmDelete, deletingEvent, confirmDraft } = state;


    const showStoreURL = Boolean(eventDetails?.live_flag === 1);


    useEffect(() => {
        (async () => {
            const res = await Promise.all([
                fetchExpresso(`/apiv2/hosts/${auth.hostId}`),
                fetchExpresso(`/apiv2/events/${eventId}`)
            ]);

            if (res[0].status !== 200 || res[1].status !== 200) {
                throw new Error();
            }

            const [host, event] = await Promise.all([
                res[0].json(),
                res[1].json()
            ])

            const connect = new ConnectedAccount(null, host.connect_status);

            let publishStatus = 'draft';

            if (event.live_flag) {
                event.unlisted_flag
                    ? publishStatus = 'unlisted'
                    : publishStatus = 'published'
            }

            setState(s => ({ ...s, status: 'success', event, publishStatus, connect }));
        })()
    }, [eventId, auth.hostId]);


    const updatePublishStatus = async (value) => {
        if (updatingStatus === true) return;
        if (deletingEvent === true) return;

        const currentValue = publishStatus;
        const nextValue = value;

        try {
            setState({ ...state, publishStatus: nextValue, updatingStatus: true, confirmDraft: false });
    
            let liveFlag;
            let unlistedFlag;
            let successMessage;
    
            switch (nextValue) {
                case 'published':
                    liveFlag = 1;
                    unlistedFlag = 0;
                    successMessage = 'Your event is now published';
                    break;
                case 'unlisted':
                    liveFlag = 1;
                    unlistedFlag = 1;
                    successMessage = 'Your event is now unlisted';
                    break;
                case 'draft':
                    liveFlag = 0;
                    unlistedFlag = 0;
                    successMessage = 'Your event is in draft mode';
                    break;
                default:
                    throw new Error();
            }
    
            const res = await fetchExpresso(`/apiv2/events/${eventId}`, {
                method: 'PUT',
                body: { liveFlag, unlistedFlag }
            });

            if (res.status !== 200) throw new Error();

            createNotification(successMessage);
            dispatch({ type: 'set_event', event: { live_flag: liveFlag, unlisted_flag: unlistedFlag } });
            setState((oldState) => ({ ...oldState, updatingStatus: false }));
        }
        catch(e) {
            setState((oldState) => ({ ...oldState, publishStatus: currentValue, updatingStatus: false }));
            createNotification('Unable to update your event');
        }
    };


    const handleDelete = () => {
        setState({ ...state, confirmDelete: false, deletingEvent: true })

        fetchExpresso(`/apiv2/events/${eventId}`, {
            method: 'DELETE'
        })
            .then(async (res) => {
                if (res.status === 200) {
                    createNotification('Deleted Event');
                    props.history.push('/events');

                } else {
                    const data = await res.json();
                    createNotification(data.message ?? 'There was a problem deleting your event');
                    setState((oldState) => ({ ...oldState, deletingEvent:false }))
                }
            })
            .catch(() => {
                createNotification('There was a problem deleting your event');
                setState((oldState) => ({ ...oldState, deletingEvent:false }))
            })
    }


    // Generate Storefront Link
    const storefrontLink = `${process.env.REACT_APP_STORE_URL}/event/${eventId}`;

    const copyLinkToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(storefrontLink);
            createNotification('Copied to clipboard!')
        } catch(e) {
            createNotification('Unable to copy to clipboard')
        }
    }


    if (state.status === 'loading') {
        return (
            <Page>
                <ControlBar>
                    <ControlBarHeader>Review & Publish</ControlBarHeader>
                </ControlBar>

                <Box display='flex' justifyContent='center' alignItems='center' height={72}>
                    <CircularProgress />
                </Box>
            </Page>
        )
    }

    if (state.status === 'error') {
        return (
            <Page>
                <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' mt={12}>
                    <Typography variant='h5'>There was a problem finding your event</Typography>
                    <Box height={32} />
                    <Button variant='outlined' onClick={() => props.history.push('/events')}>Back to Events</Button>
                </Box>
            </Page>
        )
    }

    const startDate = moment(event.start_date).format('MMM D, YYYY');
    const startTime = moment(event.start_date).format('h:mm A');
    
    const endDate = moment(event.end_date).format('MMM D, YYYY');
    const endTime = moment(event.end_date).format('h:mm A');

    let dateString1 = '';
    let dateString2 = '';

    if (startDate !== endDate) {
        // Multi-day event
        dateString1 = `From: ${startDate} ${startTime}`;
        dateString2 = `To: ${endDate} ${endTime}`;
    } else {
        // Single-day event
        dateString1 = startDate;
        dateString2 = `${startTime} - ${endTime}`;
    }


    const address1 = event.address1;
    const address2 = `${event.address2} - ${event.city}, ${event.prov_state} ${event.postal_code}`;

    return (
        <Page>

            <ControlBar>
                <ControlBarHeader>Review & Publish</ControlBarHeader>
            </ControlBar>

            {connect.chargesEnabled === false && (
                <Box mb={2}>
                    <Alert severity='warning' variant='filled' action={<AlertButton severity='warning' onClick={() => props.history.push(`/settings`)}>Connect</AlertButton>}>
                        Please complete your FrontDoor+ Payments onboarding before publishing an event
                    </Alert>
                </Box>
            )}

            <Alert severity='info'>
                <AlertText>Review and finalize your event details here. Choose from three options: </AlertText>
                <AlertTextBold>[1] Published</AlertTextBold>
                <AlertText> - make your event visible on the Upcoming Events listing, </AlertText>
                <AlertTextBold>[2] Unlisted</AlertTextBold>
                <AlertText> - publish the event without listing it publicly, or </AlertText>
                <AlertTextBold>[3] Draft</AlertTextBold>
                <AlertText> - keep the event offline and unavailable for ticket sales.</AlertText>
            </Alert>            

            <MetaDataContainer>

                <ImageContainer>
                    <Image src={event.image_url} alt='' />
                </ImageContainer>

                <Box>
                    <Title variant='h6'>{event.event_title}</Title>

                    <Box display={'flex'} alignItems='center' mb='24px'>
                        <ScheduleIcon fontSize='large' />
                        <Box>
                            <IconLabel variant='body1'>{dateString1}</IconLabel>
                            <IconLabel variant='body1'>{dateString2}</IconLabel>
                        </Box>
                    </Box>

                    <Box display={'flex'} alignItems='center' mb='24px'>
                        <LocationOnIcon fontSize='large' />
                        <Box>
                            <IconLabel variant='body1'>{address1}</IconLabel>
                            <IconLabel variant='body1'>{address2}</IconLabel>
                        </Box>
                    </Box>

                </Box>

            </MetaDataContainer>


            <ProductPreview
                hostId={auth.hostId}
                eventId={eventId}
                history={props.history}
                timezone={eventDetails.time_zone}
            />


            <Typography variant='h5' gutterBottom>Publish Your Event</Typography>

            {connect.chargesEnabled === false && (
                <Typography>You will be unable to publish your event until you have setup your Stripe account.</Typography>
            )}

            <Box mt={3} mb={8}>
                <ButtonGroup color='secondary' aria-label="change event status">

                    {publishStatus === 'published'
                        ? <ButtonActive startIcon={(publishStatus === 'published' && updatingStatus) ? <Loading /> : undefined}>published</ButtonActive>
                        : <ButtonInactive disabled={!connect.chargesEnabled} onClick={() => updatePublishStatus('published')}>published</ButtonInactive>
                    }
                    {publishStatus === 'unlisted'
                        ? <ButtonActive startIcon={(publishStatus === 'unlisted' && updatingStatus) ? <Loading /> : undefined}>unlisted</ButtonActive>
                        : <ButtonInactive onClick={() => updatePublishStatus('unlisted')}>unlisted</ButtonInactive>
                    }
                    {publishStatus === 'draft'
                        ? <ButtonActive startIcon={(publishStatus === 'draft' && updatingStatus) ? <Loading /> : undefined}>draft</ButtonActive>
                        : <ButtonInactive onClick={() => setState({ ...state, confirmDraft: true })}>draft</ButtonInactive>
                    }
                </ButtonGroup>

                <Box mt={2}>
                    <Box mb={1}>
                        <SuccessText display='inline'>Published</SuccessText>
                        <Typography display='inline'>{' '}- Your event is open to the public and listed on the {' '}</Typography>
                        <InlineLink href={process.env.REACT_APP_STORE_URL} target="_blank" rel='noopener noreferrer'>FrontDoor+ website</InlineLink>
                    </Box>

                    <Box mb={1}>
                        <InfoText display='inline'>Unlisted</InfoText>
                        <Typography display='inline'>{' '}- Anyone with the{' '}</Typography>
                        <InlineLink href={storefrontLink} target="_blank" rel='noopener noreferrer'>event link</InlineLink>
                        <Typography display='inline'>{' '}can see your event. Your event will not be listed on the FD+ website</Typography>
                    </Box>

                    <Box mb={1}>
                        <WarningText display='inline'>Draft</WarningText>
                        <Typography display='inline'>{' '}- Your event will not be available to the public</Typography>
                    </Box>
                </Box>
            </Box>


            {(connect.chargesEnabled && showStoreURL) && (
                <>
                    <Typography variant='h5'>Share Your Event</Typography>

                    <Box mt={3} mb={8}>
                        <Typography gutterBottom>Your event is live! Customers can purchase tickets by visiting the following link:</Typography>
                        <Link href={storefrontLink} target="_blank" rel='noopener noreferrer'>{storefrontLink}</Link>
                        <Box mt={2}>
                            <Button variant='outlined' color='secondary' onClick={copyLinkToClipboard} startIcon={<FileCopyIcon />}>Copy Link</Button>
                        </Box>
                    </Box>
                </>
            )}

            <Typography variant='h5'>Delete Your Event</Typography>

            <Box mt={3} mb={8}>
                <Typography style={{ marginBottom: 12 }}>Warning: You cannot recover an event once it has been deleted.</Typography>
                <DeleteButton variant='outlined' onClick={() => setState({ ...state, confirmDelete: true })}>Delete Event</DeleteButton>
            </Box>

            <ConfirmAction
                open={confirmDelete} onConfirm={handleDelete} onCancel={() => setState({ ...state, confirmDelete: false })}
                destructive
                title='Delete your event?'
                description='This cannot be undone. If you want to hide your event, you can set it to "draft" mode above.'
                confirmText='Delete'
            />

            <ConfirmAction
                open={confirmDraft} onConfirm={() => updatePublishStatus('draft')} onCancel={() => setState({ ...state, confirmDraft: false })}
                title='Set to draft mode?'
                description="Your event will be hidden from customers, and you'll be unable to check-in guests via the FrontDoor+ app. You can publish your event again at any time."
            />

        </Page>
    )
}


function ProductPreview(props) {
    const { hostId, eventId, history, timezone } = props;

    const [state, setState] = useState({
        status: 'loading', // loading, error, success
        refresh: 0, // increment to reload products
        products: null,
        bundles: null,
        selectedProduct: null,
        selectedBundle: null
    });

    const { status, products, bundles, selectedProduct, selectedBundle, refresh } = state;

    // Load/Reload list of products
    useEffect(() => {
        Promise.all([
            fetchExpresso(`/apiv2/events/${eventId}/products`),
            fetchExpresso(`/apiv2/events/${eventId}/bundles`),
        ])
            .then(async res => {
                if (res[0].status !== 200 || res[1].status !== 200) {
                    throw new Error();
                }

                const [products, bundles] = await Promise.all([
                    res[0].json(),
                    res[1].json()
                ]);

                setState((state) => ({ ...state, products, bundles, status: 'success' }))
            })
            .catch(() => {
                setState((state) => ({ ...state, status: 'error' }))
            })
    }, [eventId, refresh]);

    const handleModalClose = () => setState(s => ({ ...s, selectedProduct: null, selectedBundle: null }));
    const handleUpdate = () => setState(s => ({ ...s, selectedProduct: null, selectedBundle: null, refresh: s.refresh + 1 }));

    return (
        <>
            <Typography variant='h5' gutterBottom>Review Your Tickets</Typography>

            <Box mt={3} mb={8}>
                {status === 'success' && (
                    <Box mb={2}>
                        <Grid container spacing={4}>
                            {bundles.map(b => (
                                <Grid key={b.bundle_id} item xs={12} md={6} xl={4}>
                                    <Product
                                        onClick={() => setState(s => ({ ...s, selectedBundle: b.bundle_id }))}
                                        name={b.bundle_name}
                                        price={b.price}
                                        visible={b.visible}
                                        saleStart={b.sale_start_date}
                                        saleEnd={b.sale_end_date}
                                        timezone={timezone}
                                        qtyLeft={b.qty_left}
                                        accessCode={b.access_code}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}
                {status === 'success' && (
                    <Grid container spacing={4}>
                        {products.map(p => (
                            <Grid key={p.product_id} item xs={12} md={6} xl={4}>
                                <Product
                                    onClick={() => setState(s => ({ ...s, selectedProduct: p.product_id }))}
                                    name={p.prod_name}
                                    price={p.price}
                                    visible={p.visible}
                                    saleStart={p.sale_start_date}
                                    saleEnd={p.sale_end_date}
                                    scanStart={p.scan_start_date}
                                    scanEnd={p.scan_end_date}
                                    timezone={timezone}
                                    accessCode={p.access_code}
                                    multiEntry={p.multi_day_entry}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
                {status === 'success' && products.length === 0 && (
                    <>
                        <Typography style={{ marginBottom: 12, marginTop: 16 }}>Your event has no tickets. You may publish your event, but your customers will have no tickets to purchase.</Typography>
                        <Button variant='outlined' color='secondary' onClick={() => history.push(`/events/${eventId}/tickets`)}>Create Tickets</Button>
                    </>
                )}
                {status === 'error' && (
                    <Typography>Unable to load tickets.</Typography>
                )}
                {status === 'loading' && (
                    <CircularProgress />
                )}
            </Box>

            <FullScreenModal open={selectedProduct !== null} onClose={handleModalClose} title={'Edit Ticket'} cancelText='Cancel'>
                {selectedProduct !== null && (
                    <ProductEditor
                        hostId={hostId}
                        eventId={eventId}
                        productId={selectedProduct}
                        onCancel={handleModalClose}
                        onEdit={handleUpdate}
                        onDelete={handleUpdate}
                    />
                )}
            </FullScreenModal>

            <BundleEditModal
                open={selectedBundle !== null}
                eventId={eventId}
                bundleId={selectedBundle}
                onUpdate={handleUpdate}
                onClose={handleModalClose}
            />
        </>
    )
}


function Product(props) {
    const { onClick, name, price, visible, timezone, accessCode, multiEntry } = props;
    const saleStart = moment(props.saleStart).tz(timezone, true);
    const saleEnd = moment(props.saleEnd).tz(timezone, true);
    const scanStart = props.scanStart ? moment(props.scanStart) : null;
    const scanEnd = props.scanEnd ? moment(props.scanEnd) : null;
    

    // *** Sale Duration *** //

    const saleStarted = saleStart < moment();
    const saleEnded = saleEnd < moment();


    // *** Audience *** //

    let audience = visible === 1
        ? 'Available to Everyone'
        : 'Not Available to Anyone';

    if (accessCode) audience = 'Access Code Required'


    // *** Check-in Type *** //

    let checkinType = null;

    if (typeof multiEntry === 'number') {
        checkinType = multiEntry === 1 ? 'Multi-Day Scan' : 'Single Scan';
    }


    // *** Check-in Window *** //

    let checkinWindow = null;

    if (scanStart && scanEnd) {
        if (scanStart.format('MMM D, YYYY') === scanEnd.format('MMM D, YYYY')) {
            // Single day scanning window
            checkinWindow = `${scanStart.format('ddd MMM D, h:mma')} - ${scanEnd.format('h:mma')}`;
        } else {
            // Multi day scanning window
            checkinWindow = `${scanStart.format('ddd MMM D, h:mma')} - ${scanEnd.format('ddd MMM D, h:mma')}`;
        }
    }



    return (
        <ProductCard onClick={onClick}>
            <CardContent>

                <Box mb={4}>
                    <Typography>{name}</Typography>
                    <TicketPrice>{formatPrice(price)}</TicketPrice>
                </Box>

                <Box mt={2} flex={1} display='flex' alignItems='center'>
                    {saleEnded ? <StoreInactiveIcon /> : <StoreActiveIcon />}
                    <Box display='flex' flexDirection='column'>
                        <Typography variant='caption'>SALE DURATION</Typography>
                        <Box>
                            <Typography variant='subtitle2' display='inline' style={{ fontWeight: 'bold' }}>{saleStarted ? 'Started' : 'Starts'} </Typography>
                            <Typography variant='subtitle2' display='inline'>{saleStart.format('ddd MMM D, h:mma')}</Typography>
                            <Typography variant='subtitle2' display='inline' style={{ fontWeight: 'bold' }}> Until </Typography>
                            <Typography variant='subtitle2' display='inline'>{saleEnd.format('ddd MMM D, h:mma')}</Typography>
                        </Box>
                    </Box>
                </Box>

                <Box mt={2} flex={1} display='flex' alignItems='center'>
                    {accessCode ? (
                        <Tooltip message={'Access Code: ' + accessCode}>
                            <AccessCodeIcon />
                        </Tooltip>
                    ) : (
                        <>{visible === 1 ? <VisibleIcon /> : <HiddenIcon />}</>
                    )}
                    <Box display='flex' flexDirection='column'>
                        <Typography variant='caption'>AUDIENCE</Typography>
                        <Typography variant='subtitle2'>{audience}</Typography>
                    </Box>    
                </Box>

                {checkinType !== null && (
                    <Box mt={2} flex={1} display='flex' alignItems='center'>
                        {multiEntry === 1 ? <MultiCheckinIcon color='secondary' /> : <SingleCheckinIcon color='secondary' />}
                        <Box display='flex' flexDirection='column'>
                            <Typography variant='caption'>CHECK-IN TYPE</Typography>
                            <Typography variant='subtitle2'>{checkinType}</Typography>
                        </Box>
                    </Box>
                )}

                {checkinWindow !== null && (
                    <Box mt={2} flex={1} display='flex' alignItems='center'>
                        <CheckinIcon color='secondary' />
                        <Box display='flex' flexDirection='column'>
                            <Typography variant='caption'>CHECK-IN WINDOW</Typography>
                            <Typography variant='subtitle2'>{checkinWindow}</Typography>
                        </Box>
                    </Box>
                )}

            </CardContent>
        </ProductCard>
    )
}


const Title = styled(Typography)({
    marginBottom: '16px',
});

const ImageContainer = styled('div')(({ theme }) => ({
    lineHeight: 0, // Browser adds extra 2px height without this for some reason
    border: '1px solid #aaa',
    borderRadius: '4px',
    width: '520px',
    marginRight: '48px',
    marginBottom: '32px',
    [theme.breakpoints.down('md')]: {
        width: '380px'
    },
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginRight: 0
    }
}));

const MetaDataContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(5),
    width: '100%',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
        flexDirection: 'column'
    }
}));

const Image = styled('img')({
    width: '100%',
    borderRadius: '4px',
    objectFit: 'contain'
});

const IconLabel = styled(Typography)(({ theme }) => ({
    marginLeft: '16px'
}));


const ProductCard = styled(Card)(({ theme }) => ({
    height: '100%',
    '&:hover': {
        cursor: 'pointer'
    }
}));

const TicketPrice = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    marginTop: theme.spacing(1)
}));

const CheckinIcon = styled(MeetingRoomIcon)(({ theme }) => ({
    marginRight: theme.spacing(1)
}));

const SingleCheckinIcon = styled(DoneIcon)(({ theme }) => ({
    marginRight: theme.spacing(1)
}));

const MultiCheckinIcon = styled(DateRangeIcon)(({ theme }) => ({
    marginRight: theme.spacing(1)
}));

const StoreActiveIcon = styled(StoreIcon)(({ theme }) => ({
    color: theme.palette.success.main,
    marginRight: theme.spacing(1)
}));

const StoreInactiveIcon = styled(StoreIcon)(({ theme }) => ({
    color: theme.palette.grey.A200,
    marginRight: theme.spacing(1)
}));

const VisibleIcon = styled(PersonIcon)(({ theme }) => ({
    color: theme.palette.success.main,
    marginRight: theme.spacing(1)
}));

const HiddenIcon = styled(PersonIcon)(({ theme }) => ({
    color: theme.palette.grey.A200,
    marginRight: theme.spacing(1)
}));

const AccessCodeIcon = styled(LockIcon)(({ theme }) => ({
    color: theme.palette.warning.main,
    marginRight: theme.spacing(1)
}));

const ButtonActive = styled(Button)(({ theme }) => ({
    width: 114,
    borderColor: theme.palette.secondary.main,
    color: theme.palette.grey[100],
    backgroundColor: theme.palette.secondary.main,
}));

const ButtonInactive = styled(Button)(({ theme }) => ({
    width: 114,
    borderColor: theme.palette.grey[800],
    color: theme.palette.grey[800]
}));
ButtonInactive.defaultProps = { disableFocusRipple: true }

const DeleteButton = styled(Button)(({ theme }) => ({
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main
}));

const Loading = styled(CircularProgress)(({ theme }) => ({
    color: '#fff'
}));
Loading.defaultProps = { size: 12 }

const Link = styled('a')(({ theme }) => ({
    color: '#000',
    display: 'inline'
}));

const InlineLink = styled('a')(({ theme }) => ({
    ...theme.typography.body1,
    color: '#000',
    display: 'inline'
}));

const SuccessText = styled(Typography)(({ theme }) => ({
    color: theme.palette.success.main,
    fontWeight: 'bold'
}));

const InfoText = styled(Typography)(({ theme }) => ({
    color: theme.palette.info.main,
    fontWeight: 'bold'
}));

const WarningText = styled(Typography)(({ theme }) => ({
    color: theme.palette.warning.main,
    fontWeight: 'bold'
}));

const AlertText = styled(Typography)(({ theme }) => ({
    display: 'inline'
}));
AlertText.defaultProps = { variant: 'body2' }

const AlertTextBold = styled(Typography)(({ theme }) => ({
    display: 'inline',
    fontWeight: 'bold'
}));
AlertTextBold.defaultProps = { variant: 'body2' }