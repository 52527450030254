import React from 'react';
import { styled, Button } from '@material-ui/core';


export function AlertButton(props) {
    const { severity, onClick } = props;

    switch (severity) {
        case 'success':
            return <SuccessButton onClick={onClick}>{props.children}</SuccessButton>
        case 'warning':
            return <WarningButton onClick={onClick}>{props.children}</WarningButton>
        case 'error':
            return <ErrorButton onClick={onClick}>{props.children}</ErrorButton>
        case 'info':
            return <InfoButton onClick={onClick}>{props.children}</InfoButton>
        default:
            return <Button onClick={onClick}>{props.children}</Button>
    }
}


const SuccessButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'white',
    color: theme.palette.success.dark,
    '&:hover': {
        backgroundColor: theme.palette.grey[300],
        cursor: 'pointer'
    }
}));
SuccessButton.defaultProps = { color: 'inherit', size: 'small' }

const WarningButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'white',
    color: theme.palette.warning.dark,
    '&:hover': {
        backgroundColor: theme.palette.grey[300],
        cursor: 'pointer'
    }
}));
WarningButton.defaultProps = { color: 'inherit', size: 'small' }

const ErrorButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'white',
    color: theme.palette.error.dark,
    '&:hover': {
        backgroundColor: theme.palette.grey[300],
        cursor: 'pointer'
    }
}));
ErrorButton.defaultProps = { color: 'inherit', size: 'small' }

const InfoButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'white',
    color: theme.palette.info.dark,
    '&:hover': {
        backgroundColor: theme.palette.grey[300],
        cursor: 'pointer'
    }
}));
InfoButton.defaultProps = { color: 'inherit', size: 'small' }