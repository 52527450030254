import React, { useEffect, useReducer } from 'react';

import FullScreenModal from 'components/popups/FullScreenModal';
import BundleForm from 'components/bundles/BundleForm';
import fetchExpresso from 'utility/fetchExpresso';

import { useNotification } from 'context/notification';
import { useEvent } from 'context/event';
import moment from 'moment-timezone';
import { LoadingPage, ErrorPage } from 'components/ui/states';


const initialState = {
    status: 'loading', // loading, error, success, no_products
    submitting: false,
    products: [],
    defaultValues: {
        name: '',
        description: '',
        price: '',
        qtyAvailable: '',
        qtyMin: '',
        qtyMax: '',
        saleStartDate: moment(),
        saleEndDate: moment(),
        visible: true,
        accessCode: ''
    },
}

function reducer(state, action) {
    switch (action.type) {
        case 'setForm':
            return { ...state, status: 'success', products: action.products, defaultValues: { ...state.defaultValues, ...action.form } };

        case 'setNoProducts':
            return { ...state, status: 'no_productcs' };

        case 'setSubmitting':
            return { ...state, submitting: action.value };

        case 'defaultState':
            return initialState;

        default:
            throw new Error();
    }
}


export default function BundleCreateModal(props) {
    const { open, onClose } = props;

    return (
        <FullScreenModal open={open} onClose={onClose} title='Create Bundle' cancelText='Cancel'>
            {open === true && (
               <BundleModal {...props} /> 
            )}
        </FullScreenModal>
    )
}

function BundleModal(props) {
    const { onClose, eventId, onCreate } = props;

    const [state, dispatch] = useReducer(reducer, initialState);

    const event = useEvent();
    const { createNotification } = useNotification();


    useEffect(() => {
        fetchExpresso(`/apiv2/products/?event=${eventId}`)
            .then(async res => {
                if (res.status !== 200) throw new Error();
                const data = await res.json();

                if (data.length > 0) {
                    const products = data.map(i => {
                        let status = 'on_sale';
        
                        if (moment(i.sale_start_date).tz(event.time_zone, true) > moment()) status = 'sale_not_started';
                        if (moment(i.sale_end_date).tz(event.time_zone, true) < moment()) status = 'sale_concluded';
                        if (i.qty_left <= 0) status = 'sold_out';
                        if (i.visible === 0) status = 'hidden';

                        return {
                            id: i.product_id,
                            name: i.prod_name,
                            type: i.prod_type_id,
                            sold: i.qty_sold,
                            total: i.total_available,
                            price: i.price,
                            status: status,
                            quantity: 0
                        }
                    });

                    const form = {
                        saleStartDate: moment({ hour: 0, minute: 0 }),
                        saleEndDate: moment(event.end_date),
                        audience: 'visible',
                    }

                    dispatch({ type: 'setForm', products, form });
                } else {
                    dispatch({ type: 'setNoProducts' });
                }

            })
            .catch((e) => console.log(e))
    }, [eventId, event.end_date, event.time_zone]);


    const handleSubmit = (bundleInfo, bundleProducts) => {

        dispatch({ type: 'setSubmitting', value: true });

        fetchExpresso('/apiv2/bundles/', {
            method: 'POST',
            body: {
                eventId: eventId,
                bundleInfo: bundleInfo,
                bundleProducts: bundleProducts
            }
        })
            .then(async res => {
                if (res.status !== 200) {
                    throw new Error();
                }

                dispatch({ type: 'defaultState' });

                onCreate(); // Notifies parent component that a bundle was created.
            })
            .catch(() => {
                createNotification('There was a problem creating your bundle');
                dispatch({ type: 'setSubmitting', value: false });
            })
    };

    if (state.status === 'loading') {
        return <LoadingPage />
    }
    
    if (state.status === 'error') {
        return <ErrorPage message='Unable to load bundle creator' />
    }
    
    if (state.status === 'no_tickets') {
        return <ErrorPage message='Unable to find any tickets to bundle.' />
    }


    return (
        <BundleForm
            defaultValues={state.defaultValues}
            eventId={eventId}
            hostId={event.host_id}
            products={state.products}
            onSubmit={handleSubmit}
            onCancel={onClose}
            loading={state.submitting}
        />
    )
}