import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Hidden, Box, Toolbar } from '@material-ui/core';

// Components
import AdminAppBar from 'components/navigation/AdminAppBar';
import AdminDrawer from 'components/navigation/AdminDrawer';
import AdminTabs from 'components/navigation/AdminTabs';

// Pages
import Admin from 'pages/Admin';
import AdminEvents from 'pages/AdminEvents';
import Audit from 'pages/admin/audit/Audit';
import OrderLookup from 'pages/admin/OrderLookup';
import Hosts from 'pages/Hosts';
import Users from 'pages/Users';
import Reports from 'pages/admin/Reports';
import Calculator from 'pages/admin/Calculator';
import HostPricing from 'pages/HostPricing';
import PageNotFound from 'pages/PageNotFound';


export default function AdminNavigator() {
    return (
        <>
            <AdminAppBar />
        
            <Box display='flex' height='100%'>

                <Hidden smDown>
                    <AdminDrawer />
                </Hidden>

                <Box display='flex' flexGrow={1} flexDirection='column' height='100%' maxWidth='100%'>
                    
                    <Toolbar />

                    <Hidden mdUp>
                        <AdminTabs />
                    </Hidden>

                    <Box display='flex' flexGrow={1} flexDirection='column'>

                        <Switch>
                            <Route exact path={'/admin/'} component={Admin} />
                            <Route exact path={'/admin/events'} component={AdminEvents} />
                            <Route exact path={'/admin/operations'} component={Audit} />
                            <Route exact path={'/admin/orders'} component={OrderLookup} />
                            <Route exact path={'/admin/users'} component={Users} />
                            <Route exact path={'/admin/reports'} component={Reports} />
                            <Route exact path={'/admin/calculator'} component={Calculator} />

                            <Route exact path={'/admin/hosts'} component={Hosts} />
                            <Route exact path={'/admin/hosts/:hostId/host_pricing'} component={HostPricing} />

                            <Route path="*" component={PageNotFound} />
                        </Switch>

                    </Box>


                </Box>

            </Box>
        
        </>
    );
}
