import React, { useState } from 'react';

// Material UI
import { useTheme, useMediaQuery } from '@material-ui/core';

// Components
import { ControlBar, ControlBarButton, ControlBarHeader } from 'components/ui/layout';
import AddonCreateModal from 'components/addons/AddonCreateModal';
import AddonEditModal from 'components/addons/AddonEditModal';
import { DesktopProductList, MobileProductList } from 'components/products/ProductList';

// Utility
import { useAuth } from 'context/auth';
import ReactGA from 'react-ga'
import { useEvent } from 'context/event';
import fetchExpresso from 'utility/fetchExpresso';
import { useNotification } from 'context/notification';


export default function AddonList(props) {
    const { eventId, products, enableSort, onProductSort, toggleProductSorting, onProductSortSubmit, onUpdate } = props;

    // State
    const [modal, setModal] = useState({
        open: false,
        productId: null
    });

    const [copyId, setCopyId] = useState(null);


    // Utility
    const event = useEvent();
    const { auth } = useAuth();
    const { createNotification } = useNotification();
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('md'));

    const hostId = auth.hostId;


    const handleModalOpen = () => setModal({ open: true, productId: null });
    const handleModalClose = () => setModal({ open: false, productId: null });

    function handleProductSelect(id) {
        ReactGA.event({ category: "Tickets", action: 'View Add-on ', label: `${hostId}` });
        setModal({ open: true, productId: id })
    }
    
    function handleProductCreate() {
        ReactGA.event({ category: "Tickets", action: 'Create Add-on ', label: `${hostId}` });
        setModal({ open: false, productId: null });
        onUpdate();
    }
   
    function handleProductEdit() {
        ReactGA.event({ category: "Tickets", action: 'Edit Add-on ', label: `${hostId}` });
        setModal({ open: false, productId: null });
        onUpdate();
    }
    
    function handleProductDelete() {
        ReactGA.event({ category: "Tickets", action: 'Delete Add-on ', label: `${hostId}` });
        setModal({ open: false, productId: null });
        onUpdate();
    }

    const handleProductCopy = (id) => {
        if (copyId) return;
        setCopyId(id);

        fetchExpresso(`/apiv2/products/?sourceId=${id}`, {
            method: 'POST',
            body: { hostId: hostId, eventId: eventId }
        })
            .then(res => {
                if (res.status === 200) {
                    handleProductCreate();
                    createNotification('Copied add-on');
                } else {
                    createNotification('Unable to copy add-on.');
                }
            })
            .catch(() => {
                createNotification('Unable to copy add-on.');
            })
            .finally(() => {
                setCopyId(null);
            })
    };

    return (
        <>
            <ControlBar>
                <ControlBarHeader>Add-On Tickets</ControlBarHeader>
                <ControlBarButton onClick={handleModalOpen}>New Add-on</ControlBarButton>
            </ControlBar>

            {mobileView === true && (
                <MobileProductList
                    addon
                    event={event}
                    products={products}
                    onProductSelect={handleProductSelect}
                    onProductCopy={handleProductCopy}
                    copyId={copyId}
                    onProductSort={onProductSort}
                    enableSort={enableSort}
                    toggleProductSorting={toggleProductSorting}
                    onProductSortSubmit={onProductSortSubmit}
                />
            )}

            {mobileView === false && (
                <DesktopProductList
                    addon
                    event={event}
                    products={products}
                    onProductSelect={handleProductSelect}
                    onProductCopy={handleProductCopy}
                    copyId={copyId}
                    onProductSort={onProductSort}
                    enableSort={enableSort}
                    toggleProductSorting={toggleProductSorting}
                    onProductSortSubmit={onProductSortSubmit}
                />
            )}
            
            <AddonEditModal
                open={modal.open && modal.productId !== null}
                onClose={handleModalClose}
                hostId={hostId}
                eventId={eventId}
                productId={modal.productId}
                onCancel={handleModalClose}
                onEdit={handleProductEdit}
                onDelete={handleProductDelete}
            />

            <AddonCreateModal
                open={modal.open && modal.productId === null}
                onClose={handleModalClose}
                hostId={hostId}
                eventId={eventId}
                onCreate={handleProductCreate}
                onCancel={handleModalClose}
            />
        </>
    )
}