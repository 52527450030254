import React from 'react';

// Material UI
import { styled, AppBar, Toolbar, Button, Box } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// Utility
import { useHistory } from 'react-router-dom';


/**
 * Displays on all screen sizes
 * 
 * This app bar shows when the user is viewing the admin console
 * Its only function is to let the user navigate back to the home screen
 */


export default function AdminAppBar() {

    const history = useHistory();

    return (
        <Box displayPrint='none'>

            <TopAppBar position='fixed'>

                <Toolbar>

                    <BackButton onClick={() => history.push(`/events`)} startIcon={<BackArrow />}>Back</BackButton>

                </Toolbar>

            </TopAppBar>

        </Box>
    )
}


const TopAppBar = styled(AppBar)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.background.dark,
    zIndex: theme.zIndex.drawer + 1
}));

const BackButton = styled(Button)(({ theme }) => ({
    color: theme.palette.common.white
}));

const BackArrow = styled(ArrowBackIcon)(({ theme }) => ({
    color: theme.palette.common.white
}));