import React, { useState, useEffect } from 'react';

// Material-UI
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { styled, CircularProgress, Typography, Box, Button } from '@material-ui/core';

// Utility
import fetchExpresso from 'utility/fetchExpresso';
import moment from 'moment-timezone';



export default function ScansByEvent() {

    const [range, setRange] = useState('day'); // day, month, year

    const [state, setState] = useState({
        data: null,
        loading: true,
        error: false
    });

    const { data, loading, error } = state;


    useEffect(() => {
        fetchExpresso(`/admin/reports/scans_by_event?tz=${moment.tz.guess()}&start=${moment().startOf(range).format('YYYY-MM-DDTHH:mm:ss')}&end=${moment().endOf(range).format('YYYY-MM-DDTHH:mm:ss')}`)
            .then(async res => {
                if (res.status !== 200) {
                    setState(s => ({ ...s, loading: false, error: true }));
                    return;
                }
                const data = await res.json();
                setState(s => ({ ...s, data: data, loading: false, error: false }));
            })
            .catch(e => {
                setState(s => ({ ...s, loading: false, error: true }));
            })
    }, [range]);

    if (loading) {
        return (
            <Root>
                <Status>
                    <CircularProgress disableShrink />
                </Status>
            </Root>
        );
    }

    if (error) {
        return (
            <Root>
                <Status>
                    <Typography>Unable to load scans</Typography>
                </Status>
            </Root>
        );
    }


    return (
        <Root>

            <CardHeader
                title='Scans by Event'
                action={(
                    <Box>
                        <Button style={range === 'day' ? {textDecoration: 'underline'} : undefined} onClick={() => setRange('day')}>24 hrs</Button>
                        {/* <Button style={range === 'day' ? {textDecoration: 'underline'} : undefined} onClick={() => setRange('day')}>Today</Button>
                        <Button style={range === 'month' ? {textDecoration: 'underline'} : undefined} onClick={() => setRange('month')}>This Month</Button>
                        <Button style={range === 'year' ? {textDecoration: 'underline'} : undefined} onClick={() => setRange('year')}>This Year</Button> */}
                    </Box>
                )}
            />

            <CardContent>

                <TableContainer component={Box}>

                    <Table size='small'>

                        <TableHead>

                            <TableRow>
                                <TableCellBold>EVENT</TableCellBold>
                                <TableCellBold align='right'>SCANS</TableCellBold>
                                <TableCellBold align='right'>GOOD %</TableCellBold>
                                <TableCellBold align='right'>WHEN</TableCellBold>
                            </TableRow>

                        </TableHead>

                        <TableBody>

                            {data.map((r, index) => (
                                <TableRow key={r.event_id}>
                                    <EventTitleCell><EventTitleText noWrap variant='body2'>{r.event_name}</EventTitleText></EventTitleCell>
                                    <TableCell align='right'>{r.scans_total}</TableCell>
                                    <TableCell align='right'>{(r.scan_good_pct * 100).toFixed(1)}%</TableCell>
                                    <TableCell align='right'>{moment(r.last_scan).fromNow()}</TableCell>
                                </TableRow>
                            ))}

                            {data.length === 0 && (
                                <TableRow>
                                    <TableCell align='center' colSpan={5}>
                                        <Typography variant='subtitle2'>No data found</Typography>
                                    </TableCell>
                                </TableRow>
                            )}

                        </TableBody>

                    </Table>

                </TableContainer>

            </CardContent>

        </Root>
    )
}

const Root = styled(Card)({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width :'100%'
});

const Status = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
});

const TableCellBold = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold'
}));

const EventTitleCell = styled(TableCell)(({ theme }) => ({
    maxWidth: 320,
    [theme.breakpoints.down('lg')]: {
        maxWidth: 160
    },
    [theme.breakpoints.down('md')]: {
        maxWidth: 100
    },
    [theme.breakpoints.down('sm')]: {
        maxWidth: 220
    },
}));

const EventTitleText = styled(Typography)({
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
});