import React, { useState, useEffect } from 'react';

// Material-UI
import { Card } from '@material-ui/core';
import { styled, CircularProgress, Typography } from '@material-ui/core';

// Utility
import fetchExpresso from 'utility/fetchExpresso';
import Chart from 'react-apexcharts';
import moment from 'moment-timezone';


export default function TicketsByDay() {

    const [state, setState] = useState({
        data: null,
        loading: true,
        error: false
    });

    const { data, loading, error } = state;

    useEffect(() => {
        fetchExpresso(`/admin/reports/ticket_count?tz=${moment.tz.guess()}`)
            .then(async res => {
                if (res.status !== 200) {
                    setState(s => ({ ...s, loading: false, error: true }));
                    return;
                }
                const data = await res.json();
                setState(s => ({ ...s, data: data, loading: false, error: false }));
            })
            .catch(e => {
                setState(s => ({ ...s, loading: false, error: true }));
            })
    }, []);

    if (loading) {
        return (
            <Root>
                <Status>
                    <CircularProgress disableShrink />
                </Status>
            </Root>
        );
    }

    if (error) {
        return (
            <Root>
                <Status>
                    <Typography>Unable to load orders</Typography>
                </Status>
            </Root>
        );
    }

    const series = [
        { name: 'Paid - online', data: data.map(t => t.ticket_count_paid) },
        { name: 'Free - online', data: data.map(t => t.ticket_count_free) },
        { name: 'Paid - pos', data: data.map(t => t.pos_ticket_count_paid) },
        { name: 'Free - pos', data: data.map(t => t.pos_ticket_count_free) },
    ];

    const options = {
        chart: {
            stacked: true,
            zoom: {
                enabled: false
            }
        },
        title: {
            text: data.reduce((p,n) => p + n.ticket_count + n.pos_ticket_count, 0),
            offsetX: 0,
            style: {
                fontSize: '24px',
            }
        },
        subtitle: {
            text: 'Tickets',
            offsetX: 0,
            style: {
                fontSize: '14px',
            }
        },
        legend: {
            onItemClick: {
                toggleDataSeries: true
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: false,
            curve: 'straight'
        },
        fill: {
            type: 'solid'
        },
        xaxis: {
            categories: data.map(t => t.day_time),
            labels: {
                rotateAlways: true,
                maxHeight: 60
            }
        }
    }

    return (
        <Root>
            <Chart
                height='100%'
                width='100%'
                type='area'
                options={options}
                series={series}
            />
        </Root>
    )
}


const Root = styled(Card)(({ theme }) => ({
    padding: theme.spacing(1),
    height: 280
}));

const Status = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
});