import React, { useState, useEffect } from 'react';

// Material-UI
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell as _TableCell } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { styled, CircularProgress, Typography, Box, Button } from '@material-ui/core';

// Utility
import fetchExpresso from 'utility/fetchExpresso';
import moment from 'moment-timezone';



export default function ScanStatus() {
    const [state, setState] = useState({
        data: null,
        loading: true,
        error: false
    });

    const { data, loading, error } = state;


    useEffect(() => {
        fetchExpresso(`/admin/reports/scan_status?tz=${moment.tz.guess()}`)
            .then(async res => {
                if (res.status !== 200) {
                    setState(s => ({ ...s, loading: false, error: true }));
                    return;
                }
                const data = await res.json();
                setState(s => ({ ...s, data: data, loading: false, error: false }));
            })
            .catch(e => {
                setState(s => ({ ...s, loading: false, error: true }));
            })
    }, []);

    if (loading) {
        return (
            <Root>
                <Status>
                    <CircularProgress disableShrink />
                </Status>
            </Root>
        );
    }

    if (error) {
        return (
            <Root>
                <Status>
                    <Typography>Unable to load stats</Typography>
                </Status>
            </Root>
        );
    }

    const totalScans = data.reduce((p,n) => p + n.status_count, 0);


    return (
        <Root>

            <CardHeader
                title='Scans'
                action={<Button style={{textDecoration: 'underline'}}>24 Hrs</Button>}
            />

            <CardContent>

                <TableContainer component={Box}>

                    <Table size='small'>

                        <TableHead>

                            <TableRow>
                                <TableCellBold></TableCellBold>
                                <TableCellBold></TableCellBold>
                                <TableCellBold align='right'>#</TableCellBold>
                                <TableCellBold align='right'>%</TableCellBold>
                                <TableCellBold align='right'>WHEN</TableCellBold>
                            </TableRow>

                        </TableHead>

                        <TableBody>

                            {data.map(s => (
                                <TableRow key={s.scan_status_id}>
                                    <TableCellBold width={20}>{s.scan_status_id}</TableCellBold>
                                    <TableCell>{s.code_desc}</TableCell>
                                    <TableCell width={30} align='right'>{s.status_count}</TableCell>
                                    <TableCell width={30} align='right'>{((s.status_count / totalScans) * 100).toFixed(1)}%</TableCell>
                                    <TableCell width={30} align='right'>{moment(s.latest_status_stamp).fromNow()}</TableCell>
                                </TableRow>
                            ))}

                            {data.length === 0 && (
                                <TableRow>
                                    <TableCell align='center' colSpan={5}>
                                        <Typography variant='subtitle2'>No data found</Typography>
                                    </TableCell>
                                </TableRow>
                            )}

                        </TableBody>

                    </Table>

                </TableContainer>

            </CardContent>

        </Root>
    )
}

const Root = styled(Card)({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width :'100%'
});

const Status = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
});

const TableCellBold = styled(_TableCell)(({ theme }) => ({
    fontWeight: 'bold'
}));

const TableCell = styled(_TableCell)(({ theme }) => ({
    whiteSpace: 'nowrap'
}));