import React, { useState, useEffect } from 'react';

// Material UI
import { useTheme } from '@material-ui/core/styles';
import { styled, Box, Grid, Hidden } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';

// Components
import { LoadingPage, ErrorPage } from 'components/ui/states';
import ReceiptTable from 'components/tables/ReceiptTable';
import OrderDetails from 'components/orders/OrderDetails';
import TicketEmailer from 'components/orders/TicketEmailer';
import TicketDownloader from 'components/orders/TicketDownloader';
import RegistrationAnswers from 'components/orders/RegistrationAnswers';
import ActivityLog from 'components/orders/ActivityLog';
import Comments from 'components/orders/Comments';
import AdminControls from 'components/orders/AdminControls';

// Utility
import { useAuth } from 'context/auth';
import fetchExpresso from 'utility/fetchExpresso';
import moment from 'moment-timezone';



// TODO: Refactor this component to render the FullScreenModal Component instead of relying on the parent to wrap it in the modal.
//       See OrderCreateModal for an example

export default function OrderDetailModal(props) {
    // Props
    const { tranId, eventId, onUpdate } = props;

    const [state, setState] = useState({
        status: 'loading', // loading, error, success
        order: null,
        products: null,
        activity: null,
        payouts: null
    });

    const [refresh, setRefresh] = useState(0); // Increment this to refresh the order history


    useEffect(() => {
        (async () => {
            try {

                const [o, a, p] = await Promise.all([
                    fetchExpresso(`/apiv2/orders/${tranId}/`),
                    fetchExpresso(`/apiv2/orders/${tranId}/activity`),
                    fetchExpresso(`/apiv2/events/${eventId}/payouts?tz=${moment.tz.guess()}`),
                ]);

                if (o.status !== 200 || a.status !== 200 || p.status !== 200) {
                    throw new Error();
                }
    
                const [orderDetails, activity, payouts] = await Promise.all([o.json(), a.json(), p.json()])

                setState(s => ({
                    ...s,
                    status: 'success',
                    order: orderDetails.order,
                    products: orderDetails.products,
                    activity: activity,
                    payouts: payouts
                }));

            } catch(e) {
                setState(s => ({ ...s, status: 'error' }));
            }
        })()
    }, [tranId, eventId, refresh]);


    const { auth } = useAuth();
    const theme = useTheme();


    if (state.status === 'loading') {
        return <LoadingPage />
    }
    
    if (state.status === 'error') {
        return <ErrorPage message='Unable to find order' />
    }


    const statusFinancial = state.order.status_financial; 
    const orderApproved = Boolean(statusFinancial && statusFinancial >= 1000 && statusFinancial <= 1999); // A status_financial of 0 can result in this expression resulting in 0 instead of a boolean. We have to explicitly convert the result to a boolean.
    const questionsEnabled = state.order.checkout_question_flag === 1;
    const enableRefund = statusFinancial && statusFinancial >= 1000 && statusFinancial <= 1999 && state.payouts?.some(p => p.final_payout_flag === 1) === false; // We allow 'refunds' on free orders for now since it allows us to void those tickets
    const enableReceipt = statusFinancial && statusFinancial !== 1001;
    const multiTicket = state.products.length > 1 || state.products.some(p => p.is_bundle === 1) || state.products[0].qty > 1;

    // Host Absorbs (Total)
    let hostTotal = 0;

    const { host_absorbs_b4t_fees, host_absorbs_cc_fees, our_fee_total, cc_fee_total, tax_on_products, sub_total } = state.order;

    let fees = 0

    if (host_absorbs_b4t_fees) {
        fees = Number((fees + our_fee_total).toFixed(2));
    }

    if (host_absorbs_cc_fees) {
        fees = Number((fees + cc_fee_total).toFixed(2))
    }

    const subTotalCalc = Number((sub_total - state.order.disc_order_total).toFixed(2));

    hostTotal = Number((subTotalCalc + tax_on_products - fees).toFixed(2));



    return (
        <Root>
            <Box display='flex' flexDirection='column' flexGrow={1} p={[2, 4]} maxWidth={theme.breakpoints.values.xl}>

                <Box display='flex'>

                    <Box flexGrow={1}>

                        <Grid container spacing={3}>

                            <Grid item xs={12} lg={6}>
                                <OrderDetails
                                    admin={auth.admin}
                                    order={state.order}
                                />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <Card>
                                    <CardHeader title='Receipt' />
                                    <CardContent>
                                        <ReceiptTable orderDetails={{ ...state.order, hostTotal: hostTotal}} products={state.products} />
                                    </CardContent>
                                </Card>
                            </Grid>

                            {orderApproved && (
                                <Grid item xs={12} lg={6}>
                                    <TicketEmailer
                                        multiTicket={multiTicket}
                                        email={state.order.email}
                                        tranId={tranId}
                                        onSend={() => setRefresh(refresh+1)}
                                    />
                                </Grid>
                            )}
                            
                            {orderApproved && (
                                <Grid item xs={12} lg={6}>
                                    <TicketDownloader
                                        multiTicket={multiTicket}
                                        lastName={state.order.last_name}
                                        tranId={tranId}
                                        email={state.order.email}
                                        onDownload={() => setRefresh(refresh+1)}
                                    />
                                </Grid>
                            )}

                            {questionsEnabled && (
                                <Grid item xs={12} lg={6}>
                                    <RegistrationAnswers tranId={tranId} />
                                </Grid>
                            )}

                            {auth.admin && (
                                <Grid item xs={12} lg={6}>
                                    <AdminControls
                                        order={state.order}
                                        tranId={tranId}
                                        eventId={eventId}
                                        enableRefund={enableRefund}
                                        enableReceipt={enableReceipt}
                                        onRefund={onUpdate}
                                    />
                                </Grid>
                            )}


                            <Hidden mdUp>
                                <Grid item xs={12}>
                                    <Comments tranId={tranId} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ActivityLog log={state.activity} />
                                </Grid>
                            </Hidden>

                        </Grid>
                    </Box>

                    <Hidden smDown>
                        <Box ml={3}>
                            <Comments tranId={tranId} />
                            <Box mb={4} />
                            <ActivityLog log={state.activity} />
                        </Box>
                    </Hidden>

                </Box>

            </Box>
        </Root>
    )
}


const Root = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey[100],
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
}));