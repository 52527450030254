import React, { useState } from 'react';

// Material UI
import { styled, Box, Button, TextField, Grid, Typography, Hidden } from '@material-ui/core';
import { Card, CardHeader, CardContent, CardActions } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import FileCopyIcon from '@material-ui/icons/FileCopy';

// Components
import { Page } from 'components/ui/layout';
import { ControlBar, ControlBarHeader } from 'components/ui/layout';

// Utility
import { useNotification } from 'context/notification';


export default function Resources() {
    return (
        <Page>

            <Grid container spacing={4} justifyContent='center'>

                <Grid item xs={12} lg={8}>
                    <ControlBar>
                        <ControlBarHeader>Event Organizer Resources</ControlBarHeader>
                    </ControlBar>
                    <Typography gutterBottom>
                        The articles below are here to support you in every step of organizing an event with FrontDoor+. Feel free to share these articles with your volunteers and staff members to ensure your event runs smoothly from start to finish.
                    </Typography>
                </Grid>
                
                <Grid item xs={12} lg={8}>
                    <Resource
                        title='Entry Management'
                        description='Learn how to streamline entry to your event with the FrontDoor+ mobile apps.'
                        link='https://hub.frontdoor.plus/blog/master-the-gate-your-quick-guide-to-scanning-tickets-and-using-our-pos-devices'
                    />
                </Grid>

                <Grid item xs={12} lg={8}>
                    <Resource
                        title='Protect your Attendees and Reputation Online'
                        description='This article goes over strategies to combat scammers who exploit social media to sell fake tickets.'
                        link='https://hub.frontdoor.plus/blog/secure-your-event-essential-facebook-settings-to-prevent-ticket-scams'
                    />
                </Grid>
            
                {/* <Grid item xs={12} lg={8}>
                    <Resource
                        title='Promoting your Event on Social Media (Coming Soon)'
                        description='Leverage social media platforms to raise awareness and reach a wider audience.'
                        link='https://hub.frontdoor.plus/blog/'
                    />
                </Grid> */}

            </Grid>

        </Page>
    )
}


function Resource(props) {
    const { title, description, link } = props;

    const [copied, setCopied] = useState(false);

    const { createNotification } = useNotification();

    const copyLink = () => {
        navigator.clipboard.writeText(link)
            .then(() => setCopied(true))
            .catch(() => createNotification('Unable to copy to clipboard'))
    }

    return (
        <Card>
            <CardHeader title={title} />
            <CardContent>
                <Typography>{description}</Typography>
                <Box mt={4}>
                    <LinkContainer
                        helperText={copied ? 'Copied to clipboard' : ' '}
                        value={link}
                    />
                </Box>
            </CardContent>
            <CardActions>
                <Hidden smUp>
                    <Grid container spacing={1}>
                        <Grid xs={6} item>
                            <LinkButton fullWidth startIcon={<LaunchIcon />} href={link} target='_blank' rel='noopener noreferrer'>Read Article</LinkButton>
                        </Grid>
                        <Grid xs={6} item>
                            <LinkButton fullWidth startIcon={<FileCopyIcon />} onClick={copyLink}>Copy Link</LinkButton>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden xsDown>
                    <LinkButton startIcon={<LaunchIcon />} href={link} target='_blank' rel='noopener noreferrer'>Read Article</LinkButton>
                    <LinkButton startIcon={<FileCopyIcon />} onClick={copyLink}>Copy Link</LinkButton>
                </Hidden>
            </CardActions>
        </Card>
    )
}

const LinkContainer = styled(TextField)(({ theme }) => ({
    borderColor: theme.palette.secondary.main,
    marginHorizontal: theme.spacing(2),
}));
LinkContainer.defaultProps = {
    variant: 'outlined',
    fullWidth: true,
    onFocus: (e) => e.target.select()
}

const LinkButton = styled(Button)(({ theme }) => ({}));
LinkButton.defaultProps = { variant: 'outlined', color: 'secondary' };