import React, { useState } from 'react';
import { styled, Box, TextField, CardActions, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { ActionButton } from 'components/ui/buttons';
import { useTextInput } from 'hooks/forms';
import { validateEmail } from 'utility/validation';
import { useNotification } from 'context/notification';
import fetchExpresso from 'utility/fetchExpresso';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import SendIcon from '@material-ui/icons/Send';


export default function TicketEmailer(props) {
    const { multiTicket, onSend } = props;

    const [delivery, setDelivery] = useState('merge'); // merge, zip
    const [loading, setLoading] = useState(false);

    const email = useTextInput({ defaultValue: props.email });

    const { createNotification } = useNotification();


    const handleSend = () => {
        const emailValid = validateEmail(email.value);

        if (emailValid) {
            setLoading(true);

            fetchExpresso(`/api/purchases/issue-tickets?delivery=${delivery}`, {
                method: 'POST',
                body: {
                    tranId: props.tranId,
                    email: email.value.trim()
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        createNotification(`Sent tickets to: ${email.value.trim()}`)

                        // Create a log on successful send
                        fetchExpresso(`/apiv2/ticket_activity/`, {
                            method: 'POST',
                            body: { tranId: props.tranId, typeId: 100, email: email.value.trim() }
                        })
                        .then(() => {
                            if (onSend) {
                                onSend()
                            }
                        })
                        .catch(() => null);
                    } else {
                        throw new Error();
                    }
                })
                .catch(() => createNotification('There was a problem sending tickets'))
                .finally(() => setLoading(false));
        } else {
            email.setError('Please enter a valid email address');
        }
    };


    return (
        <Card>
            <CardHeader
                title={<Box display='flex' alignItems='center'><AlternateEmailIcon /><Title>Email Tickets</Title></Box>}
                subheader='Send these tickets to a specific email address'
            />
            <CardContent>
                <Box minHeight={multiTicket ? '160px' : '60px'}>
                    <TextField variant='outlined' type='email' fullWidth {...email.formProps} />
                    {multiTicket && (
                        <Box mt={4}>
                            <Box mb={2}>
                                <Typography style={{ fontWeight: 'bold' }}>How do you want the tickets sent?</Typography>
                            </Box>
                            <RadioGroup aria-label="bundle" value={delivery} onChange={e => setDelivery(e.target.value)}>
                                <FormControlLabel value="merge" control={<Radio />} label={'Combine tickets into one file'} />
                                <Box mt={2} />
                                <FormControlLabel value="zip" control={<Radio />} label={<>
                                    <Typography>Send each ticket as a separate file</Typography>
                                    <Typography variant='caption'>Best if customer is distributing tickets to others</Typography>
                                </>} />
                            </RadioGroup>
                        </Box>
                    )}
                </Box>
            </CardContent>
            <CardActions>
                <ActionButton disabled={loading} onClick={handleSend} startIcon={<SendIcon />}>{loading ? 'Sending...' : 'Send'}</ActionButton>
            </CardActions>
        </Card>
    )
}


const Title = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
    display: 'inline'
}));
Title.defaultProps = { variant: 'h6' }