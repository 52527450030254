import React, { useState } from 'react';

// Material UI
import { styled, Box, BottomNavigation, BottomNavigationAction, Dialog, Badge } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EventIcon from '@material-ui/icons/Event';
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

// Utility
import { useHistory, useLocation } from 'react-router-dom';
import { useInbox, InboxMobile } from 'components/Inbox';


/**
 * Mobile Only
 * See RootAppBar.js for the desktop version of this menu
 */

export default function RootTabs(props) {
    const { admin, hostId } = props;

    // Inbox
    const inbox = useInbox(hostId, admin);
    const [inboxOpen, setInboxOpen] = useState(false);

    // Hooks
    const history = useHistory();
    const location = useLocation();

    const currentPage = location.pathname.replace(/\//g, '') || ''; // Regex: Remove all instances of '/' character in the pathname


    const handleNavigation = (event, index) => {
        switch (index) {
            case 0:
                history.push('/');
                break;
            case 1:
                history.push('/events');
                break;
            case 2:
                history.push('/resources');
                break;
            case 3:
                history.push('/settings');
                break;
            case 4:
                setInboxOpen(true);
                break;
            case 5:
                history.push('/admin');
                break;
            default:
                break;
        }
    };


    return (
        <Box displayPrint='none'>
            <BottomTabs showLabels value={getIndex(currentPage)} onChange={handleNavigation}>
                <TabAction disabled={getIndex(currentPage) === 0} label="Home" icon={<DashboardIcon />} />
                <TabAction disabled={getIndex(currentPage) === 1} label="Events" icon={<EventIcon />} />
                {admin === false && (
                    <TabAction disabled={getIndex(currentPage) === 2} label="Resources" icon={<HelpIcon />} />
                )}
                <TabAction disabled={getIndex(currentPage) === 3} label="Settings" icon={<SettingsIcon />} />
                <TabAction disabled={false} label="Inbox" icon={
                        <Badge badgeContent={inbox.unreadCount} color='error'>
                            <MailOutlineIcon />
                        </Badge>
                    } />
                {admin && (
                    <TabAction disabled={getIndex(currentPage) === 5} label="Admin" icon={<SupervisorAccountIcon />} />
                )}
            </BottomTabs>

            <Dialog fullWidth open={inboxOpen} onClose={() => setInboxOpen(false)}>
                <InboxMobile {...inbox} />
            </Dialog>
        </Box>
    )
}


function getIndex(value) {
    switch (value) {
        case '':
            return 0;
        case 'events':
            return 1;
        case 'resources':
            return 2;
        case 'settings':
            return 3;
        case 'inbox':
            return 4;
        case 'admin':
            return 5;
        default:
            return -1;
    }
}


const BottomTabs = styled(BottomNavigation)(({ theme }) => ({
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 2,
    backgroundColor: theme.palette.background.dark
}));

const TabAction = styled(BottomNavigationAction)(({ theme }) => ({
    color: theme.palette.common.white
}));