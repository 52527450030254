import { useState, useEffect } from 'react';

import { useAuth } from 'context/auth';


export default function useExpresso(endpoint, method, headers, body, dependency) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const { auth } = useAuth();

    let options = (method && method === 'POST')
        ? { method, headers: { 'Content-Type': 'application/json', ...headers }, body: JSON.stringify(body) }
        : { method: 'GET', headers: {} };

    if (auth) {
        options.headers['x-access-token'] = auth.token
    }

    // useEffect will only run again if a value in this array changes
    // pass an empty array if you only want the effect to run once
    const dependencyArray = (Array.isArray(dependency))
        ? dependency
        : [];

    useEffect(() => {
        let mounted = true;

        if (!loading) { setLoading(true) };
        if (error) { setError(false) };

        // Fetch call to desired endpoint
        // endpoint and options will be null if user makes a GET request
        fetch(process.env.REACT_APP_API_URL + endpoint, options)
            .then(res => res.json())
            .then(data => {
                if (mounted) {
                    if (data.err === true) {
                        setError(true)
                    } else {
                        setData(data);
                    }
                    setLoading(false);
                }
            })
            .catch((error) => {
                if (mounted) {
                    setError(error);
                    setLoading(false);
                }
            })

        const cleanup = () => { mounted = false; };
        return cleanup;

        // eslint-disable-next-line
    }, dependencyArray);

    return [data, loading, error];
}