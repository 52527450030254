import React from 'react';

// Material-UI
import { Card, CardHeader, CardContent, Box } from '@material-ui/core';
import { styled, CircularProgress, Typography, Button } from '@material-ui/core';

// Components
import EventPayoutsTable from 'components/payouts/EventPayoutsTable';

// Utility
import useExpresso from 'hooks/useExpresso';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';


export default function EventPayouts(props) {
    const eventId = props.eventId;

    const history = useHistory();

    const [data, loading, error] = useExpresso(`/apiv2/events/${eventId}/payouts?limit=5&tz=${moment.tz.guess()}`, null, null, null, [eventId]);

    const handleNavigate = () => history.push('payouts');


    if (loading) {
        return (
            <Card>
                <CardHeader title='Recent Payouts' action={<Button color='secondary' onClick={handleNavigate}>View All</Button>} />
                <CardContent>
                    <Status>
                        <CircularProgress />
                    </Status>
                </CardContent>
            </Card>
        )
    }

    if (error) {
        return (
            <Card>
                <CardHeader title='Recent Payouts' action={<Button color='secondary' onClick={handleNavigate}>View All</Button>} />
                <CardContent>
                    <Status>
                        <Typography variant='subtitle2'>There was a problem getting your payouts</Typography>
                    </Status>
                </CardContent>
            </Card>
        )
    }


    return (
        <Card>
            <CardHeader title='Recent Payouts' action={<Button color='secondary' onClick={handleNavigate}>View All</Button>} />
            <CardContent>
                <EventPayoutsTable payouts={data} />
            </CardContent>
        </Card>
    )
}


const Status = styled(Box)(({ theme }) => ({
    minHeight: 120,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));