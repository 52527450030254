import React, { useState, useEffect } from 'react';

// Material-UI
import { TableContainer, Table, TableBody, TableRow, TableCell, TableHead } from '@material-ui/core';
import { styled, CircularProgress, Paper, Typography, LinearProgress } from '@material-ui/core';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import LocalMallIcon from '@material-ui/icons/LocalMall';

// Components
import { ControlBar, ControlBarButton, ControlBarHeader } from 'components/ui/layout';
import FullScreenModal from 'components/popups/FullScreenModal';
import ProductEditor from 'components/forms/ProductEditor';
import BundleEditModal from 'components/bundles/BundleEditModal';
import ProductTitle from 'components/products/ProductTitle';

// Utility
import { useHistory } from 'react-router-dom';
import { useEvent } from 'context/event';
import fetchExpresso from 'utility/fetchExpresso';


export default function ProductSales(props) {
    const { eventId, hostId } = props;
    
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedBundle, setSelectedBundle] = useState(null);
    const [refresh, setRefresh] = useState(0);

    const event = useEvent();
    const history = useHistory();
    
    const [state, setState] = useState({
        tickets: [],
        addons: [],
        bundles: [],
        status: 'loading', // loading, error, success
    });

    // Load/Reload list of products
    useEffect(() => {

        Promise.all([
            fetchExpresso(`/apiv2/events/${eventId}/products`),
            fetchExpresso(`/apiv2/events/${eventId}/bundles`),
        ])
            .then(async res => {
                if (res[0].status !== 200 || res[1].status !== 200) {
                    throw new Error();
                }

                const [products, bundles] = await Promise.all([
                    res[0].json(),
                    res[1].json()
                ]);

                setState((state) => ({
                    ...state,
                    tickets: products.filter(p => p.prod_type_id === 1),
                    addons: products.filter(p => p.prod_type_id === 2),
                    bundles: bundles,
                    status: 'success'
                }))
            })
            .catch(() => {
                setState((state) => ({ ...state, status: 'error' }))
            })
    }, [eventId, refresh]);


    const handleProductModalClose = () => setSelectedProduct(null);
    const handleBundleModalClose = () => setSelectedBundle(null);

    const handleProductUpdate = () => {
        setSelectedProduct(null);
        setRefresh(refresh + 1);
    }

    const handleBundleUpdate = () => {
        setSelectedBundle(null);
        setRefresh(refresh + 1);
    }

    const handleNavigate = () => history.push('tickets');


    // Return Product Sales
    return (
        <Root>
            <ControlBar>
                <ControlBarHeader>Ticket Overview</ControlBarHeader>
                <ControlBarButton variant='outlined' onClick={handleNavigate}>View All</ControlBarButton>
            </ControlBar>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableHeadCell></TableHeadCell>
                            <TableHeadCell>NAME</TableHeadCell>
                            <TableHeadCell>SOLD</TableHeadCell>
                            <TableHeadCell align='right'>CHECKED-IN</TableHeadCell>
                            <TableHeadCell colSpan={2}></TableHeadCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {state.status === 'success' && state.tickets.map(p => (
                            <TableBodyRow key={p.product_id} onClick={() => setSelectedProduct(p.product_id)}>
                                <TableBodyCell>
                                    <TicketIcon />
                                </TableBodyCell>
                                <TableBodyCell>
                                    <ProductTitle
                                        name={p.prod_name}
                                        visible={p.visible}
                                        startDate={p.sale_start_date}
                                        endDate={p.sale_end_date}
                                        qtyLeft={p.qty_left}
                                        timezone={event.time_zone}
                                        accessCode={p.access_code}
                                    />
                                </TableBodyCell>
                                <TableBodyCell>{p.qty_sold + ' / ' + p.total_available}</TableBodyCell>
                                <TableBodyCell align='right'>{p.scan_count + ' / ' + p.qty_sold}</TableBodyCell>
                                <TableBodyCell align='right'>{p.scan_pct}%</TableBodyCell>
                                <TableBodyCell width={120}>
                                    <ProgressBar variant='determinate' color='secondary' value={p.scan_pct} />
                                </TableBodyCell>
                            </TableBodyRow>
                        ))}
                        
                        {state.status === 'success' && state.addons.map(p => (
                            <TableBodyRow key={p.product_id} onClick={() => setSelectedProduct(p.product_id)}>
                                <TableBodyCell>
                                    <AddonIcon    />
                                </TableBodyCell>
                                <TableBodyCell>
                                    <ProductTitle
                                        name={p.prod_name}
                                        visible={p.visible}
                                        startDate={p.sale_start_date}
                                        endDate={p.sale_end_date}
                                        qtyLeft={p.qty_left}
                                        timezone={event.time_zone}
                                        accessCode={p.access_code}
                                    />
                                </TableBodyCell>
                                <TableBodyCell>{p.qty_sold + ' / ' + p.total_available}</TableBodyCell>
                                <TableBodyCell align='right'>{p.scan_count + ' / ' + p.qty_sold}</TableBodyCell>
                                <TableBodyCell align='right'>{p.scan_pct}%</TableBodyCell>
                                <TableBodyCell width={120}>
                                    <ProgressBar variant='determinate' color='secondary' value={p.scan_pct} />
                                </TableBodyCell>
                            </TableBodyRow>
                        ))}

                        {state.status === 'success' && state.bundles.map(b => (
                            <TableBodyRow key={b.bundle_id} onClick={() => setSelectedBundle(b.bundle_id)}>
                                <TableBodyCell>
                                    <BundleIcon />
                                </TableBodyCell>
                                <TableBodyCell>
                                    <ProductTitle
                                        name={b.bundle_name}
                                        visible={b.visible}
                                        startDate={b.sale_start_date}
                                        endDate={b.sale_end_date}
                                        qtyLeft={b.qty_left}
                                        timezone={event.time_zone}
                                        accessCode={b.access_code}
                                    />
                                </TableBodyCell>
                                <TableBodyCell colSpan={4}>{b.qty_sold + ' / ' + b.total_available}</TableBodyCell>
                            </TableBodyRow>
                        ))}

                        {state.status === 'success' && state.bundles.length === 0 && state.tickets.length === 0 && state.addons.length === 0 && (
                            <TableRow>
                                <TableCell align='center' colSpan={4}>
                                    <Typography variant='subtitle2'>No sales found</Typography>
                                </TableCell>
                            </TableRow>
                        )}

                        {state.status === 'loading' && (
                            <TableRow>
                                <TableCell align='center' colSpan={4}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        )}
                        
                        {state.status === 'error' && (
                            <TableRow>
                                <TableCell align='center' colSpan={4}>
                                    <Typography variant='subtitle2'>There was a problem getting your tickets</Typography>
                                </TableCell>
                            </TableRow>
                        )}

                    </TableBody>
                </Table>
            </TableContainer>

            <FullScreenModal open={selectedProduct !== null} onClose={handleProductModalClose} title={'Edit Ticket'} cancelText='Cancel'>
                {selectedProduct !== null && (
                    <ProductEditor
                        hostId={hostId}
                        eventId={eventId}
                        productId={selectedProduct}
                        onCancel={handleProductModalClose}
                        onEdit={handleProductUpdate}
                        onDelete={handleProductUpdate}
                    />
                )}
            </FullScreenModal>

            <BundleEditModal
                open={selectedBundle !== null}
                eventId={eventId}
                bundleId={selectedBundle}
                onUpdate={handleBundleUpdate}
                onClose={handleBundleModalClose}
            />

        </Root>
    )
}


const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '72px',
    minHeight: 200
});

const TableHeadCell = styled(TableCell)(({ theme }) => ({
    ...theme.typography.subHeader
}));

const TableBodyRow = styled(TableRow)(({ theme }) => ({
    '&:hover': {
        backgroundColor: theme.palette.action.hover
    }
}));

const TableBodyCell = styled(TableCell)({
    whiteSpace: 'nowrap',
    '&:hover': {
        cursor: 'pointer'
    }
});

const ProgressBar = styled(LinearProgress)(({ theme }) => ({
    height: 12,
    backgroundColor: theme.palette.grey[300],
    width: 120
}));


const BundleIcon = styled(LocalMallIcon)(({ theme }) => ({}));
BundleIcon.defaultProps = { color: 'secondary' };

const TicketIcon = styled(ConfirmationNumberIcon)(({ theme }) => ({}));
TicketIcon.defaultProps = { color: 'secondary' };

const AddonIcon = styled(AddShoppingCartIcon)(({ theme }) => ({}));
AddonIcon.defaultProps = { color: 'secondary' };