import React from 'react';

// Material-UI
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell as _TableCell } from '@material-ui/core';
import { styled, Paper, CircularProgress, Typography } from '@material-ui/core';

// Components
import { ControlBar, ControlBarHeader } from 'components/ui/layout';
import PayoutChip from 'components/payouts/PayoutChip';

// Utility
import useExpresso from 'hooks/useExpresso';
import { formatPrice } from 'utility/numbers';
import moment from 'moment-timezone';


export default function HostPayouts(props) {
    const hostId = props.hostId;

    const [data, loading, error] = useExpresso(`/apiv2/hosts/${hostId}/payouts?limit=5&tz=${moment.tz.guess()}`, null, null, null, [hostId]);


    return (
        <Root>
            <ControlBar>
                <ControlBarHeader>Recent Payouts</ControlBarHeader>
            </ControlBar>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableHeadCell>EVENT</TableHeadCell>
                            <TableHeadCell align='right'>TOTAL</TableHeadCell>
                            <TableHeadCell align='left'></TableHeadCell>
                            <TableHeadCell align='right'>WHEN</TableHeadCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {data && data.length > 0 && data.map(p => (
                            <Payout key={p.payout_id} {...p} />
                        ))}

                        {data && data.length === 0 && !loading && !error && (
                            <TableRow>
                                <TableCell align='center' colSpan={6}>
                                    <Typography variant='subtitle2'>No payouts found</Typography>
                                </TableCell>
                            </TableRow>
                        )}

                        {loading && (
                            <TableRow>
                                <TableCell align='center' colSpan={6}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        )}
                        
                        {error && (
                            <TableRow>
                                <TableCell align='center' colSpan={6}>
                                    <Typography variant='subtitle2'>There was a problem getting your payouts</Typography>
                                </TableCell>
                            </TableRow>
                        )}

                    </TableBody>
                </Table>
            </TableContainer>
        </Root>
    )
}


function Payout(props) {

    const date = moment(props.payout_date).get('year') === moment().get('year')
        ? moment(props.payout_date).format('MMM Do')
        : moment(props.payout_date).format('MMM Do YYYY')
    
    return (
        <TableRow>
            <TableCell>{props.event_title}</TableCell>
            <TableCell align='right'>{formatPrice(props.payout_amt)}</TableCell>
            <TableCell align='left'>
                <PayoutChip
                    payoutDate={props.payout_date}
                    finalPayout={Boolean(props.final_payout_flag)}
                />
            </TableCell>
            <TableCell align='right'>{date}</TableCell>
        </TableRow>
    )
}


const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '72px',
    minHeight: 200
});

const TableHeadCell = styled(_TableCell)(({ theme }) => ({
    ...theme.typography.subHeader
}));

const TableCell = styled(_TableCell)(({ theme }) => ({
    whiteSpace: 'nowrap'
}));