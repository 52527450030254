import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
    overrides: {
        MuiCardActions: {
            root: {
                justifyContent: 'flex-end'
            }
        }
    },
    props: {
        MuiButtonBase: {
            color: 'secondary'
        },
        MuiTextField: {
            color: 'secondary'
        },
        MuiBadge: {
            // The default value (rectangle) is deprecated and results in errors on the console everytime a Badge is rendered
            // This sets the default value to the correct value and prevents error messages
            overlap: 'rectangular'
        }
    },
    palette: {
        primary: {
            main: '#39E991',
        },
        secondary: {
            main: '#1976d2',
            light: '#2FA3F2',
        },
        background: {
            light: '#363636',
            dark: '#262626',
            card: 'white'
        },
        action: {
            hover: '#d5d5d5'
        },
        success: {
            main: "#388e3c"
        },
        status: {
            error: '#d32f2f',
            success: '#388e3c',
            disabled: '#a0a0a0'
        }
    },
    typography: {
        header: {
            fontSize: '1.3rem'
        },
        subHeader: {
            fontSize: '0.8rem',
            color: '#616161'
        }
    }
});