import React from 'react';
import { Grid } from '@material-ui/core';

// Components
import { AdminPage } from 'components/ui/layout';
import OrderDownload from 'components/admin/OrderDownload';
import AdminStats from 'components/admin/AdminStats';
import MonthlyReport from 'components/admin/MonthlyReport';
import MonthlyPosReport from 'components/admin/MonthlyPosReport';


export default function Reports() {

    return (
        <AdminPage>
            <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                    <OrderDownload />
                </Grid>
                <Grid item xs={12} md={8}>
                    <AdminStats />
                </Grid>
                <Grid item xs={12}>
                    <MonthlyReport />
                </Grid>
                <Grid item xs={12}>
                    <MonthlyPosReport />
                </Grid>
            </Grid>
        </AdminPage>
    )
}