import React, { useState, useEffect } from 'react';

// Components
import AdminEventList from 'components/admin/AdminEventList';

// Utility
import fetchExpresso from 'utility/fetchExpresso';
import { useLocation } from 'react-router-dom';
import { AdminPage } from 'components/ui/layout';


export default function AdminEvents() {
    const location = useLocation();

    // ===== Get number of events that need payouts ===== //

    const [payoutCount, setPayoutCount] = useState(0);

    const getPayoutCount = () => {
        fetchExpresso('/admin/reports/payout_count')
            .then(res => res.json())
            .then(data => {
                setPayoutCount(data?.unpaid_events_count || 0);
            })
            .catch(() => null)
    };

    useEffect(() => { getPayoutCount(); }, []);


    // ===== Check url query params for the default AdminEventList tab to open first ===== //

    const [defaultTab, setDefaultTab] = useState(null); // recent, future, past, unpaid

    useEffect(() => {
        if (location.search.startsWith('?tab=')) {
            const tab = location.search.slice(5);
            if (['recent', 'future', 'past', 'unpaid'].includes(tab)){
                setDefaultTab(tab);
            } else {
                setDefaultTab('recent');
            }
        } else {
            setDefaultTab('recent');
        }
    }, [location.search]);

    if (defaultTab === null) return null;


    return (
        <AdminPage>
            <AdminEventList
                payoutCount={payoutCount}
                defaultTab={defaultTab}
                refreshPayoutCount={getPayoutCount}
            />
        </AdminPage>
    )
}