import React from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Divider, CircularProgress } from '@material-ui/core';

// Utility
import useExpresso from 'hooks/useExpresso';


export default function UserList(props) {
    // Props
    const { dependency, onUserSelect } = props;

    // Fetch users by event ID
    const [users, loading, error]  = useExpresso(`/apiv2/users/`, 'GET', null, null, [dependency]);

    // Utility
    const classes = useStyles();

    // RETURN: Loading
    if (loading) {
        return (
            <div className={classes.status}>
                <CircularProgress disableShrink/>
            </div>
        )
    }
    
    // RETURN: Error
    if (error || !users) {
        return (
            <div className={classes.status}>
                <p>There was a problem loading users</p>
            </div>
        )
    };

    // RETURN: No users
    if (users.length === 0) {
        return (
            <div className={classes.status}>
                <p>No users found</p>
            </div>
        )
    }

    // RETURN: UserList
    return (
        <Paper>
            <div className={classes.tableHeader}>
                <Grid container className={classes.subHeader}>
                    <Grid item md={1} className={classes.tableRowContent}>ADMIN</Grid>
                    <Grid item md={3} className={classes.tableRowContent}>NAME</Grid>
                    <Grid item md={4} className={classes.tableRowContent}>HOST</Grid>
                    <Grid item md={4} className={classes.tableRowContent}>EMAIL</Grid>
                </Grid>
            </div>
            <div className={classes.tableBody}>
                {
                    users.map(user => (
                        <div key={user.user_id} onClick={() => onUserSelect(user.user_id)}>
                            <Grid container className={classes.tableBodyRow} spacing={1}>
                                <Grid item md={1} xs={12} className={classes.tableRowContent}>{
                                    user.super_admin ? <div className={classes.adminIcon} /> : ''}
                                </Grid>
                                <Grid item md={3} xs={12} className={classes.tableRowContent}>{user.name}</Grid>
                                <Grid item md={4} xs={12} className={classes.tableRowContent}>{user.host_name || '-'}</Grid>
                                <Grid item md={4} xs={12} className={classes.tableRowContent}>{user.email}</Grid>
                            </Grid>
                            <Divider variant="fullWidth" />
                        </div>
                    ))
                }
            </div>
        </Paper>
    )
}

const useStyles = makeStyles(theme => ({
    status: {
        display: 'flex',
        paddingTop: theme.spacing(5),
        justifyContent: 'center'
    },
    // Table Header //
    tableHeader: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    subHeader: theme.typography.subHeader,
    // Table Body //
    tableBody: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    tableBodyRow: {
        padding: theme.spacing(2),
        '&:hover': {
            backgroundColor: theme.palette.action.hover
        },
        width: '100%',
        margin: 0
    },
    // Table Content //
    statusPublished: {
        height: '1rem',
        width: '1rem',
        backgroundColor: theme.palette.success.main,
        borderRadius: '.5rem'
    },
    statusHidden: {
        height: '1rem',
        width: '1rem',
        backgroundColor: theme.palette.grey.A200,
        borderRadius: '.5rem'
    },
    tableRowContent: {
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        paddingRight: theme.spacing(1),
        display: 'inline',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
            fontWeight: 'bold'
        }
    },
    text: {
        display: 'inline'
    },
    textHidden: {
        paddingRight: theme.spacing(1),
        display: 'inline',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    adminIcon: {
        height: '1rem',
        width: '1rem',
        backgroundColor: theme.palette.success.main,
        borderRadius: '.5rem'
    }
}))