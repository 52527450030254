import React from 'react';
import { styled, TextField as _TextField, Typography, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';


// ***** Standard TextField ***** //

export function TextField(props) {
    return (
        <SdTextField
            fullWidth
            variant='outlined'
            inputProps={props.textAlign ? { style: { textAlign: props.textAlign } } : undefined}
            {...props}
        />
    )
}

const SdTextField = _TextField;



// ***** Multi-Line TextArea w/ character counter ***** //

export function TextArea(props) {
    const { value } = props;

    const maxLength = props.maxLength || 1000;
    const error = props.error || value.length > maxLength;

    return (
        <>
            <SdTextArea
                multiline
                fullWidth
                variant='outlined'
                minRows={5}
                maxRows={5}
                error={error}
                {...props}
            />
            {value.length > maxLength
                ? <CounterError>{String(value.length)} of {maxLength}</CounterError>
                : <Counter>{String(value.length)} of {maxLength}</Counter>
            }
        </>
    )
}

const SdTextArea = styled(_TextField)(({ theme }) => ({
    "& .MuiInputBase-inputMultiline": {
        resize: 'vertical'
    }
}));

const Counter = styled(Typography)(({ theme }) => ({
    ...theme.typography.caption,
    textAlign: 'right',
    marginTop: theme.spacing(1),
    color: theme.palette.grey[700]
}));

const CounterError = styled(Typography)(({ theme }) => ({
    ...theme.typography.caption,
    textAlign: 'right',
    marginTop: theme.spacing(1),
    color: theme.palette.error.main
}));



// ***** Search Bar ***** //

export function SearchBar(props) {
    const { value, onChange, placeholder, autoFocus, onKeyPress } = props;

    return (
        <TextField
            value={value}
            onChange={e => onChange(e.target.value)}
            variant='outlined'
            size='small'
            placeholder={placeholder ?? 'Search...'}
            onKeyPress={onKeyPress}
            InputProps={{
                autoFocus: autoFocus ?? false,
                startAdornment: <SearchIcon fontSize="small" />,
                endAdornment: (
                    <IconButton
                        size="small"
                        style={{ visibility: value ? 'visible' : 'hidden' }}
                        onClick={() => onChange('')}
                    >
                        <ClearIcon fontSize="small" />
                    </IconButton>
                ),
            }}
        />
    )
}