import moment from 'moment-timezone';


export function validateEmail(email) {
    if (email === null || email === "") {
        // error: empty field
        return false
    }
    else if (email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        // valid.
        if (email.slice(-4) === '.con') {
            return false
        }
        return true
    }
    else {
        // error: invalid format
        return false
    }
};

export function validateInt(num) {
    if (num && !isNaN(num)) {
        return true;
    } else {
        return false;
    }
}

export function validateString(str) {
    if (str) { return true }
    else { return false }
}

export function validatePostalCode(postalCode, region) {
    if (region === "CA" || region === 'Canada') {
        if (postalCode.match(/([ABCEGHJKLMNPRSTVXY]\d)([ABCEGHJKLMNPRSTVWXYZ]\d){2}/i) && postalCode.length === 6) {
            return true
        }
    }
    else if (region === "US" || region === 'United States') {
        if (postalCode.match("^\\d{5}(-{0,1}\\d{4})?$")) {
            return true
        }
    }
    return false
}

export function getMomentFromDateAndTime(date, time) {
    const d = moment(new Date(date));
    const t = moment(new Date(time));

    const combo = moment()
        .set('year', d.get('year'))
        .set('month', d.get('month'))
        .set('date', d.get('date'))
        .set('hour', t.get('hour'))
        .set('minute', t.get('minute'))

    return combo;
}

export function validateURL(url) {
    const _url = url.trim();
    return (_url.startsWith('http://') || _url.startsWith('https://'));
}

export function validatePhone(phone) {
    return (phone.length === 10 || phone.length === 11 || phone.length === 12);
}

export function validateSelect(select) {
    return select !== '';
}