import React, { useState, useEffect } from 'react';

// Material-UI
import { Box, Grid, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

// Components
import PerMinuteOrders from 'components/admin/PerMinuteOrders';
import PerMinuteTaps from 'components/admin/PerMinuteTaps';
import PerMinuteScans from 'components/admin/PerMinuteScans';
import TopHosts from 'components/admin/TopHosts';
import TopEvents from 'components/admin/TopEvents';
import ScanStatus from 'components/admin/ScanStatus';
import EmailStatus from 'components/admin/EmailStatus';
import SitemapStatus from 'components/admin/SitemapStatus';
import RecentOrders from 'components/admin/RecentOrders';
import RecentScans from 'components/admin/RecentScans';
import TicketsByDay from 'components/admin/TicketsByDay';
import ScansByEvent from 'components/admin/ScansByEvent';

// Utility
import fetchExpresso from 'utility/fetchExpresso';
import { AdminPage } from 'components/ui/layout';


export default function Admin(props) {
    const [payoutCount, setPayoutCount] = useState(0);

    const getPayoutCount = () => {
        fetchExpresso('/admin/reports/payout_count')
            .then(res => res.json())
            .then(data => {
                setPayoutCount(data?.unpaid_events_count || 0);
            })
            .catch(() => null)
    };

    useEffect(() => { getPayoutCount(); }, []);


    return (
        <AdminPage>

            {payoutCount > 0 && (
                <Box mb={2}>
                    <Alert
                        severity='error'
                        action={
                            <Button color="inherit" size="small" onClick={() => props.history.push('/admin/events?tab=unpaid')}>
                            View
                            </Button>
                        }
                    >
                        {payoutCount === 1
                            ? `There is 1 event that needs to be paid out`
                            : `There are ${payoutCount} events that need to be paid out`
                        }
                    </Alert>
                </Box>
            )}

            <Grid container spacing={4}>
                <Grid item xs={6} md={4}>
                    <PerMinuteOrders />
                </Grid>
                <Grid item xs={6} md={4}>
                    <PerMinuteTaps />
                </Grid>
                <Grid item xs={6} md={4}>
                    <PerMinuteScans />
                </Grid>
                <Grid item xs={12}>
                    <TicketsByDay />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TopEvents />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TopHosts />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ScansByEvent />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ScanStatus />
                </Grid>
                <Grid item xs={12} md={6}>
                    <EmailStatus />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SitemapStatus />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <RecentOrders />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <RecentScans />
                </Grid>
            </Grid>

        </AdminPage>
    )
}