import React, { useState, useEffect } from 'react';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';

// Components
import ReceiptTable from 'components/tables/ReceiptTable';
import fetchExpresso from 'utility/fetchExpresso';


export default function ReceiptPreview(props) {
    const { hostId, eventId, price, name, pos } = props;
    const title = props.title || 'Pricing Breakdown';

    const ticketCount = props.ticketCount || 1;

    const [state, setState] = useState(null);

    const c = useStyles();

    // Get host pricing information
    useEffect(() => {
        fetchExpresso(`/apiv2/events/${eventId}`)
            .then(async (res) => {
                if (res.status !== 200) return;

                const data = await res.json();

                setState({
                    b4t_fee_order_amt:          data.b4t_fee_order_amt, // No POS equivalent since we don't use this value anymore. We could probably remove this line
                    b4t_fee_line_item_amt:      pos ? data.pos_b4t_fee_line_item_amt : data.b4t_fee_line_item_amt,
                    b4t_fee_order_pct:          pos ? data.pos_b4t_fee_order_pct     : data.b4t_fee_order_pct,
                    b4t_fee_max_amt:            pos ? data.pos_b4t_fee_max_amt       : data.b4t_fee_max_amt,
                    b4t_fee_min_amt:            pos ? data.pos_b4t_fee_min_amt       : data.b4t_fee_min_amt,
                    cc_fee_order_pct:           pos ? data.pos_cc_fee_order_pct      : data.cc_fee_order_pct,
                    cc_fee_order_amt:           pos ? data.pos_cc_fee_order_amt      : data.cc_fee_order_amt,
                    tax_name:                   data.tax_name || 'Tax',
                    tax_rate:                   pos ? data.pos_tax_rate              : data.tax_rate,
                    taxable_b4t_fees:           data.taxable_b4t_fees,
                    taxable_products:           pos ? data.pos_taxable_products      : data.taxable_products,
                    taxes_included_in_product:  data.taxes_included_in_product,
                    host_absorbs_b4t_fees:      pos ? data.pos_host_absorbs_b4t_fees : data.host_absorbs_b4t_fees,
                    host_absorbs_cc_fees:       pos ? data.pos_host_absorbs_cc_fees  : data.host_absorbs_cc_fees,
                })
            })
            .catch(() => null)
    }, [hostId, eventId, pos]);

    
    let products = null;
    let orderDetails = null;

    // Calculate Fee breakdown if host pricing details have returned
    if (state) {
        const sub_total = Number(price);
        const paidTransaction = sub_total > 0;

        // Our Fees
        const b4tFeeTaxMultiplier = state.taxable_b4t_fees ? 1.13 : 1;
        let our_order_percent = sub_total * state.b4t_fee_order_pct;

        if (our_order_percent > 0) {
            if (state.b4t_fee_min_amt && state.b4t_fee_min_amt > our_order_percent) {
                our_order_percent = state.b4t_fee_min_amt;
            }
    
            if (state.b4t_fee_max_amt && state.b4t_fee_max_amt < our_order_percent) {
                our_order_percent = state.b4t_fee_max_amt;
            }
        }

        const our_product_flat = state.b4t_fee_line_item_amt * ticketCount;
        const our_order_flat = state.b4t_fee_order_amt;
        const our_fee_total = (sub_total)
            ? Number(((our_order_percent + our_product_flat + our_order_flat) * b4tFeeTaxMultiplier).toFixed(2)) // Only show fee if there is a subtotal
            : 0;
        const ourFeeTotalCalc = state.host_absorbs_b4t_fees ? 0 : our_fee_total;

        // Taxes
        const tax_on_products = (state.taxable_products && !state.taxes_included_in_product) // Only show fee if there is a subtotal
            ? Number((sub_total * state.tax_rate).toFixed(2))
            : 0;

        // Credit Card Fees
        let cc_percent_fee = 0, cc_flat_fee = 0, cc_fee_total = 0, ccFeeTotalCalc = 0;

        if (paidTransaction) {
            if (state.host_absorbs_cc_fees) {
                // ** CC Fee is included in the ticket price ** //
                cc_percent_fee = (sub_total + tax_on_products + ourFeeTotalCalc) * state.cc_fee_order_pct
                cc_flat_fee = state.cc_fee_order_amt;
                cc_fee_total = Number(
                    (cc_percent_fee + cc_flat_fee).toFixed(2)
                );
                ccFeeTotalCalc = 0;
            } else {
                // ** CC Fee is passed on to the customer ** //
                // Calc CC fee considering that increasing the final amount also increases the Stripe fee
                const originalCharge = sub_total + tax_on_products + ourFeeTotalCalc;
                const adjustedCharge = (originalCharge + state.cc_fee_order_amt) / (1 - state.cc_fee_order_pct);
            
                cc_percent_fee = adjustedCharge * state.cc_fee_order_pct;
                cc_flat_fee = state.cc_fee_order_amt;
                cc_fee_total = Number(
                    (cc_percent_fee + cc_flat_fee).toFixed(2)
                );
    
                ccFeeTotalCalc = cc_fee_total;
            }
        }

        // Totals
        const total = Number((sub_total + ourFeeTotalCalc + tax_on_products + ccFeeTotalCalc).toFixed(2));


        // Host Absorbs (Total)
        let hostTotal = 0;
        let fees = 0;

        if (state.host_absorbs_b4t_fees) {
            fees = Number((fees + our_fee_total).toFixed(2));
        }

        if (state.host_absorbs_cc_fees) {
            fees = Number((fees + cc_fee_total).toFixed(2));
        }

        hostTotal = Number((sub_total + tax_on_products - fees).toFixed(2));


        // Data for ReceiptTable
        products = [{ product_name: (name.trim() || 'Ticket'), qty: 1, price: sub_total, tran_detail_id: 1 }];
        orderDetails = { sub_total, tax_name: state.tax_name, tax_on_products, our_fee_total, cc_fee_total, total, host_absorbs_b4t_fees: state.host_absorbs_b4t_fees, host_absorbs_cc_fees: state.host_absorbs_cc_fees, status_financial: 1000, hostTotal: hostTotal };
    }

    return (
        <div>
            <p className={c.header}>{title}</p>
            {
                state && <ReceiptTable pos={pos} products={products} orderDetails={orderDetails} />
            }
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    header: {
        ...theme.typography.header,
        marginTop: 0
    }
}))