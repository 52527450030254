import React, { createContext, useContext, useState } from 'react';

export const NotificationContext = createContext();

export function useNotification() {
  return useContext(NotificationContext);
}

export function NotificationProvider(props) {
    // State
    const [notification, setNotification] = useState({open: false, message: ''});

    // Setters
    function createNotification(message) {
        setNotification({open: true, message});
    }

    function closeNotification() {
        setNotification({open: false, message: ''});
    }

    return (
        <NotificationContext.Provider value={{notification, createNotification, closeNotification}}>
            {props.children}
        </NotificationContext.Provider>
    )
}