import React, { useState, useEffect } from 'react';

// Material UI
import { styled, Box, FormControlLabel, Checkbox, MenuItem, IconButton } from '@material-ui/core';
import { Card, CardHeader, CardContent, CardActions } from '@material-ui/core';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

// Components
import { useTextInput, useNumberInput, useToggle } from 'hooks/forms';
import { TextField } from 'components/ui/inputs';
import { ActionButton } from 'components/ui/buttons';
import { SubmitButton, CancelButton } from 'components/ui/forms';

// Utility
import { useNotification } from 'context/notification';
import moment from 'moment-timezone';

// API
import * as AuditAPI from 'pages/admin/audit/AuditAPI';
import { formatPrice } from 'utility/numbers';



export default function AuditIncidentalsCard(props) {
    const { eventId, onUpdate } = props;

    const [loading, setLoading] = useState(false);
    const [formOpen, setFormOpen] = useState(false);

    const [incidentals, setIncidentals] = useState([]);
    const [incidentalTypes, setIncidentalTypes] = useState([]);

    const [type, setType] = useState('default');
    const description = useTextInput();
    const price = useNumberInput();
    const taxEnabled = useToggle();

    const tax = taxEnabled.checked ? (price.value * 0.13).toFixed(2) : 0;
    const total = taxEnabled.checked ? (price.value * 1.13).toFixed(2) : price.value;

    const formValid = (type !== 'default' && description.value.trim() !== '' && price.value.trim() !== '');


    const { createNotification } = useNotification();


    useEffect(() => {
        AuditAPI.getIncidentalTypes()
            .then(types => setIncidentalTypes(types))
            .catch(e => createNotification(String(e)));
        
        AuditAPI.getIncidentalsForEvent(eventId)
            .then(incidentals => setIncidentals(incidentals))
            .catch(e => createNotification(String(e)));
    // eslint-disable-next-line
    }, []);


    const resetIncidentalForm = () => {
        setType('default');
        description.setValue('');
        price.setValue('');
        taxEnabled.setValue(false);
        setFormOpen(false);
    };

    const createIncidental = async () => {
        try {
            setLoading(true);
            
            await AuditAPI.createIncidental(eventId, {
                type: type,
                description: description.value,
                subtotal: Number(price.value),
                tax: Number(tax),
                total: Number(total)
            });

            const updatedIncidentals = await AuditAPI.getIncidentalsForEvent(eventId);

            setIncidentals(updatedIncidentals);
            resetIncidentalForm();
            onUpdate();
        } catch(e) {
            createNotification(String(e));
        }

        setLoading(false);
    };

    const deleteIncidental = async (incId) => {
        try {
            setLoading(true);
            
            await AuditAPI.deleteIncidental(incId, eventId);

            const updatedIncidentals = await AuditAPI.getIncidentalsForEvent(eventId);

            setIncidentals(updatedIncidentals);
            onUpdate();
        } catch(e) {
            createNotification(String(e));
        }

        setLoading(false);
    };

    return (
        <Card>
            <CardHeader title='Incidentals' />
            <CardContent>
                <TableContainer component={Box}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <HeaderCell width={60}>WHEN</HeaderCell>
                                <HeaderCell width={100}>TYPE</HeaderCell>
                                <HeaderCell>DESCRIPTION</HeaderCell>
                                <HeaderCell width={60} align='right'>SUBTOTAL</HeaderCell>
                                <HeaderCell width={74} align='right'>TAX</HeaderCell>
                                <HeaderCell width={60} align='right'>TOTAL</HeaderCell>
                                <HeaderCell width={10} align='right'></HeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {incidentals.map(i => (
                                <TableRow key={i.id}>
                                    <BodyCell>{moment(i.when * 1000).format('MMM DD')}</BodyCell>
                                    <BodyCell>{i.type}</BodyCell>
                                    <Description>{i.desc}</Description>
                                    <BodyCell align='right'>{formatPrice(i.subtotal)}</BodyCell>
                                    <BodyCell align='right'>{formatPrice(i.tax)}</BodyCell>
                                    <BodyCell align='right'>{formatPrice(i.total)}</BodyCell>
                                    <BodyCell align='right' style={{ padding: 0 }}>
                                        <IconButton size='small' disabled={loading} onClick={() => deleteIncidental(i.id)}>
                                            <ClearIcon />
                                        </IconButton>
                                    </BodyCell>
                                </TableRow>
                            ))}
                            {incidentals.length === 0 && (
                                <TableRow>
                                    <TableCell align='center' colSpan={6}>No incidentals found</TableCell>
                                </TableRow>
                            )}
                            {formOpen && (
                                <TableRow>
                                    <TableCell align='right'></TableCell>

                                    <TableCell>
                                        <TextField select size='small' variant='outlined' placeholder='Type' fullWidth value={type} onChange={(e) => setType(e.target.value)}>
                                            <MenuItem value='default' disabled>Type</MenuItem>
                                            {incidentalTypes.map(i => (
                                                <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>
                                            ))}
                                        </TextField>
                                    </TableCell>

                                    <TableCell>
                                        <TextField size='small' placeholder='Description' {...description.formProps} />
                                    </TableCell>

                                    <TableCell style={{ paddingLeft: 0, paddingRight: 0 }} align='right'>
                                        <TextField size='small' placeholder='Subtotal' {...price.formProps} />
                                    </TableCell>

                                    <TableCell align='right'>
                                        <FormControlLabel
                                            control={<Checkbox {...taxEnabled.formProps} />}
                                            label={taxEnabled.checked ? formatPrice(tax) : 'Tax?'}
                                            style={{ marginRight: 0 }}
                                        />
                                    </TableCell>

                                    <TableCell align='right'>{formatPrice(total)}</TableCell>

                                    <TableCell></TableCell>

                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
                {formOpen ? (
                    <CardActions>
                        <CancelButton onClick={resetIncidentalForm} disabled={loading}>Cancel</CancelButton>
                        <SubmitButton onClick={createIncidental} disabled={formValid === false || loading}>Save</SubmitButton>
                    </CardActions>
                ) : (
                    <CardActions>
                        <ActionButton onClick={() => setFormOpen(true)}>New Incidental</ActionButton>
                    </CardActions>
                )}
        </Card>
    )
}

const HeaderCell = styled(TableCell)(({ theme }) => ({
    ...theme.typography.subHeader,
    paddingTop: 0,
    fontWeight: 'bold',
    borderBottom: '0.5px solid'
}));

const BodyCell = styled(TableCell)(({ theme }) => ({
    border: 'none'
}));

const Description = styled(TableCell)(({ theme }) => ({
    border: 'none',
    minWidth: 300
}));