import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useNotification } from 'context/notification';
import fetchExpresso from 'utility/fetchExpresso';
import ProductForm from 'components/forms/ProductForm';
import ConfirmAction from 'components/popups/ConfirmAction';
import moment from 'moment-timezone';


export default function ProductEditor(props) {
    const { productId, hostId, eventId, onEdit, onDelete, onCancel } = props;

    const [loading, setLoading] = useState(false); // Used when updating a product
    const [confirmDelete, setConfirmDelete] = useState(false);

    const [init, setInit] = useState({
        status: 'loading', // loading, error, success
        enableSponsor: false,
        deletable: false,
        defaultValues: null
    });

    const { createNotification } = useNotification();


    useEffect(() => {
        (async () => {
            try {
                const [productResponse, eventResponse] = await Promise.all([
                    fetchExpresso(`/apiv2/products/${productId}`),
                    fetchExpresso(`/apiv2/events/${eventId}`),
                ]);
    
                if (productResponse.status !== 200 || eventResponse.status !== 200) {
                    throw new Error();
                }

                const product = await productResponse.json();
                const event = await eventResponse.json();

                let audience;

                if (product.access_code) {
                    audience = 'accessCode';
                } else if (product.visible === 1) {
                    audience = 'visible';
                } else {
                    audience = 'hidden'
                }

                let posVisible = 'hidden';
                if (product.pos_visible === 1) posVisible = 'visible';

                setInit({
                    status: 'success',
                    enableSponsor: event.sponsor_module_flag === 1,
                    deletable: product.bundle_count === 0 && product.quantity_sold === 0,
                    defaultValues: {
                        name: product.prod_name,
                        price: product.price,
                        free: !product.price,
                        type: product.prod_type_id,
                        description: product.prod_desc,
                        startDate: moment(product.scan_start_date),
                        endDate: moment(product.scan_end_date),
                        eventStartDate: moment(event.start_date),
                        eventEndDate: moment(event.end_date),
                        saleStartDate: moment(product.sale_start_date),
                        saleEndDate: moment(product.sale_end_date),
                        quantity: product.qty_total_available,
                        minOrder: product.qty_min_purchase,
                        maxOrder: product.qty_max_purchase,
                        overrideDates: Boolean(product.scan_date_override),
                        multipleScan: Boolean(product.multi_day_entry),
                        image: product.sponsor_image_url,
                        audience: audience,
                        accessCode: product.access_code,
                        quantitySold: product.quantity_sold,
                        posVisible: posVisible,
                        posName: product.pos_prod_name,
                        posPrice: product.pos_price,
                    },
                    createdAtMsg: `Created ${moment(product.created_at).fromNow()} by ${product.created_by}`,
                    updatedAtMsg: `Updated ${moment(product.updated_at).fromNow()}` + (product.updated_by ? ` by ${product.updated_by}` : '')
                })
            }
            catch(e) {
                setInit({
                    status: 'error',
                    defaultValues: null
                })
            }
        })()
    }, [productId, eventId]);

    
    const handleEdit = (formData) => {
        if (loading) return;
        setLoading(true);

        fetchExpresso(`/apiv2/products/${productId}`, {
            method: 'PUT',
            body: formData
        })
            .then(res => {
                if (res.status === 200) {
                    createNotification('Ticket Updated');
                    onEdit();
                } else {
                    createNotification('There was a problem updating your ticket');
                    setLoading(false);
                }
            })
            .catch(() => {
                createNotification('There was a problem updating your ticket');
                setLoading(false);
            })
    }

    const handleDelete = () => {
        setConfirmDelete(false);
        setLoading(true);

        fetchExpresso(`/apiv2/products/${productId}`, {
            method: 'DELETE'
        })
            .then(async (res) => {
                if (res.status === 200) {
                    createNotification('Deleted ticket');
                    onDelete(productId);
                } else {
                    const data = await res.json();
                    createNotification(data.message ?? 'There was a problem deleting your ticket');
                    setLoading(false);
                }
            })
            .catch(() => {
                createNotification('There was a problem deleting your ticket');
                setLoading(false);
            })
    }


    // Waiting for default values to load
    if (init.status === 'loading') {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' width={1} height={1}>
                <CircularProgress />
            </Box>
        )
    }
    
    // Error loading data for product form
    if (init.status === 'error') {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' width={1} height={1}>
                <Typography align='center'>There was a problem loading your form</Typography>
            </Box>
        )
    }
    
    return (
        <>
            <ProductForm
                editMode
                deletable={init.deletable}
                defaultValues={init.defaultValues}
                createdAtMsg={init.createdAtMsg}
                updatedAtMsg={init.updatedAtMsg}
                enableSponsor={init.enableSponsor}
                onSubmit={handleEdit}
                onDelete={() => setConfirmDelete(true)}
                onCancel={onCancel}
                hostId={hostId}
                eventId={eventId}
                loading={loading}
            />

            <ConfirmAction
                open={confirmDelete} onConfirm={handleDelete} onCancel={() => setConfirmDelete(false)}
                destructive
                title='Are you sure you want to delete this ticket?'
                description='You cannot recover a ticket once it is deleted'
                confirmText='Delete'
            />
        </>
    );
}