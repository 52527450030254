import fetchExpresso from "utility/fetchExpresso";
import moment from 'moment-timezone';


const FUTURE_WEEKS = 8;
const PAST_WEEKS = 4;


export async function getAuditData() {

    const start = moment().tz('America/Toronto').subtract(PAST_WEEKS, 'weeks').startOf('isoWeek').format('YYYY-MM-DDT00:00:00');
    const end   = moment().tz('America/Toronto').add(FUTURE_WEEKS, 'weeks').endOf('isoWeek').format('YYYY-MM-DDT23:59:59');

    const response = await fetchExpresso(`/admin/operations/events?start=${start}&end=${end}`)

    if (response.status === 200) {
        const events = await response.json();
        const audit = generateAuditData(events);

        return audit;
    }
    else if (response.status === 400) {
        throw new Error(`[400] Bad request`)
    }
    else if (response.status === 500) {
        throw new Error(`[500] There was a problem on our end`)
    }
    else {
        throw new Error(`[${response.status}] Something went wrong`)
    }

}


export async function getAuditDataForEvent(id) {
    const response = await fetchExpresso(`/admin/operations/events/${id}`)

    if (response.status === 200) {
        const results = await response.json();
        return results.event;
    }
    else if (response.status === 400) {
        throw new Error(`[400] Bad request`)
    }
    else if (response.status === 500) {
        throw new Error(`[500] There was a problem on our end`)
    }
    else {
        throw new Error(`[${response.status}] Something went wrong`)
    }
}


export async function editEvent(id, params) {
    try {
        const response = await fetchExpresso(`/admin/operations/events/${id}`, {
            method: 'PUT',
            body: {
                phoneCount:  params.phoneCount,
                readerCount: params.readerCount,
                note:        params.note,
                audited:     params.audited,
                returned:    params.returned,
            }
        })

        if (response.status === 200) {
            return null;
        } else {
            const data = await response.json();
            throw new Error(`[${response.status}] ${data.message}`)
        }
    }
    catch(e) {
        throw e
    }
}


export async function getIncidentalTypes() {
    try {
        const response = await fetchExpresso(`/apiv2/incidental_types/`)

        if (response.status === 200) {
            const data = await response.json();

            const types = data.types
                .filter(i => [2,3,999].includes(i.inc_type_id))
                .map(t => ({ id: t.inc_type_id, name: t.type_name })); // Rename fields

            return types;
        } else {
            const data = await response.json();
            throw new Error(`[${response.status}] ${data.message}`)
        }
    }
    catch(e) {
        throw e
    }
}


export async function getIncidentalsForEvent(id) {
    try {
        const response = await fetchExpresso(`/apiv2/events/${id}/incidentals/`)

        if (response.status === 200) {
            const data = await response.json();

            const incidentals = data.incidentals
                .filter(i => [2,3,999].includes(i.inc_type_id))
                .map(i => ({
                    id: i.inc_id,
                    type: i.type_name,
                    desc: i.inc_desc,
                    subtotal: i.sub_total,
                    tax: i.tax_total,
                    total: i.total,
                    when: i.created_at
                }));

            return incidentals;
        } else {
            const data = await response.json();
            throw new Error(`[${response.status}] ${data.message}`)
        }
    }
    catch(e) {
        throw e
    }
}


export async function createIncidental(eventId, params) {
    try {
        const response = await fetchExpresso(`/apiv2/events/${eventId}/incidentals/`, {
            method: 'POST',
            body: {
                type:        params.type,
                description: params.description,
                subtotal:    params.subtotal,
                tax:         params.tax,
                total:       params.total,
            }
        })

        if (response.status === 200) {
            return null;
        } else {
            const data = await response.json();
            throw new Error(`[${response.status}] ${data.message}`)
        }
    }
    catch(e) {
        throw e
    }
}

export async function deleteIncidental(incId, eventId) {
    try {
        const response = await fetchExpresso(`/apiv2/events/${eventId}/incidentals/${incId}`, {
            method: 'DELETE'
        })

        if (response.status === 200) {
            return null;
        } else {
            const data = await response.json();
            throw new Error(`[${response.status}] ${data.message}`)
        }
    }
    catch(e) {
        throw e
    }
}


function generateAuditData(events) {
    const futureWeeks = [];
    const pastWeeks = [];

    const now = moment().tz('America/Toronto');
    const lastYear = moment().tz('America/Toronto').subtract(1, 'year');
    const currentWeek = now.isoWeek();
    const isoWeeksThisYear = now.isoWeeksInYear();
    const isoWeeksLastYear = lastYear.isoWeeksInYear();

    // Generate nine 1-week periods including the current week
    // We use Monday - Sunday (ISO Week)
    for (let i = 0; i <= FUTURE_WEEKS; i++) {
        const date = moment().tz('America/Toronto').add(i, 'weeks');
    
        futureWeeks.push({
            weeksOut: i,
            start: date.startOf('isoWeek').format('MMM D'),
            end: date.endOf('isoWeek').format('MMM D'),
            totalPhones: 0,
            totalReaders: 0,
            totalUnconfirmed: 0,
            events: []
        })
    }

    // Generate nine 1-week periods including the current week
    // We use Monday - Sunday (ISO Week)
    for (let i = 1; i <= PAST_WEEKS; i++) {
        const date = moment().tz('America/Toronto').subtract(i, 'weeks');
    
        pastWeeks.push({
            weeksOut: i * -1,
            start: date.startOf('isoWeek').format('MMM D'),
            end: date.endOf('isoWeek').format('MMM D'),
            totalPhones: 0,
            totalReaders: 0,
            totalUnconfirmed: 0,
            events: []
        })
    }

    // Sort events into their 1-week group by their start date
    for (const e of events) {
        const start = moment(e.start_date).tz(e.time_zone, true).tz('America/Toronto');
        const end = moment(e.end_date).tz(e.time_zone, true).tz('America/Toronto');
        const isoWeek = start.isoWeek();

        let relativeWeek = 0;


        // ** Current Week Event ** //
        if (isoWeek === currentWeek) {
            relativeWeek = 0;
        }

        // ** Future event ** //
        else if (start >= now) {
            if (isoWeek >= currentWeek) {
                // isoWeek is for the current calendar year
                relativeWeek = isoWeek - currentWeek;
            } else {
                // isoWeek is for the next calendar year
                const extendedWeek = isoWeeksThisYear + isoWeek;
                relativeWeek = extendedWeek - currentWeek;
            }
        }
        
        // ** Past event ** //
        else {
            if (isoWeek < currentWeek) {
                // isoWeek is for the current calendar year
                relativeWeek = isoWeek - currentWeek;
            } else {
                // isoWeek is for the previous calendar year
                const weeksLastYear = isoWeeksLastYear - isoWeek;
                relativeWeek = (weeksLastYear + currentWeek) * -1;
            }
        }
    
        if (relativeWeek >= 0) {
            const index = relativeWeek;

            futureWeeks[index].totalPhones += e.admin_phone_count
            futureWeeks[index].totalReaders += e.admin_reader_count

            if (e.admin_phone_count === null || e.admin_reader_count === null) {
                futureWeeks[index].totalUnconfirmed += 1; // An upcoming event has not confirmed how many devices are needed
            }

            futureWeeks[index].events.push({
                eventId: e.event_id,
                startDateUnix: start.valueOf(), // unix timestamp in milliseconds
                endDateUnix: end.valueOf(), // unix timestamp in milliseconds
                status: e.status,
                concluded: e.concluded === 1,
                event: e.event_title,
                host: e.host_name,
                province: e.prov_state,
                note: e.admin_note,
                phoneCount: e.admin_phone_count,
                readerCount: e.admin_reader_count,
                incidentalCount: e.incidental_count,
                audited: e.admin_audit_flag === 1,
                returned: e.admin_audit_return_flag === 1
            });
        }
        else {
            const index = (relativeWeek * -1) - 1;

            pastWeeks[index].totalPhones += e.admin_phone_count
            pastWeeks[index].totalReaders += e.admin_reader_count

            if (e.admin_phone_count === null || e.admin_reader_count === null) {
                pastWeeks[index].totalUnconfirmed += 1; // A past event did not confirm how many devices were needed
            }

            pastWeeks[index].events.push({
                eventId: e.event_id,
                startDateUnix: start.valueOf(), // unix timestamp in milliseconds
                endDateUnix: end.valueOf(), // unix timestamp in milliseconds
                status: e.status,
                concluded: e.concluded === 1,
                event: e.event_title,
                host: e.host_name,
                province: e.prov_state,
                note: e.admin_note,
                phoneCount: e.admin_phone_count,
                readerCount: e.admin_reader_count,
                incidentalCount: e.incidental_count,
                audited: e.admin_audit_flag === 1,
                returned: e.admin_audit_return_flag === 1
            });
        }
    }

    const data = futureWeeks.concat(pastWeeks).sort((a,b) => a.weeksOut - b.weeksOut)
    return data;
}