import React, { useState } from 'react';
import { styled, Box, Button } from '@material-ui/core';
import ProductPicker from 'components/orders/ProductPicker';
import CustomerForm from 'components/orders/CustomerForm';
import { validateString, validateEmail, validatePostalCode, validatePhone, validateSelect } from 'utility/validation';
import { useNotification } from 'context/notification';
import { ScrollRestoreContainer } from 'components/ui/layout';
import { Alert } from '@material-ui/lab';



export default function OrderCreateForm(props) {
    const { cart, customer, onCartProductUpdate, onCartBundleUpdate, onCustomerUpdate, onSubmit, onCancel } = props;

    const { createNotification } = useNotification();
    
    const [emptyCart, setEmptyCart] = useState(false);

    const [validFields, setValidFields] = useState({
        firstName: true,
        lastName: true,
        email: true,
        phone: true,
        company: true,
        address: true,
        city: true,
        province: true,
        postalCode: true,
        country: true,
    });


    const handleSubmit = () => {
        const { firstName, lastName, email, phone, company, address, city, province, postalCode, country } = props.customer;

        const newValidFields = {
            // Mandatory Fields
            firstName: validateString(firstName),
            lastName: validateString(lastName),
            email: validateEmail(email),
            // Optional Fields
            phone: validatePhone(phone) || phone.trim() === '',
            company: validateString(company) || company.trim() === '',
            address: validateString(address) || address.trim() === '',
            city: validateString(city) || city.trim() === '',
            province: validateSelect(province) || province.trim() === '',
            postalCode: validatePostalCode(postalCode, country) || postalCode.trim() === '',
            country: validateSelect(country) || country.trim() === '',
        };

        const emptyCart = cart.products.every(c => c.quantity === 0) && cart.bundles.every(c => c.quantity === 0);
        const customerValid = Object.keys(newValidFields).every(key => newValidFields[key] === true)

        if (emptyCart === false && customerValid === true) {
            onSubmit();
        } else {
            setEmptyCart(emptyCart);
            setValidFields(newValidFields);
            createNotification('Please make sure every field is filled in correctly');
        }
    };
    

    return (
        <ScrollRestoreContainer>
            <FormContainer>

                <Box mb={4}>
                    <Alert severity='warning'>
                        Keep in mind: Use this feature to create complimentary orders that don't require payment. It allows you to bypass restrictions like sold-out, hidden, or currently unavailable tickets.
                    </Alert>
                </Box>

                <Box mb={4}>
                    <ProductPicker
                        products={cart.products}
                        bundles={cart.bundles}
                        error={emptyCart}
                        status={cart.status}
                        onProductUpdate={onCartProductUpdate}
                        onBundleUpdate={onCartBundleUpdate}
                    />
                </Box>
                <Box mb={4}>
                    <CustomerForm
                        customer={customer}
                        onChange={onCustomerUpdate}
                        validFields={validFields}
                    />
                </Box>

                <ButtonContainer>
                    <CancelButton variant='outlined' onClick={onCancel}>Cancel</CancelButton>
                    <NextButton variant='contained' onClick={handleSubmit}>Next</NextButton>
                </ButtonContainer>

            </FormContainer>
        </ScrollRestoreContainer>
    )
}



const FormContainer = styled(Box)(({ theme }) => ({
    width: theme.breakpoints.values.md,
    paddingBottom: theme.spacing(10)
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
}));

const CancelButton = styled(Button)(({ theme }) => ({
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    marginLeft: theme.spacing(2),
}));
CancelButton.defaultProps = { variant: 'outlined' }

const NextButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
}));
NextButton.defaultProps = { variant: 'contained' }