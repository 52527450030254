import React from 'react';

// Material UI
import Snackbar from '@material-ui/core/Snackbar';

// Utility 
import { useNotification } from 'context/notification';


export default function Notification() {
    const { notification, closeNotification } = useNotification();

    return (
        <Snackbar
            open={notification.open}
            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            autoHideDuration={5000}
            onClose={closeNotification}
            message={notification.message}
        />
    )
}
