/**
 * Describes the current status of the account and what capabilities are enabled
 * @param {string} id Connected account id
 * @param {number} status Connected account status
 *
 * @returns {object} Object representing the connected account of a host
 */
export class ConnectedAccount {
    constructor(id, status) {

        this.id = id;
        this.status = status;
        this.paymentSystem = status === 9000 ? 'legacy' : 'connect';
        this.accountCreated = status !== 3000;


        switch (status) {

            // ***** 1xxx - Connected and ready to accept charges ***** //
            // All good
            case 1000:
                this.chargesEnabled = true;
                this.payoutsEnabled = true;
                this.onboardComplete = true;
                this.needsAttention = false;
                break;

            // All good; requires attention
            case 1001:
                this.chargesEnabled = true;
                this.payoutsEnabled = true;
                this.onboardComplete = true;
                this.needsAttention = true;
                break;

            // Can create charges, but not receive payouts
            case 1002:
                this.chargesEnabled = true;
                this.payoutsEnabled = false;
                this.onboardComplete = true;
                this.needsAttention = true;
                break;


            // ***** 2xxx - Connected but not ready to accept charges ***** //
            // Onboarding incomplete
            case 2000:
                this.chargesEnabled = false;
                this.payoutsEnabled = false;
                this.onboardComplete = false;
                this.needsAttention = true;
                break;

            // Onboarding complete, charges disabled, but payouts are enabled
            case 2001:
                this.chargesEnabled = false;
                this.payoutsEnabled = true;
                this.onboardComplete = true;
                this.needsAttention = true;
                break;

            // Onboarding complete, but charges + payouts are disabled
            case 2002:
                this.chargesEnabled = false;
                this.payoutsEnabled = false;
                this.onboardComplete = true;
                this.needsAttention = true;
                break;


            // ***** 3000 - Does not have a connect account yet ***** //
            case 3000:
                this.chargesEnabled = false;
                this.payoutsEnabled = false;
                this.onboardComplete = true;
                this.needsAttention = true;
                break;


            // ***** 9000 - Does not use Stripe Connect ***** //
            case 9000:
                this.chargesEnabled = true; // charges are created on our platform account
                this.payoutsEnabled = true; // payouts issued from our platform account
                this.onboardComplete = true; // host does not need to onboard an account
                this.needsAttention = false;
                break;

            default:
                this.chargesEnabled = false;
                this.payoutsEnabled = false;
                this.onboardComplete = false;
                this.needsAttention = true;
                break;
        }
    }
}