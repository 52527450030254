import React, { useState } from 'react';

// Material-UI
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { styled, Box, Paper, CircularProgress, Typography } from '@material-ui/core';

// Components
import { ControlBar, ControlBarButton, ControlBarHeader } from 'components/ui/layout';
import OrderDetailModal from 'components/orders/OrderDetailModal';
import FullScreenModal from 'components/popups/FullScreenModal';
import { PaymentIcon, DiscountIcon, CommentIcon } from 'components/ui/icons';

// Utility
import { useHistory } from 'react-router-dom';
import useExpresso from 'hooks/useExpresso';
import { formatPrice } from 'utility/numbers';
import moment from 'moment-timezone';


export default function RecentOrders(props) {
    const { eventId } = props;
    const [state, setState] = useState({ order: null, refreshToken: 0 });
    const [data, loading, error] = useExpresso(`/api/events/${eventId}/orders/recent/5`, null, null, null, [eventId, state.refreshToken]);

    const history = useHistory();


    const handleUpdate = () => {
        setState({ ...state, order: null, refreshToken: state.refreshToken + 1 });
    }

    const setOrder = (order) => {
        setState({ ...state, order: order });
    }

    const handleNavigate = () => history.push('orders');


    // Return recent orders
    return (
        <Root>

            <ControlBar>
                <ControlBarHeader>Recent Orders</ControlBarHeader>
                <ControlBarButton variant='outlined' onClick={handleNavigate}>View All</ControlBarButton>
            </ControlBar>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableHeadCell width={10}></TableHeadCell>
                            <TableHeadCell width={80}>#</TableHeadCell>
                            <TableHeadCell width={80} align='left'>DETAIL</TableHeadCell>
                            <TableHeadCell width={80} align='right'>TOTAL</TableHeadCell>
                            <TableHeadCell width={80} align='right'>TIX</TableHeadCell>
                            <TableHeadCell>EMAIL</TableHeadCell>
                            <TableHeadCell>NAME</TableHeadCell>
                            <TableHeadCell width={160}>WHEN</TableHeadCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {data && data.rows.length > 0 && data.rows.map(order => (
                            <TRow key={order.tran_id} onClick={() => setOrder(order.tran_id)}>
                                <TableBodyCell>
                                    <PaymentIcon status={order.status_financial} message={order.decline_message} />
                                </TableBodyCell>
                                <TableBodyCell>F{order.tran_id}</TableBodyCell>
                                <TableBodyCell align='left'>
                                    <Box display='flex'>
                                        <DiscountIcon code={order.discount_code} compedBy={order.comped_by} />
                                        <CommentIcon count={order.comment_count} />
                                    </Box>
                                </TableBodyCell>
                                <TableBodyCell align='right'>{formatPrice(order.total)}</TableBodyCell>
                                <TableBodyCell align='right'>{order.total_tickets}</TableBodyCell>
                                <TableBodyCell>{order.email}</TableBodyCell>
                                <TableBodyCell>{`${order.first_name[0]}. ${order.last_name}`}</TableBodyCell>
                                <TableBodyCell>{moment(order.created_at).fromNow()}</TableBodyCell>
                            </TRow>
                        ))}

                        {data && data.rows.length === 0 && !loading && !error && (
                            <TableRow>
                                <TableCell align='center' colSpan={7}>
                                    <Typography variant='subtitle2'>No orders found</Typography>
                                </TableCell>
                            </TableRow>
                        )}

                        {loading && (
                            <TableRow>
                                <TableCell align='center' colSpan={7}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        )}
                        
                        {error && (
                            <TableRow>
                                <TableCell align='center' colSpan={6}>
                                    <Typography variant='subtitle2'>There was a problem getting your orders</Typography>
                                </TableCell>
                            </TableRow>
                        )}

                    </TableBody>
                </Table>
            </TableContainer> 

            <FullScreenModal open={state.order ? true : false} onClose={() => setOrder(null)} title='Order Details'>
                <OrderDetailModal tranId={state.order} eventId={eventId} onUpdate={handleUpdate} />
            </FullScreenModal>
        </Root>
    )
}

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '72px',
    minHeight: 200
});

const TRow = styled(TableRow)(({ theme }) => ({
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
        cursor: 'pointer'
    }
}));

const TableHeadCell = styled(TableCell)(({ theme }) => ({
    ...theme.typography.subHeader
}));

const TableBodyCell = styled(TableCell)({
    whiteSpace: 'nowrap',
});