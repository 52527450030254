import React, { useState, useEffect } from 'react';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Link } from '@material-ui/core';

// Images
import logo from 'images/logo.png';

// Utility
import fetchExpresso from 'utility/fetchExpresso';
import ReactGA from 'react-ga';

export default function ResetPassword(props) {

    let userId = props.match.params.userId
    let passwordReset = props.match.params.passwordReset


    const classes = useStyles();

    const [errorMessage, setErrorMessage] = useState(null)
    const [linkStatus, setLinkStatus] = useState(false)
    const [updateStatus, setUpdateStatus] = useState(false)

    const [passwordState, setPasswordState] = useState({
        newPassword1: '',
        newPassword2: ''
    })

    useEffect(() => {
        fetchExpresso(`/api/auths/check_password_reset`, {
            method: 'POST',
            body: { userId, passwordReset }
        })
            .then(res => res.json())
            .then(data => {
                if (data.err === false) {
                    setLinkStatus(true)
                    setErrorMessage(null)
                } else {
                    setLinkStatus(false)
                    setErrorMessage(data.message)
                }
            })
            .catch(() => {
                setErrorMessage('Please check your connection and try again later')
            });
    }, [passwordReset, userId])

    function onPasswordChange(e) {
        const value = e.target.value;
        setPasswordState({ ...passwordState, [e.target.id]: value });
        setErrorMessage(null);
    }

    function resetPassword() {
        if (passwordState.newPassword1.length < 8) {
            setErrorMessage('New password must be at least eight characters long')
            return
        }

        if (passwordState.newPassword1 === passwordState.newPassword2) {
            fetchExpresso(`/api/auths/reset_password`, {
                method: 'POST',
                body: {
                    userId,
                    passwordReset,
                    newPassword: passwordState.newPassword1
                }
            })
                .then(res => res.json())
                .then(data => {
                    if (data.err === false) {
                        setUpdateStatus(true)
                        ReactGA.event({ category: "Authentication", action: 'Reset Password', label: 'Unauthenticated' });
                    } else {
                        setErrorMessage(data.message)
                    }
                })
                .catch(() => {
                    setErrorMessage('Please check your connection and try again later')
                });
        } else {
            setErrorMessage('Two new passwords must match')
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.imgContainer}>
                <Link href="/login">
                    <img alt='FrontDoor+ Icon' src={logo} className={classes.logo}></img>
                </Link>
            </div>

            <div className={classes.container}>
                <h2 style={{ textAlign: 'center', margin: 0 }}>Choose a new password</h2>
                {
                    linkStatus ?
                        <div>
                            {
                                updateStatus ?
                                    <div>
                                        <p style={{ textAlign: 'center' }}>Your password has been successfully changed</p>
                                        <div className={classes.linkContainer} >
                                            <Link href={`${process.env.REACT_APP_PUBLIC_URL}/login`}>Return to the login page</Link>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <TextField className={classes.passwordInput} required type="password" variant="outlined" id="newPassword1" value={passwordState.newPassword1} onChange={onPasswordChange} label="New Password" />
                                        <TextField className={classes.passwordInput} required type="password" variant="outlined" id="newPassword2" value={passwordState.newPassword2} onChange={onPasswordChange} label="Confirm New Password" />
                                    </div>
                            }
                        </div>
                        :
                        <div>
                            <p style={{ textAlign: 'center' }}>Your link has expired</p>
                            <p style={{ textAlign: 'center' }}>This probably means that you sent us another password reset request; in that case you'll need to use the newer reset link.</p>
                        </div>


                }

                {(linkStatus && !updateStatus) &&
                    <div className={classes.buttonContainer}>
                        {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}
                        <Button variant="outlined" color='secondary' style={{ width: '100%' }} onClick={resetPassword}>Submit</Button>
                    </div>
                }
            </div>
        </div >
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            maxWidth: theme.breakpoints.values.sm,
            marginRight: 'auto',
            marginLeft: 'auto'
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2)
        }
    },
    container: {
        backgroundColor: '#fff',
        padding: theme.spacing(4),
    },
    passwordInput: {
        width: '100%',
        marginTop: theme.spacing(2)
    },
    buttonContainer: {
        paddingTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    errorMessage: {
        textAlign: 'center',
        color: theme.palette.error.main,
        marginBottom: theme.spacing(3)
    },
    logo: {
        flexShrink: 0,
        padding: '1rem',
        width: '250px',
        height: '150px',
        objectFit: 'contain',
        [theme.breakpoints.down('xs')]: {
            width: '75px',
            height: '75px',
            padding: '0.5rem'
        }

    },
    imgContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    linkContainer: {
        display: 'flex',
        justifyContent: 'center',

    }
}));
