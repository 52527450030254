import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardHeader, CardContent } from '@material-ui/core';
import { LoadingState, ErrorState } from 'components/ui/states';
import fetchExpresso from 'utility/fetchExpresso';


export default function RegistrationAnswers(props) {
    const { tranId } = props;

    const [state, setState] = useState({
        answers: null,
        loading: true,
        error: false
    });


    useEffect(() => {
        fetchExpresso(`/apiv2/orders/${tranId}/registration`)
            .then(async res => {
                if (res.status !== 200) throw new Error();

                const answers = await res.json();

                setState({answers, loading: false, error: false})
            })
            .catch(e => {
                setState(s => ({ ...s, loading: false, error: true }))
            })
    }, [tranId]);


    if (state.loading) {
        return (
            <Card>
                <CardHeader title='Checkout Form Responses' />
                <CardContent>
                    <LoadingState />
                </CardContent>
            </Card>
        )
    }

    if (state.error) {
        return (
            <Card>
                <CardHeader title='Checkout Form Responses' />
                <CardContent>
                    <ErrorState message='Unable to load checkout answers' />
                </CardContent>
            </Card>
        )
    }


    return (
        <Card>
            <CardHeader title='Checkout Form Responses' />
            <CardContent>
                {state.answers.map(a => (
                    <Box key={a.q_id} mb={4}>
                        <Typography variant='caption'>{a.q_desc}</Typography>
                        <Answer
                            type={a.q_type_id}
                            answer={a.answer}
                        />
                    </Box>
                ))}
            </CardContent>
        </Card>
    )
}


function Answer(props) {
    const { type, answer } = props;

    if (answer === null) {
        return <Typography>-</Typography>
    }

    if (type === 8) {
        // Yes or No answer. Convert 1/0 to text
        return answer === '1'
            ? <Typography>Yes</Typography>
            : <Typography>No</Typography>;
    }

    else {
        return <Typography>{answer}</Typography>
    }
}