import React, { useState } from 'react';

// Material-UI
import { styled, useTheme } from '@material-ui/core/styles';
import { TextField, Typography, Button, Box, Switch, FormControlLabel, Radio, RadioGroup, InputAdornment, Grid } from '@material-ui/core';
import CasinoIcon from '@material-ui/icons/Casino';

// Components
import Tooltip from 'components/popups/Tooltip';
import { Section, Header, Content } from 'components/ui/forms';

// Form Hooks
import { useTextInput, useNumberInput } from 'hooks/forms';


/**
 * Base form component
 * Can be used for creating or editing discount codes
 * Handles all form logic including validation
 */

export default function DiscountForm(props) {
    // Props
    const { editMode, defaults, createdAtMsg, updatedAtMsg, usedCodes, onSubmit, onCancel, loading, deletable, onDelete } = props;

    // Form Values
    const code = useTextInput({
        defaultValue: defaults?.code ?? '',
        noSpaces: true,
        maxLength: 25
    });
    const amount = useNumberInput({
        defaultValue: defaults?.discountFlat || defaults?.discountPercent || '',
        decimalPlaces: 2
    });
    const limit = useNumberInput({
        defaultValue: defaults?.limit ?? '',
        disabled: !defaults?.limit,
        maxLength: 4,
        integerOnly: true
    });

    const [type, setType] = useState(defaults?.discountFlat ? '$' : '%');
    const [limitEnabled, setLimitEnabled] = useState(Boolean(defaults?.limit));
    const [visible, setVisible] = useState(defaults?.visible ?? true);

    // Hooks
    const theme = useTheme();

    // Handlers
    function handleLimitToggle(e) {
        setLimitEnabled(e.target.checked);
        limit.setDisabled(!e.target.checked);
    }

    function handleSubmit() {
        let formError = false;

        if (code.value.length === 0) {
            code.setError('This field cannot be empty');
            formError = true;
        }

        if (usedCodes.includes(code.value.toLowerCase())) {
            code.setError('This code is already being used for this event')
            formError = true;
        }

        if (amount.value.length === 0) {
            amount.setError('Please enter a valid amount');
            formError = true;
        }

        if (type === '%' && Number(amount.value) > 100) {
            amount.setError('You can\'t set a discount greater than 100%');
            formError = true;
        }

        if (limitEnabled) {
            if (limit.value === '' || limit.value === '0') {
                limit.setError('Please enter a valid limit');
                formError = true;
            }
        }

        // Form contains invalid values. Don't submit.
        if (formError) { return; }

        // Form is good. Submit formData
        const formData = {
            code: code.value,
            discountPercent: type === '%' ? amount.value : null,
            discountFlat: type === '$' ? amount.value : null,
            limit: limitEnabled ? limit.value : null,
            visible: visible
        };
        onSubmit(formData);
    }

    const generateCode = () => {
        const chars = '23456789ABCDEFGHJKLMNPQRSTUVWXYZ';
        const length = 5;

        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];

        code.setValue(result);
    };


    return (
            <Box display='flex' justifyContent='center' width='100%'>
                <Box display='flex' flexDirection='column' flexGrow={1} p={[2, 4]} mt={5} maxWidth={theme.breakpoints.values.sm}>

                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Section>
                                <Header>Discount Code</Header>
                                <Typography>Attendees can apply this discount code during the checkout process for a reduced ticket price.</Typography>

                                <Content>
                                    <DiscountCodeInput
                                        {...code.formProps}
                                        variant='outlined'
                                        label='Discount Code'
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Button color='secondary' startIcon={<CasinoIcon color='secondary' />} onClick={generateCode}>
                                                        Random
                                                    </Button>
                                                </InputAdornment>)
                                        }}
                                    />

                                </Content>
                            </Section>
                        </Grid>

                        <Grid item xs={12}>
                            <Section>
                                <Header>Value</Header>

                                <Box mt={2}>
                                    <RadioInput aria-label="type" row={true} value={type} onChange={(e) => setType(e.target.value)}>
                                        <FormControlLabel value="%" control={<Radio />} label="Percentage" />
                                        <FormControlLabel value="$" control={<Radio />} label="Fixed Amount" />
                                    </RadioInput>
                                        
                                    <SmallTextInput
                                        {...amount.formProps}
                                        variant='outlined'
                                        label='Discount value'
                                        InputProps={{
                                            startAdornment: type === '$' ? <InputAdornment position="start">$</InputAdornment> : undefined,
                                            endAdornment: type === '%' ? <InputAdornment position="end">%</InputAdornment> : undefined
                                        }}
                                    />
                                </Box>
                            </Section>
                        </Grid>


                        <Grid item xs={12}>
                            <Section>
                                <Header>Usage Limits</Header>
                                <Typography>Limit the number of times this discount can be used in total.</Typography>

                                <Content>
                                    <SmallTextInput
                                        {...limit.formProps}
                                        variant='outlined'
                                        label={limitEnabled ? 'Limit' : 'Unlimited'}
                                    />
                                    <UsageToggle
                                        control={<Switch checked={limitEnabled} onChange={handleLimitToggle} />}
                                        label="Limit Usage"
                                    />
                                </Content>
                            </Section>
                        </Grid>

            
                        <Grid item xs={12}>
                            <Section>
                                <Header>Status</Header>
                                <Typography>Use this toggle to set the discount code as active or inactive. Ensure all details are correct before making the code available for use.</Typography>

                                <Box mt={2}>
                                    <FormControlLabel
                                        control={<Switch checked={visible} onChange={(e) => setVisible(e.target.checked)} />}
                                        label="Active"
                                    />
                                </Box>
                            </Section>
                        </Grid>
                    </Grid>




                    <Box display='flex' justifyContent='flex-end' mt={6} mb={4}>
                        {editMode && (
                            <>
                                {deletable ? (
                                    <DeleteButton variant='outlined' onClick={onDelete} disabled={loading}>Delete</DeleteButton>
                                ) : (
                                    <Tooltip message='Discounts cannot be deleted after being used by a customer.'>
                                        <DeleteButtonDisabled variant='outlined'>Delete</DeleteButtonDisabled>
                                    </Tooltip>
                                )}
                            </>
                        )}
                        <CancelButton variant='outlined' onClick={onCancel} disabled={loading}>Cancel</CancelButton>
                        <SaveButton variant='outlined' onClick={handleSubmit} disabled={loading}>Save</SaveButton>
                    </Box>

                    {createdAtMsg && (
                        <Typography align='right' variant='caption'>{createdAtMsg}</Typography>
                    )}
                    
                    {updatedAtMsg && (
                        <Typography align='right' variant='caption'>{updatedAtMsg}</Typography>
                    )}

                </Box>

            </Box>
    )
}

const DiscountCodeInput = styled(TextField)(({ theme }) => ({
    width: '50%',
    [theme.breakpoints.down('xs')]: {
        width: '100%'
    }
}));

const SmallTextInput = styled(TextField)(({ theme }) => ({
    width: '50%'
}));

const UsageToggle = styled(FormControlLabel)(({ theme }) => ({
    marginLeft: theme.spacing(1)
}));

const RadioInput = styled(RadioGroup)(({ theme }) => ({
    marginBottom: theme.spacing(1)
}));

const SaveButton = styled(Button)(({ theme }) => ({
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main
}));

const DeleteButton = styled(Button)(({ theme }) => ({
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    marginRight: 'auto'
}));


const DeleteButtonDisabled = styled(Button)(({ theme }) => ({
    color: theme.palette.grey[400],
    borderColor: theme.palette.grey[400],
    marginRight: 'auto',
    '&:hover': {
        cursor: 'default',
        backgroundColor: 'transparent'
    }
}));
DeleteButtonDisabled.defaultProps = { variant: 'contained', disableRipple: true }

const CancelButton = styled(Button)({
    marginRight: '1rem'
});