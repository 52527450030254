import React, { useState } from 'react';

// Material-UI
import { styled, Box, TextField, InputAdornment, Grid, Typography, Paper, Hidden, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

// Components
import { Page } from 'components/ui/layout';
import { ControlBar, ControlBarHeader } from 'components/ui/layout';
import { ActionButton } from 'components/ui/buttons';
import ReceiptTable from 'components/tables/ReceiptTable';

// Utility
import { useNumberInput } from 'hooks/forms';


export default function Calculator() {
    const tickets        = useNumberInput({ defaultValue: 1, integerOnly: true });
    const price          = useNumberInput({ defaultValue: 10 });
    const lineItemAmount = useNumberInput({ defaultValue: 1.25 });
    const orderPercent   = useNumberInput({ defaultValue: 5 });
    const feeMinAmount   = useNumberInput({ defaultValue: 0 });
    const feeMaxAmount   = useNumberInput({ defaultValue: 8 });
    const ccFeePercent   = useNumberInput({ defaultValue: 2.9 });
    const ccFeeAmount    = useNumberInput({ defaultValue: 0.30 });
    const tax            = useNumberInput();

    const [absorbsB4tFees, setAbsorbsB4tFees] = useState('customer');
    const [absorbsCcFees, setAbsorbsCcFees] = useState('customer');

    const handleReset = () => {
        tickets.setValue('1');
        price.setValue('10');
        lineItemAmount.setValue('1.25');
        orderPercent.setValue('5');
        feeMinAmount.setValue('0');
        feeMaxAmount.setValue('8');
        ccFeePercent.setValue('2.9');
        ccFeeAmount.setValue('0.30');
        tax.setValue('');
        setAbsorbsB4tFees('customer');
        setAbsorbsCcFees('customer');
    };


    return (
        <Page width='md'>

            <ControlBar>
                <ControlBarHeader>Fee Calculator</ControlBarHeader>
            </ControlBar>


            <Container>

                <Box flex={1}>

                    <Section>

                        <Header>Ticket Info</Header>

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Input {...tickets.formProps} variant='outlined' label="# of tickets" />
                            </Grid>
                            <Grid item xs={6}>
                                <Input {...price.formProps} variant='outlined' label="Ticket price" />
                            </Grid>
                        </Grid>

                    </Section>

                    <Section>

                        <Header>B4T Fees</Header>

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Input {...orderPercent.formProps} variant='outlined' label="Percent per ticket" InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} />
                            </Grid>
                            <Grid item xs={6}>
                                <Input {...lineItemAmount.formProps} variant='outlined' label="Amount per ticket" InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }} />
                            </Grid>
                            <Grid item xs={6}>
                                <Input {...feeMinAmount.formProps} variant='outlined' label="Minimum per ticket" InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }} />
                            </Grid>
                            <Grid item xs={6}>
                                <Input {...feeMaxAmount.formProps} variant='outlined' label="Maximum per ticket" InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }} />
                            </Grid>
                            <Grid item xs={12}>
                                <RadioGroup row value={absorbsB4tFees} onChange={(e) => setAbsorbsB4tFees(e.target.value)}>
                                    <FormControlLabel value={'customer'} control={<Radio />} label="Customer pays" />
                                    <FormControlLabel value={'host'} control={<Radio />} label="Host pays" />
                                </RadioGroup>
                            </Grid>
                        </Grid>

                    </Section>

                    <Section>

                        <Header>Credit Card Fees</Header>

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Input {...ccFeePercent.formProps} variant='outlined' label="CC fee" InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} />
                            </Grid>
                            <Grid item xs={6}>
                                <Input {...ccFeeAmount.formProps} variant='outlined' label="CC fee" InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }} />
                            </Grid>
                            <Grid item xs={12}>
                                <RadioGroup row value={absorbsCcFees} onChange={(e) => setAbsorbsCcFees(e.target.value)}>
                                    <FormControlLabel value={'customer'} control={<Radio />} label="Customer pays" />
                                    <FormControlLabel value={'host'} control={<Radio />} label="Host pays" />
                                </RadioGroup>
                            </Grid>
                        </Grid>

                    </Section>

                    <Section>
                        <Header>Tax</Header>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Input {...tax.formProps} variant='outlined' label="Tax" InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} />
                            </Grid>
                        </Grid>
                    </Section>

                    <ActionButton onClick={handleReset}>
                        Reset
                    </ActionButton>

                </Box>

                <Hidden smDown>
                    <Box width={300} ml={6}>
                        <FeePreview
                            price={price.value}
                            tickets={tickets.value}
                            lineItemAmount={lineItemAmount.value}
                            orderPercent={orderPercent.value}
                            feeMinAmount={feeMinAmount.value}
                            feeMaxAmount={feeMaxAmount.value}
                            tax={tax.value}
                            ccFeePercent={ccFeePercent.value}
                            ccFeeAmount={ccFeeAmount.value}
                            absorbsB4tFees={absorbsB4tFees}
                            absorbsCcFees={absorbsCcFees}
                        />
                    </Box>
                </Hidden>

            </Container>

            <Hidden mdUp>
                <FeePreview
                    price={price.value}
                    tickets={tickets.value}
                    lineItemAmount={lineItemAmount.value}
                    orderPercent={orderPercent.value}
                    feeMinAmount={feeMinAmount.value}
                    feeMaxAmount={feeMaxAmount.value}
                    tax={tax.value}
                    ccFeePercent={ccFeePercent.value}
                    ccFeeAmount={ccFeeAmount.value}
                    absorbsB4tFees={absorbsB4tFees}
                    absorbsCcFees={absorbsCcFees}
                />
            </Hidden>

        </Page>
    )
}

function FeePreview(props) {
    const price = Number(props.price) || 0;
    const tickets = Number(props.tickets) || 0;
    const b4t_fee_line_item_amt = Number(props.lineItemAmount) || 0;
    const b4t_fee_order_pct = (Number(props.orderPercent) || 0) / 100;
    const b4t_fee_min_amt = Number(props.feeMinAmount) || 0;
    const b4t_fee_max_amt = Number(props.feeMaxAmount) || 0;
    const cc_fee_order_pct = (Number(props.ccFeePercent) || 0) / 100;
    const cc_fee_order_amt = Number(props.ccFeeAmount) || 0;
    const tax_rate = (Number(props.tax) || 0) / 100;

    const host_absorbs_b4t_fees = props.absorbsB4tFees === 'customer' ? 0 : 1;
    const host_absorbs_cc_fees = props.absorbsCcFees === 'customer' ? 0 : 1;

    const taxable_products = tax_rate ? 1 : 0; // Flag that determines if taxes are applied to the order
    const taxes_included_in_product = 0; // We always assume taxes are added on to the original ticket price for this demo

    const sub_total = Number((tickets * price).toFixed(2));

    const paidTransaction = (tickets > 0 && price > 0);


    // Our Fees
    const b4tFeeTaxMultiplier = 1; // taxable_b4t_fees is always turned off, so the tax multiplier on our fees is always 1
    let our_order_percent = sub_total * b4t_fee_order_pct;

    if (our_order_percent > 0) {
        let feePerTicket = price * b4t_fee_order_pct;

        if (b4t_fee_min_amt && b4t_fee_min_amt > feePerTicket) {
            our_order_percent = b4t_fee_min_amt * tickets;
        }

        if (b4t_fee_max_amt && b4t_fee_max_amt < feePerTicket) {
            our_order_percent = b4t_fee_max_amt * tickets;
        }
    }

    const our_product_flat = b4t_fee_line_item_amt * tickets;
    const our_order_flat = 0; // Note: We don't appear to use this value, so setting it to 0 for now. (Used to be assigned the value of b4t_fee_order_amt)
    const our_fee_total = (sub_total)
        ? Number(((our_order_percent + our_product_flat + our_order_flat) * b4tFeeTaxMultiplier).toFixed(2))
        : 0;
    const ourFeeTotalCalc = host_absorbs_b4t_fees ? 0 : our_fee_total;

    // Taxes
    const tax_on_products = (taxable_products && !taxes_included_in_product)
        ? Number((sub_total * tax_rate).toFixed(2))
        : 0;

    // Credit Card Fees
    let cc_percent_fee = 0, cc_flat_fee = 0, cc_fee_total = 0, ccFeeTotalCalc = 0;

    if (paidTransaction) {
        if (host_absorbs_cc_fees) {
            // ** CC Fee is included in the ticket price ** //
            cc_percent_fee = (sub_total + tax_on_products + ourFeeTotalCalc) * cc_fee_order_pct
            cc_flat_fee = cc_fee_order_amt;
            cc_fee_total = Number(
                (cc_percent_fee + cc_flat_fee).toFixed(2)
            );
            ccFeeTotalCalc = 0;
        } else {
            // ** CC Fee is passed on to the customer ** //
            // Calc CC fee considering that increasing the final amount also increases the Stripe fee
            const originalCharge = sub_total + tax_on_products + ourFeeTotalCalc;
            const adjustedCharge = (originalCharge + cc_fee_order_amt) / (1 - cc_fee_order_pct);
        
            cc_percent_fee = adjustedCharge * cc_fee_order_pct;
            cc_flat_fee = cc_fee_order_amt;
            cc_fee_total = Number(
                (cc_percent_fee + cc_flat_fee).toFixed(2)
            );
    
            ccFeeTotalCalc = cc_fee_total;
        }
    }

    // Totals
    const total = Number((sub_total + ourFeeTotalCalc + tax_on_products + ccFeeTotalCalc).toFixed(2));




    // Host Absorbs (Total)
    let hostTotal = 0;
    let fees = 0;

    if (host_absorbs_b4t_fees) {
        fees = Number((fees + our_fee_total).toFixed(2));
    }

    if (host_absorbs_cc_fees) {
        fees = Number((fees + cc_fee_total).toFixed(2));
    }

    hostTotal = Number((sub_total + tax_on_products - fees).toFixed(2));



    const products = [{
        product_name: 'Ticket',
        price: price,
        qty: tickets
    }]

    const orderDetails = {
        sub_total: sub_total,
        status_financial: 1000,
        discount_code: null,
        disc_order_total: null,
        tax_name: 'Tax',
        tax_on_products: tax_on_products,
        our_fee_total: our_fee_total,
        cc_fee_total: cc_fee_total,
        total: total,
        hostTotal: hostTotal,
        host_absorbs_b4t_fees: host_absorbs_b4t_fees,
        host_absorbs_cc_fees: host_absorbs_cc_fees
    }


    return (
        <ReceiptTable
            products={products}
            orderDetails={orderDetails}
        />
    )
}


const Container = styled(Paper)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(2)
}));

const Section = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(4)
}));

const Input = styled(TextField)(({ theme }) => ({
    width: '100%'
}));

const Header = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    marginBottom: theme.spacing(2)
}));
Header.defaultProps = { variant: 'h6' }