import React, { useEffect, useReducer } from 'react';

import { Box, useTheme, styled, Paper, Typography, CircularProgress } from '@material-ui/core'
import { ControlBar, ControlBarButton, ControlBarHeader } from 'components/ui/layout';
import DiscountList from 'components/DiscountList';
import DiscountCreator from 'components/forms/DiscountCreator';
import DiscountEditor from 'components/forms/DiscountEditor';
import FullScreenModal from 'components/popups/FullScreenModal';
import fetchExpresso from 'utility/fetchExpresso';


const initialState = {
    modalOpen: false, // Opens the modal. Used for creating and editing discount codes
    selectedDiscount: null, // ID of discount being edited
    discounts: [],
    status: 'loading', // loading, error, success
    refresh: 0 // Increment to refresh events
}

function reducer(state, action) {
    switch (action.type) {
        case 'addDiscount':
            return { ...state, modalOpen: true };

        case 'editDiscount':
            return { ...state, modalOpen: true, selectedDiscount: action.discountId };

        case 'refresh':
            return { ...state, modalOpen: false, selectedDiscount: null, refresh: state.refresh + 1 };

        case 'closeModal':
            return { ...state, modalOpen: false, selectedDiscount: null };

        case 'loadSuccess':
            return { ...state, status: 'success', discounts: action.discounts };

        case 'loadError':
            return { ...state, status: 'error' };
    
        default:
            break;
    }
}


function DiscountPage(props) {
    const theme = useTheme();
    let maxWidth = theme.breakpoints.values.lg;

    return (
        <Box display={'flex'} flexDirection={'column'} height='100%'>
            <Box px={[2, 4]} pt={[2, 4]} pb={6} flexGrow={1}>
                <Box display='flex' flexDirection='column' ml='auto' mr='auto' maxWidth={maxWidth} height='100%' width='100%'>
                    {props.children}
                </Box>
            </Box>
        </Box>
    )
}


export default function Discounts(props) { 
    const eventId = props.match.params.id;

    const [state, dispatch] = useReducer(reducer, initialState);


    // Fetch all discount codes for event
    useEffect(() => {
        fetchExpresso(`/apiv2/discounts/?event=${eventId}`)
            .then(async res => {
                if (res.status === 200) {
                    const discounts = await res.json();
                    dispatch({type: 'loadSuccess', discounts})
                }
                else { dispatch({type: 'loadError'}); }
            })
            .catch(err => {
                dispatch({type: 'loadError'});
            })
    }, [eventId, state.refresh]);


    // List of discount_codes created for this event
    // These should not be used to generate another discount
    let usedCodes = [];

    if (state.status === 'success') {
        if (state.selectedDiscount) {
            // Don't include the code currently being edited
            usedCodes = state.discounts.filter(d => d.disc_id !== state.selectedDiscount).map(d => d.discount_code.toLowerCase());
        } else {
            // Include all discount codes for this event
            usedCodes = state.discounts.map(d => d.discount_code.toLowerCase());
        }
    }

    return (
        <DiscountPage>
            <ControlBar>
                <ControlBarHeader>Discounts</ControlBarHeader>
                <ControlBarButton onClick={() => dispatch({type: 'addDiscount'})}>New Discount</ControlBarButton>
            </ControlBar>

            {state.status === 'loading' && (
                <Box display='flex' flexGrow={1} justifyContent='center' alignItems='center'>
                    <CircularProgress />
                </Box>
            )}
            
            {state.status === 'error' && (
                <Box display='flex' flexGrow={1} justifyContent='center' alignItems='center'>
                    <Typography variant='subtitle2'>There was a problem loading discounts</Typography>
                </Box>
            )}

            {state.status === 'success' && state.discounts.length === 0 && (
                <Box display='flex' flexGrow={1} justifyContent='center' alignItems='center'>
                    <Typography variant='subtitle2'>No discounts found</Typography>
                </Box>
            )}

            {state.status === 'success' && state.discounts.length > 0 && (
                <Container>
                    <Box flexGrow={1}>
                        <Box height='100%'>
                            <DiscountList
                                discounts={state.discounts}
                                onEdit={(discountId) => dispatch({type: 'editDiscount', discountId})}
                            />
                        </Box>
                    </Box>
                </Container>
            )}


            <FullScreenModal open={state.modalOpen} onClose={() => dispatch({type: 'closeModal'})} title={state.selectedDiscount ? 'Edit Discount' : 'Create Discount'} cancelText='Cancel'>
                {state.selectedDiscount ? (
                    <DiscountEditor
                        eventId={eventId}
                        discountId={state.selectedDiscount}
                        usedCodes={usedCodes}
                        onSave={() => dispatch({type: 'refresh'})}
                        onCancel={() => dispatch({type: 'closeModal'})}
                    />
                ) : (
                    <DiscountCreator
                        eventId={eventId}
                        usedCodes={usedCodes}
                        onSave={() => dispatch({type: 'refresh'})}
                        onCancel={() => dispatch({type: 'closeModal'})}
                    />
                )}
            </FullScreenModal>

        </DiscountPage>
    )
}



const Container = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
}));