import React, { useState, memo, useEffect } from 'react';

// Material UI
import { styled, Box, Paper, CircularProgress, TextField, IconButton, Typography, Tabs, Tab, useTheme } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';

// Components
import { ControlBar, ControlBarHeader } from 'components/ui/layout';
import { PaymentIcon, CommentIcon, PaymentMethodIcon, CustomOrderIcon } from 'components/ui/icons';

// Utility
import { useAuth } from 'context/auth';
import useExpresso from 'hooks/useExpresso';
import { formatPrice } from 'utility/numbers';
import moment from 'moment-timezone';
import ReactGA from 'react-ga';

function OrderPage(props) {
    const theme = useTheme();
    let maxWidth = theme.breakpoints.values.lg;

    return (
        <Box display={'flex'} flexDirection={'column'} height='100%'>
            <Box px={[2, 4]} pt={[2, 4]} pb={6} flexGrow={1}>
                <Box display='flex' flexDirection='column' ml='auto' mr='auto' maxWidth={maxWidth} height='100%' width='100%'>
                    {props.children}
                </Box>
            </Box>
        </Box>
    )
}

export default function OnsiteOrders(props) {
    const eventId = props.match.params.id;
    const search = props.location.search;

    // State
    const [state, setState] = useState({
        selectedOrder: null,
        refreshToken: 0,
        createOrderOpen: false
    });

    // Utility
    const { auth } = useAuth();

    const hostId = auth.hostId;


    // If url includes the search param: o, automatically open the order with the provided id
    useEffect(() => {
        if (search.startsWith('?o=')) {
            const orderId = search.slice(3);
            setState(s => ({ ...s, selectedOrder: orderId }));
        }
    }, [search]);
    

    // Set selected order to make OrderDetailModal modal appear
    const setSelectedOrder = (order) => {
        ReactGA.event({ category: "Orders", action: 'View Order', label: `${hostId}` });
        setState(s => ({ ...s, selectedOrder: order }))
    }


    return (
        <OrderPage>

            <ControlBar>
                <ControlBarHeader>In-Person Orders</ControlBarHeader>
            </ControlBar>
            
            <OrderTable
                eventId={eventId}
                onOrderSelected={setSelectedOrder}
                dependency={state.refreshToken}
            />

            {/* <FullScreenModal open={state.selectedOrder ? true : false} onClose={() => setSelectedOrder(null)} title='Order Details'>
                <OrderDetailModal tranId={state.selectedOrder} eventId={eventId} onUpdate={handleRefundUpdate} />
            </FullScreenModal> */}

            {/* <OrderCreateModal
                open={state.createOrderOpen}
                onClose={() => setState({ ...state, createOrderOpen: false })}
                eventId={eventId}
                onOrderCreate={handleOrderCreate}
            /> */}

        </OrderPage>
    )
}


// Order Table Component
// Fetch and add onClick listeners to all orders for an event
const OrderTable = memo((props) => {
    // Props
    const { eventId, onOrderSelected, dependency } = props;

    // State and Hooks
    const [category, setCategory] = useState(0);

    const [data, loading, error] = useExpresso(
        `/apiv2/events/${eventId}/onsite-orders/`,
        'GET',
        null,
        { eventId: eventId },
        [eventId, dependency]
    );


    let orders = data?.rows || [];


    // ***** Filter List by Category ***** //

    if (category === 1) {
        orders = orders.filter(o => o.status_financial >= 1000 && o.status_financial <= 1999);
    }

    if (category === 2) {
        orders = orders.filter(o => o.status_financial === 3000);
    }    

    if (category === 3) {
        orders = orders.filter(o => o.status_financial >= 2000 && o.status_financial <= 2999);
    }


    if (loading) {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' height={'100%'}>
                <CircularProgress />
            </Box>
        )
    }

    if (error) {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' height={'100%'}>
                <Typography variant='subtitle2'>There was a problem loading orders</Typography>
            </Box>
        )
    }
    

    return (
        <Container>
            <Tabs value={category} onChange={(_, value) => setCategory(value)} variant='scrollable'>
                <Tab label={'All'} />
                <Tab label={(
                    <Box display='flex' alignItems={'center'}>
                        <ApprovedTabIcon fontSize='small' />
                        <Typography variant='button'>APPROVED</Typography>
                    </Box>
                )} />
                <Tab label={(
                    <Box display='flex' alignItems={'center'}>
                        <RefundTabIcon fontSize='small' />
                        <Typography variant='button'>REFUNDED</Typography>
                    </Box>
                )} />
                <Tab label={(
                    <Box display='flex' alignItems={'center'}>
                        <DeclineTabIcon fontSize='small' />
                        <Typography variant='button'>CANCELED</Typography>
                    </Box>
                )} />
            </Tabs>

            <Box flexGrow={1}>
                <Box height='100%'>
                    <OrderDataGrid
                        orders={orders}
                        onOrderSelected={onOrderSelected}
                    />
                </Box>
            </Box>
            
        </Container>
    )
    // Component won't re render if old and new props are the same
}, (oldProp, newProp) => {
    if ((oldProp.eventId === newProp.eventId) && (oldProp.dependency === newProp.dependency)) {
        return true;
    }
    else { return false }
})


function OrderDataGrid(props) {
    const { orders, onOrderSelected } = props;

    const [dataset, setDataset] = useState([]); // Filtered list of events
    const [searchText, setSearchText] = useState('');


    // Whenever the list of events from the parent component is updated, we need to update the dataset, and clear all filters to keep the UI in sync.
    // On first render, this will just set the initial value for 'dataset'
    useEffect(() => {
        setDataset(orders);
        setSearchText('');
    }, [orders]);


    // ***** Search / Filter ***** //

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        
        const searchRegex = new RegExp(searchValue.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
        
        const filteredRows = orders.filter((row) => {
            const orderNumber = 'P' + String(row.pos_tran_id).padStart(5, '0');
            const last4 = String(row.card_last4);

            return searchRegex.test(orderNumber) || searchRegex.test(last4);
        });
        
        setDataset(filteredRows);
    };


    // ***** DataGrid Rows and Columns ***** //

    const rows = dataset.map(o => ({
        id: o.pos_tran_id,
        c1: o.status_financial,
        c2: 'P' + String(o.pos_tran_id).padStart(5, '0'),
        c3: { customOrder: o.custom_order, commentCount: o.comment_count, paymentType: o.tran_type_id, last4: o.card_last4, brand: o.card_brand }, // More status fields can go here
        c4: o.total,
        c5: o.total_tickets,
        c6: o.device_id || '-',
        c7: o.pos_name || '-',
        c8: o.created_at,
    }));

    const columns = [
        { field: 'c1',  headerName: ' ',        width: 100,  hideSortIcons: true,
            renderCell: (params) => <PaymentIcon status={params.value} />
        },
        { field: 'c2',  headerName: 'ORDER',    width: 100,  hideSortIcons: true },
        { field: 'c3',  headerName: 'DETAIL',   width: 140,  hideSortIcons: true,
            sortComparator: (v1, v2, param1, param2) => {
                const a = param1.value.last4 || 0;
                const b = param2.value.last4 || 0;
                return a - b;
            },
            renderCell: (params) => (
                <Box display={'flex'} alignItems={'center'}>
                    <PaymentMethodIcon type={params.value.paymentType} last4={params.value.last4} brand={params.value.brand} />
                    {params.value.last4 && (
                        <Box mx={1}>
                            <Typography variant='body2'> - {params.value.last4}</Typography>
                        </Box>
                    )}
                    <CommentIcon count={params.value.commentCount} />
                    {params.value.customOrder === 1 && (
                        <CustomOrderIcon />
                    )}
                </Box>
            )
        },
        { field: 'c4',  headerName: 'TOTAL',       width: 100,      hideSortIcons: true, type: 'number', valueFormatter: params => formatPrice(params.value) },
        { field: 'c5',  headerName: 'TIX',         width: 100,      hideSortIcons: true, type: 'number', valueFormatter: params => params.value || '-' },
        { field: 'c6',  headerName: 'DEVICE ID',   width: 110,      hideSortIcons: true },
        { field: 'c7',  headerName: 'DEVICE NAME', width: 150,      hideSortIcons: true },
        { field: 'c8',  headerName: 'DATE',        minWidth: 180,   hideSortIcons: true, valueFormatter: params => {
            const value = moment(params.value);
            if (value.get('year') === moment().get('year')) {
                return moment(params.value).format('MMM D, h:mma')
            } else {
                return moment(params.value).format('MMM D YYYY h:mma')
            }
        }},
    ]

    return (
        <DG
            onRowClick={(params) => onOrderSelected(params.id)}
            disableSelectionOnClick
            components={{ Toolbar: Toolbar }}
            componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(''),
                },
            }}
            rows={rows}
            columns={columns}
            disableColumnMenu
            sortingOrder={['desc', 'asc']}
        />
    )
}


function Toolbar(props) {
    const { value, onChange, clearSearch } = props;
    
    return (
        <SearchBar
            variant="outlined"
            size='small'
            value={value}
            onChange={onChange}
            placeholder="Search by order number"
            InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
                <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    style={{ visibility: value ? 'visible' : 'hidden' }}
                    onClick={clearSearch}
                >
                    <ClearIcon fontSize="small" />
                </IconButton>
            ),
            }}
        />
    )
}

const DG = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-cell:focus": {
        outline: 'none'
    },
    "& .MuiDataGrid-dataContainer": {
        '&:hover': {
            cursor: 'pointer'
        }
    },
}));

const SearchBar = styled(TextField)(({ theme }) => ({
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: '360px'
}));

const Container = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
}));

const ApprovedTabIcon = styled(CheckCircleOutlinedIcon)(({ theme }) => ({
    marginRight: theme.spacing(0.5)
}));
const RefundTabIcon = styled(RotateLeftIcon)(({ theme }) => ({
    marginRight: theme.spacing(0.5)
}));
const DeclineTabIcon = styled(BlockIcon)(({ theme }) => ({
    marginRight: theme.spacing(0.5)
}));