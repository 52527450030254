import React from 'react';

// Material UI
import { styled, Box, Card, Button, Typography } from '@material-ui/core';

// Components
import Profile from 'components/Profile';
import StripeConnect from 'components/StripeConnect';

//Utility
import { useAuth } from 'context/auth';
import { Page } from 'components/ui/layout';
import { Alert } from '@material-ui/lab';


export default function Settings() {
    const { logout, auth } = useAuth();

    const connectStatus = new URL(document.location).searchParams.get('connect_status');

    return (
        <Page width='md'>
            {connectStatus === 'refresh' && (
                <Box mb={4}>
                    <Alert severity='warning' variant='filled'>Your Stripe setup link has expired. Try using the latest one below.</Alert>
                </Box>
            )}

            <Box mb={3}>
                <Card>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} p={4}>
                        <Typography align='center' variant='h4'>{auth.hostName}</Typography>
                    </Box>
                </Card>
            </Box>

            <Box mb={4}>
                <Profile />
            </Box>

            <Box mb={4}>
                <StripeConnect />
            </Box>


            <Box display='flex' flexDirection='column' alignItems='center' my={6}>

                <LinkButton variant='outlined' color='secondary' onClick={() => window.open("https://forms.gle/dmpG5dpRiK8NsoV26")}>Send Feedback</LinkButton>

                <Box height={24} />

                <LinkButton variant='outlined' color='secondary' onClick={logout}>Logout</LinkButton>

                <Box height={24} />

                <Typography>FrontDoor+ Version: {process.env.REACT_APP_VERSION}</Typography>

            </Box>

        </Page>
    )
}


const LinkButton = styled(Button)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    width: 300,
}));