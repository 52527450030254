import React from 'react';

// Material UI
import { styled, Box, Typography } from '@material-ui/core';
import { TableRow, TableCell } from '@material-ui/core';
import MessageIcon from '@material-ui/icons/Message';

// Components
import Tooltip from 'components/popups/Tooltip';

// Utils
import moment from 'moment-timezone';



export default function AuditListItem(props) {
    const { onClick, weeksOut } = props;
    const { status, concluded, startDateUnix, endDateUnix, event, host, province, note, phoneCount, readerCount, incidentalCount, audited, returned } = props.event;

    const start = moment(startDateUnix);
    const end = moment(endDateUnix);


    let CalendarWidget = Box;

    if (status === 'published') CalendarWidget = PublishedDate;
    if (status === 'unlisted') CalendarWidget = UnlistedDate;
    if (status === 'draft') CalendarWidget = DraftDate;
    if (concluded === true) CalendarWidget = ConcludedDate;


    let disabled = false;

    if (weeksOut >= 0 && audited === true) {
        // Future Event. This event has been audited
        disabled = true;
    }

    if (weeksOut < 0 && returned === true) {
        // Past Event. This event has returned their devices
        disabled = true;
    }
    
    if (weeksOut < 0 && phoneCount === 0 && readerCount === 0) {
        // Past Event. This event didn't rent out devices
        disabled = true;
    }

    return (
        <Row disabled={disabled} onClick={onClick}>
            <Cell>
                <CalendarWidget>
                    <BoldText>{start.format('D')}</BoldText>
                    <Typography>{start.format('ddd')}</Typography>
                </CalendarWidget>
            </Cell>
            <Cell align='center'>
                <Typography>{end.diff(start, 'days') + 1}</Typography>
            </Cell>
            <EventCell>
                <Typography>{event}</Typography>
                <Typography variant='caption'>{host}</Typography>
            </EventCell>
            <Cell>
                {note ? (
                    <Box display='flex' alignItems={'center'}>
                        <Tooltip message={note}>
                            <MessageIcon />
                        </Tooltip>
                    </Box>
                ) : null}
            </Cell>
            <Cell>
                <Typography>{province}</Typography>
            </Cell>
            <Cell align='center'>
                <Typography>{phoneCount ?? '?'}</Typography>
            </Cell>
            <Cell align='center'>
                <Typography>{readerCount ?? '?'}</Typography>
            </Cell>
            <Cell align='center'>
                <Typography>{incidentalCount}</Typography>
            </Cell>
        </Row>
    )
}

const BoldText = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold'
}));

const Cell = TableCell;

const Row = styled(({ disabled, ...other }) => <TableRow {...other} />)({
    opacity:         (props) => props.disabled ? 0.5 : 1,
    backgroundColor: (props) => props.disabled ? '#eee' : 'white',
});

const EventCell = styled(TableCell)(({ theme }) => ({
    minWidth: 300
}));

const DraftDate = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 38,
    borderColor: theme.palette.warning.main,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: 6,
    color: theme.palette.warning.main
}));

const UnlistedDate = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 38,
    borderColor: theme.palette.info.main,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: 6,
    color: theme.palette.info.main
}));

const PublishedDate = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 38,
    borderColor: theme.palette.success.main,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: 6,
    color: theme.palette.success.main
}));

const ConcludedDate = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 38,
    borderColor: theme.palette.grey[600],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: 6,
    color: theme.palette.grey[600]
}));